import React, { useState } from 'react'
import { useGetLocationPartners, useGetLocations } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'

import ConversationChat from './ConversationsList/ConversationChat/ConversationChat'
import ConversationsList from './ConversationsList/ConversationsList'
import CustomerInfoSection from './CustomerInfoSection/CustomerInfoSection'
import { GridContainer, LeftGridSection, MiddleGridSection, RightGridSection, LocationDropdownWrapper, ChilipiperSectionWrapper, CustomerSurveyContainer } from './styles'
import SingleLocationDropdown from 'components/SingleLocationDropdown/SingleLocationDropdown'
import { Location } from 'types'
import { ChilipiperSection } from '../../features/manage-locations/IndividualLocation/ChilipiperSection'
import NotificationBanner from 'components/NotificationBanner/NotificationBanner'

const CustomerSurveys: React.FC = () => {
  const { isMobile } = useIsMobile()
  const { defaultLocation, setDefaultLocation } = useGetLocations()
  const partnerId = defaultLocation?.id !== null ? String(defaultLocation?.id) : ''

  const { partner, organization } = useGetLocationPartners(partnerId)


  const [selectedConversation, setSelectedConversation] = useState<any | null>(null)
  const [showCustomerInfo, setShowCustomerInfo] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const isPlatformOrganization = organization?.attributes?.chowly_platform ?? false
  const organizationOnboardingIncomplete = organization?.attributes?.ovation_onboarding_started && !organization?.attributes?.ovation_phone_verified
  const partnerOnboardingIncomplete = partner?.attributes?.ovation_onboarding_started && !partner?.attributes?.ovation_phone_verified
  const showVerificationBanner = organizationOnboardingIncomplete || partnerOnboardingIncomplete
  const isOnboarded = organization?.attributes?.ovation_phone_verified && partner?.attributes?.ovation_phone_verified


  const handleBackToList = () => {
    setSelectedConversation(null)
    setShowCustomerInfo(false)
  }

  const handleBackToChat = () => {
    setShowCustomerInfo(false)
  }

  const handleShowCustomerInfo = () => {
    setShowCustomerInfo(true)
  }

  // Handle location change from the dropdown
  const handleLocationChange = (location: Location) => {
    setDefaultLocation(location)
  }

  const handleExpandChat = (expanded: boolean) => {
    setIsExpanded(expanded)
  }

  return (
    <CustomerSurveyContainer>
      <LocationDropdownWrapper>
        <SingleLocationDropdown
          onLocationChange={handleLocationChange}
        />
      </LocationDropdownWrapper>
      {!isPlatformOrganization ? (
        <ChilipiperSectionWrapper>
          <ChilipiperSection
            message='The selected Location is not a Platform location.'
            actionBtnText='Reach out to sales to learn how you can upgrade to a Platform Plan'
          />
        </ChilipiperSectionWrapper>
      ):(
        <>
          {showVerificationBanner && (
            <NotificationBanner
              type="warning"
              message="This location is pending toll-free phone number verification. Please contact your Chowly Support rep for more information."
              icon
              width="100%"
              margin="16px 0"
            />
          )}
          <GridContainer>
            <LeftGridSection md={3} $showOnMobile={!selectedConversation}>
              <ConversationsList isOnboarded={isOnboarded} setSelectedConversation={setSelectedConversation} />
            </LeftGridSection>

            <MiddleGridSection
              $showOnMobile={!!selectedConversation && !showCustomerInfo}
              $expanded={isExpanded}
            >
              <ConversationChat
                conversation={selectedConversation}
                onBack={handleBackToList}
                onCustomerInfoClick={handleShowCustomerInfo}
                onExpand={handleExpandChat}
                isExpanded={isExpanded}
              />
            </MiddleGridSection>

            <RightGridSection md={3} $showOnMobile={showCustomerInfo}>
              {(!isExpanded || isMobile) && (
                <CustomerInfoSection
                  customerInfo={selectedConversation?.customer}
                  onBack={handleBackToChat}
                />
              )}
            </RightGridSection>
          </GridContainer>
        </>
      )}
    </CustomerSurveyContainer>
  )
}

export default CustomerSurveys
