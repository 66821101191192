import styled from 'styled-components'
import { colors, fontFamily, fontWeight } from 'theme'

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '1280px',
  rowGap: '1rem',
  paddingBottom: 'calc(2vh + 24px)',
})

export const CardBox = styled.div({
  marginBottom: '24px',
  borderRadius: '8px',
  background: '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(27, 81, 164, 0.04)',
  padding: '24px',
  '&:last-child': {
    marginBottom: 0,
  },
})

export const TitleCardBox = styled(CardBox)({
  height: '148px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

export const RulesCardBox = styled(CardBox)({
  height: '259px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

export const TitleTypography = styled.div({
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: '25px',
  fontStyle: 'normal',
  fontWeight: fontWeight.semibold,
  lineHeight: '140%',
})

export const SectionTitle = styled.div({
  color: '#000',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '140%',
  marginBottom: '16px',
})

export const RulesGrid = styled.div({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
})

export const Header = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.text.primary};
`

export const Description = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: 24px;
`

export const Card = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

export const CardTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.text.primary};
`

export const RulesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
`

export const RuleCard = styled.div`
  background: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 6px;
  padding: 16px;
`

export const RuleBox = styled.div({
  borderRadius: '8px',
  border: '1px solid #D7D7D7',
  background: '#FEFEFE',
  width: '196px',
  height: '76px',
  padding: '16px',
  '@media (max-width: 768px)': {
    height: '100px',
  },
})

export const RuleTitle = styled.div({
  color: '#292929',
  fontFamily: 'Inter',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '150%',
  marginBottom: '8px',
})

export const RuleValue = styled.div({
  color: '#777',
  fontFamily: 'Inter',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
})

export const DescriptionText = styled.p({
  color: '#777',
  fontFamily: 'Inter',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
  marginTop: '8px',
})

export const BoldText = styled.span({
  fontWeight: 700,
})
