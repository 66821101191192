import { useState } from 'react';
import { Button, Modal } from 'components'
import { updateMarketingChannel } from 'api';
import  CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled'
import * as Styled from './styles'
import { useGetGoogleBusinessLocations } from 'hooks';

type Props = {
  isOpen: boolean
  locationName: string
  showInfoIcon: boolean
  onClose: () => void
  marketingChannelId: string
  locationId: string
}

const StyledTextField = styled(TextField)`
  & .MuiInputBase-root input {
    font-size: 14px;
    border: none;
    box-shadow: none;
  }
`;


const ConnectionModal = ({ isOpen, locationName, onClose, marketingChannelId, locationId }: Props) => {
  const { isLoading, locations } = useGetGoogleBusinessLocations(locationId, marketingChannelId)
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const selectedLocationValue = locations?.find(profile => profile?.location?.name === selectedLocation) || null;
  const [submitting, setSubmitting] = useState(false);

  const sortedLocations = locations?.sort((a, b) => a?.location?.title?.localeCompare(b?.location?.title || '') || 0)
  const handleSubmit = async () => {
    if(!selectedLocationValue) return;
    setSubmitting(true);
    await updateMarketingChannel(marketingChannelId, {
      googleBusinessProfile: {
        accountId: selectedLocationValue?.accountId as string,
        locationId: selectedLocationValue?.location?.name as string,
      }
    } , locationId).finally(() => {
      setSubmitting(false);
    })
    
    onClose()
  };

  return (
    <Modal isOpen={isOpen} cardProps={{ borderRadius: 8 }}>
      <Styled.ModalBox>
        {isLoading ? <CircularProgress /> : (
          <Styled.TextContainer>
            <div style={{ marginBottom: "1rem" }}>
              <label htmlFor="selectedProfile" style={{ display: "block", marginBottom: ".5rem" }}>
                {`Which Google Business Profile do you want to associate to ${locationName}`}
              </label>
              <Autocomplete
                value={selectedLocationValue}
                onChange={(_, newValue) => setSelectedLocation(newValue?.location?.name || '')}
                options={sortedLocations || []}
                
                getOptionLabel={(option) => option?.location?.title || ''}
                getOptionKey={(option) => option?.location?.name || ''}
                renderOption={(props, option) => (
                  <li {...props}>
                    <div>
                      <Typography>{option?.location?.title}</Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ color: 'text.secondary', fontSize: '12px' }}
                      >
                        {option?.location?.storefrontAddress?.addressLines?.[0]}
                      </Typography>
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    label="Select a business profile"
                  />
                )}
              />
            </div>
            <Styled.LineRow />
            <Styled.ButtonContainer>
              <Button variant='textonly' size='small' onClick={onClose} disabled={submitting}>
                Cancel
              </Button>
              <Button size='small' onClick={handleSubmit} isLoading={submitting} disabled={submitting || !selectedLocationValue}>
                Save
              </Button>
            </Styled.ButtonContainer>
          </Styled.TextContainer>
        )}
      </Styled.ModalBox>
    </Modal>
  )
}

export default ConnectionModal
