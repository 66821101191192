import { ReactComponent as DownArrowIcon } from 'assets/icons/down-arrow-icon.svg'
import { ReactComponent as Popout } from 'assets/icons/popout-icon.svg'
import SearchIconURL, { ReactComponent as SearchIconComponent } from 'assets/icons/search.svg'

import { Link, NavLink as RouterNavLink } from 'react-router-dom'
import styled from 'styled-components'
import {
  backdrop,
  borderRadius,
  breakpoints,
  colors,
  elevation,
  fontWeight,
  inset0,
  shadow,
  transition,
} from 'theme'
import { Logout as LogoutIcon } from '@mui/icons-material'

export const Backdrop = styled.div.attrs({ role: 'button' })({
  ...backdrop,
  backdropFilter: 'unset',
  backgroundColor: 'unset',
})

export const Container = styled.div({
  position: 'fixed',
  transform: 'none',
  display: 'none',
  width: '100vw',
  height: '88px',
  justifyContent: 'space-between',
  flexDirection: 'row',
  padding: '0px',
  fontWeight: '500',
  backgroundColor: '#FFFFFF',
  borderBottom: '1px solid #EBEBEB',
  color: colors.primary.DEFAULT,
  [`@media ${breakpoints.sm}`]: {
    display: 'flex',
  },
  zIndex: 10,
})

export const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 0 8px 0;
  background-color: #FFFFFF;
`

export const SideBar = styled.div<{ $isOpen: boolean; $isCollapsed: boolean }>`
  ${inset0};
  ${transition};
  position: fixed;
  transform: ${({ $isOpen }) => ($isOpen ? 'none' : 'translate(-100%, 0)')};
  width: ${({ $isCollapsed }) => ($isCollapsed ? '96px' : '312px')};
  height: 100vh;
  font-weight: 500;
  flex-direction: column;
  background-color: #FFFFFF;
  border-bottom: 1px solid #EBEBEB;
  border-right: 1px solid #EBEBEB;
  color: ${colors.primary.DEFAULT};
  z-index: ${elevation.backdrop};
  display: flex;
  padding: ${({ $isCollapsed }) => ($isCollapsed ? '0 12px' : '0 24px')};
  transition: all 0.3s ease;

  ${({ $isCollapsed }) => $isCollapsed && `
    .nav-text {
      display: none;
    }

    a,
    button,
    div {
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    svg {
      margin: 0 auto;
    }
  `}

  @media ${breakpoints.sm} {
    width: ${({ $isCollapsed }) => ($isCollapsed ? '96px' : '312px')};
  }
`
export const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(26, 26, 26, 0.16);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 9;
`;

export const Header = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  height: 'auto',
  [`@media ${breakpoints.sm}`]: {
    alignItems: 'center',
    height: '88px',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

export const CloseButton = styled.img.attrs({ role: 'button' })({
  width: '2rem',
  height: '2rem',
  position: 'absolute',
  left: '24px',
  top: '20px',
})

export const ClearButton = styled.img.attrs({ role: 'button' })({
  width: 24,
  height: 24,
  position: 'absolute',
  right: 10,
  marginTop: -22,
  top: '50%',
})

export const LogoSection = styled.div`
  padding: 16px 0;
  margin-bottom: 16px;
`

export const LogoSectionDesktop = styled.div`
  padding: 16px 0;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const LogoLink = styled(Link)<{ $isCollapsed?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: auto;
    height: ${({ $isCollapsed }) => ($isCollapsed ? '32px' : '40px')};
  }
`

export const LogoLinkMobile = styled(Link)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
`

export const Nav = styled.nav<{ $isCollapsed?: boolean }>`
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: ${({ $isCollapsed }) => $isCollapsed ? 'center' : 'flex-start'};
  gap: 0px;
`

export const MenuItemsContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`

export const NavLink = styled(RouterNavLink)<{ $isSubNav?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ $isSubNav }) => ($isSubNav ? '6px 12px' : '6px 12px')};
  margin: ${({ $isSubNav }) => ($isSubNav ? '0' : '0.5rem 0px')};
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  line-height: 1.5rem;
  border-radius: ${borderRadius.lg};
  column-gap: 0.5rem;
  color: #484848;
  transition: all 275ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
  &:hover {
    background-color: ${colors.gray[200]};
    color: ${colors.gray[600]};
    font-weight: 600;
  }
  &.active {
    background-color: ${colors.blue[100]};
    color: ${colors.blue[700]};
    
    &:hover {
      background-color: ${colors.blue[100]};
    }
  }
`

export const ManageLink = styled(RouterNavLink)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '6px 12px',
  margin: '0.5rem 0',
  fontSize: '14px',
  fontWeight: fontWeight.medium,
  lineHeight: '1.5rem',
  borderRadius: borderRadius.lg,
  color: '#484848',
})

export const Logout = styled.span({
  color: '#C13030',
})

export const NavItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const NavLinkText = styled.div<{ $isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  
  > div {
    display: flex;
    align-items: center;
    gap: 12px;
    ${({ $isCollapsed }) => $isCollapsed && `
      justify-content: center;
    `}
  }
`;

export const NavLinkTextDigitalMarketing = styled(NavLinkText)`
  justify-content: space-between;
`;

export const DownArrow = styled(DownArrowIcon)<{ $isExpanded: boolean }>(
  {
    width: '0.85rem',
    transform: 'rotate(-90deg)',
    transition: 'transform .1s ease-in',
  },
  ({ $isExpanded }) => $isExpanded && { transform: 'rotate(0deg)' },
)

export const PopOutIcon = styled(Popout)({
  height: '1.5rem',
  width: '1.5rem',
})

export const DropdownLink = styled(RouterNavLink)({
  height: '50px',
  paddingLeft: '4rem',
  display: 'flex',
  alignItems: 'center',
  fontWeight: fontWeight.medium,
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#a6c8e3',
    color: colors.primary.DEFAULT,
    fontWeight: fontWeight.bold,
  },
  '&.active': {
    backgroundColor: '#a6c8e3',
    color: colors.primary.DEFAULT,
    fontWeight: fontWeight.bold,
  },
})

export const OuterLink = styled.a({
  marginTop: '1rem',
  fontSize: '0.875rem',
  textAlign: 'center',
  textDecoration: 'underline',
  '&:active,&:hover': {
    textDecoration: 'none',
  },
})

export const ToolBarHeader = styled.div({
  display: 'none',
  alignItems: 'center',
  flexDirection: 'row',
  marginRight: 30,
  [`@media ${breakpoints.sm}`]: {
    display: 'flex',
  },
})

export const SearchWrapperHeader = styled.div({
  position: 'relative',
  width: '100%',
  border: 'none',
  padding: 0,
  margin: 0,
})

export const ToolBar = styled.div<{ $isCollapsed?: boolean }>`
  display: flex;
  height: 100vh;
  align-items: ${({ $isCollapsed }) => $isCollapsed ? 'center' : 'flex-start'};
  flex-direction: column;
  column-gap: 12px;
  width: 100%;
`

export const ManageAccount = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 'auto',
  marginBottom: '10px',
})

export const SearchResults = styled.div({
  display: 'flex',
  width: '100%',
  position: 'absolute',
  background: '#fff',
  borderRadius: '0.5rem',
  padding: '6px 5px',
  flexDirection: 'column',
  fontWeight: 'normal',
  maxHeight: '428px',
  color: colors.black,
  fontSize: 13,
  marginTop: 1,
  boxShadow: shadow.DEFAULT,
  overflowY: 'auto',
})

export const Result = styled.span({
  padding: '7px 10px',
  borderRadius: '5px',
  cursor: 'pointer',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.10)',
  },
  '&.active': {
    background: 'rgba(27, 81, 164, 0.10)',
  },
})

export const Search = styled.input({
  width: '100%',
  color: '#292929',
  border: 'solid 1px #D7D7D7',
  fontSize: 15,
  backgroundImage: `url(${SearchIconURL})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '10px center',
  backgroundSize: '20px',
  position: 'relative',
  padding: '10px 40px',
  textOverflow: 'ellipsis',
  ['&::placeholder, &::-webkit-input-placeholder, &::-moz-placeholder']: {
    /* Add your placeholder styles here */
    color: '#292929',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&::-webkit-input-placeholder': {
    color: '#292929',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '&::-moz-placeholder': {
    color: '#292929',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

export const Icon = styled.img({
  width: '20px',
  height: '20px',
})

export const CollapseButton = styled.button`
  position: absolute;
  right: -10px;
  top: 30px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background: #f5f5f5;
  }
`

export const SearchWrapper = styled.div({
  paddingBottom: '24px',
  width: '100%',
  position: 'relative',
})

export const RedLogoutIcon = styled(LogoutIcon)({
  color: '#D32F2F'
})

export const SearchIcon = styled(SearchIconComponent)({
  marginTop: '32px !important'
})

export const SubNav = styled.div<{ $isCollapsed: boolean; $isExpanded: boolean }>`
  display: ${({ $isExpanded }) => ($isExpanded ? 'flex' : 'none')};
  flex-direction: column;
  padding-left: ${({ $isCollapsed }) => ($isCollapsed ? '0' : '32px')};
  margin-top: 4px;
  margin-bottom: 4px;
  max-height: ${({ $isExpanded }) => ($isExpanded ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  gap: 8px;
`;

export const NavButton = styled.button<{ $isCollapsed: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  margin: 0.5rem 0;
  font-size: 14px;
  font-weight: ${fontWeight.medium};
  line-height: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: ${borderRadius.lg};
  column-gap: 0.5rem;
  color: #484848;
  transition: all 275ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
  &:hover {
    background-color: ${colors.gray[200]};
    color: ${colors.gray[600]};
    font-weight: 600;
  }
  &[data-active="true"] {
    background-color: ${colors.blue[100]};
    color: ${colors.blue[700]};
    
    &:hover {
      background-color: ${colors.blue[100]};
    }
  }
  ${NavLinkText} {
    padding: 0;
    width: 100%;
    justify-content: space-between;
  }
`;

export const TooltipContent = styled.div`
  padding: 8px;
`

export const TooltipSubNav = styled.div`
  margin-top: 8px;
  padding-left: 8
`