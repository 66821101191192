import { getAllPartnerLocations, getPartner } from 'api'
import { useAuth0 } from 'libs/auth0-react'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Location, LocationSummary } from 'types'
import { LocationContextType } from 'types/LocationContextTypes'
import formatLocations from 'utils/formatLocations'

const LocationContext = createContext<LocationContextType | undefined>(undefined)

const locationQueryFields = {
  'fields[partner]': 'id,name,organization_id,organization_name,timezone',
}

export const mapLocation = (location: LocationSummary) => ({
  id: location?.id.toString(),
  name: location?.name,
  organization_id: location?.organization_id ? location?.organization_id.toString() : undefined,
  organization_name: location?.organization_name,
  timezone: location?.timezone,
})

export const LocationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate()

  const [locations, setLocations] = useState<LocationSummary[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [defaultLocation, setDefaultLocation] = useState<Location | null>(null)
  const { user } = useAuth0()
  const isAdminUser = user?.email?.includes('@chowlyinc.com') ?? false

  /**
   * fetchLocations:
   * For external users, fetches all locations recursively and stores them in global state.
   * For non-external users, fetches locations based on pagination and stores them in global state.
   */
  const fetchLocations = useCallback(
    async (page: number, allLocations: LocationSummary[] = []): Promise<void> => {
      try {
        setIsLoading(true)
        const response = await getAllPartnerLocations(page, '', locationQueryFields)
        const data = response?.data || []
        const formattedLocations = formatLocations(data)

        const updatedLocations = [...allLocations, ...formattedLocations]

        if (formattedLocations.length > 0 && page === 1 && !defaultLocation) {
          // Set defaultLocation if more than one location exists
          setDefaultLocation(mapLocation(formattedLocations[0]))
        }

        if (!isAdminUser) {
          if (response?.meta?.total && updatedLocations.length < response.meta.total) {
            setCurrentPage(page + 1)
            return fetchLocations(page + 1, updatedLocations) // Recursive call to fetch all locations (only for external users)
          } else {
            setHasMore(false)
          }
        } else {
          if (response?.meta?.total) {
            setHasMore(page * 25 < response.meta.total)
          }
        }
        setLocations(updatedLocations)
      } catch (err) {
        setError(new Error('Failed to fetch locations'))
      } finally {
        setIsLoading(false)
      }
    },
    [locations, isAdminUser, defaultLocation],
  )

  /**
   * searchLocations:
   * For external users, filters the already fetched locations from global state based on search term or page.
   * For non-external users, fetches locations based on search term or page and returns the results without saving to global state.
   */
  const searchLocations = useCallback(
    async (page: number, term: string): Promise<LocationSummary[]> => {
      try {
        setIsLoading(true)
        setHasMore(true)

        if (!isAdminUser) {
          const filteredLocations = locations.filter((location) =>
            location.name.toLowerCase().includes(term.toLowerCase()),
          )
          const paginatedLocations = filteredLocations.slice((page - 1) * 25, page * 25)
          if (paginatedLocations.length < 25) {
            setHasMore(false)
          }
          return paginatedLocations
        } else {
          const response = await getAllPartnerLocations(page, term, locationQueryFields)
          const data = response?.data || []
          const formattedLocations = formatLocations(data)
          if (formattedLocations.length < 25) {
            setHasMore(false)
          }
          return formattedLocations
        }
      } catch (err) {
        setError(new Error('Failed to search locations'))
        return []
      } finally {
        setIsLoading(false)
      }
    },
    [locations, isAdminUser],
  )

  useEffect(() => {
    if (!isLoading && user && currentPage === 1) {
      fetchLocations(currentPage)
    }
  }, [isAdminUser, user, currentPage])

  /**
   * findAndSetDefaultLocation:
   * Finds and sets the default location based on the provided locationId.
   * Also, updates the URL to the provided updateURL and updates the locationId when the location is not found.
   */
  const findAndSetDefaultLocation = useCallback(
    async (locationId: string, updateURL?: (id: string) => string) => {
      let defaultSelectedLocation: Location | null = null
      try {
        const { data } = await getPartner(locationId)
        const formattedLocation = formatLocations([data])
        const location = formattedLocation?.[0]
        defaultSelectedLocation = mapLocation(location)
        setDefaultLocation(defaultSelectedLocation)
      } catch (error) {
        // Redirect to the provided updateURL and update the locationId
        if (updateURL) {
          navigate(updateURL(defaultLocation?.id as string))
        }
      }
    },
    [defaultLocation?.id, navigate],
  )

  return (
    <LocationContext.Provider
      value={{
        locations,
        isLoading,
        error,
        hasMore,
        currentPage,
        defaultLocation,
        isAdminUser,
        fetchLocations,
        searchLocations,
        setLocations,
        setCurrentPage,
        setDefaultLocation,
        findAndSetDefaultLocation,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}

export { LocationContext }
