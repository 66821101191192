import { useCallback, useEffect, useState } from 'react'
import {
  Container,
  TabsContainer,
  Tabs,
  Tab,
  TableHeader,
  SearchInput,
  SearchIconWrapper,
  PublishedCampaignsTable,
  PaginationWrapper,
  RowsPerPageWrapper,
  PaginationText,
  CurrentPageInfo,
  PaginationSelect,
} from './styles'
import moment from 'moment'

import SearchIcon from '@mui/icons-material/Search';

import useSafeAsync from 'hooks/useSafeAsync';
import useSearchAlgoliaMultiIndex from 'hooks/useAlgoliaMultiIndexSearch';
import { getPrefixedIndexName } from 'utils/algolia';
import { FacebookCampaignInfo } from 'types/digitalMarketing';
import { AdStatus, GenericAd } from 'types/digitalMarketing/googleAd';
import { CampaignItem, NoData, Skeleton } from './components';
import { PublishedCampaignItem, PublishedCampaignItemWithPartnerName } from 'types/digitalMarketing/common';
import { debounce, deburr } from 'lodash';
import Pagination from '@mui/material/Pagination';
import { SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import DigitalMarketingDetailsDrawer from 'components/DigitalMarketingDetailsDrawer/DigitalMarketingDetailsDrawer';
import { useDigitalMarketingApi } from 'hooks';
import { Toast } from 'components';
import { Location } from 'types';
import { pauseOrResumeGoogleAd, pauseOrResumeMetaAd } from 'api/digitalMarketing';

const campaignsIndex = getPrefixedIndexName('Campaigns');
const googleAdsIndex = getPrefixedIndexName('AdCampaigns');

interface PublishedCampaignsProps {
  partners: Location[];
}

const PublishedCampaigns = ({ partners = [] }: PublishedCampaignsProps) => {
  const firstPartnerId = partners[0].id;
  const partnerIds = partners.map(p => p.id)
  const [digitalMarketingApi, { isLoading: isPausingOrResumingCampaign }] = useDigitalMarketingApi(firstPartnerId);
  const [isSearching, setIsSearching] = useState(false)
  const [publishedCampaigns, setPublishedCampaigns] = useState<PublishedCampaignItemWithPartnerName[]>([]);
  const [currentStatusFilter, setCurrentStatusFilter] = useState('all');
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState<PublishedCampaignItemWithPartnerName | null>(null);

  const filterByStatus = (element: PublishedCampaignItemWithPartnerName, statusFiltered?: string) => {
    const filter = statusFiltered || currentStatusFilter;
    let s = 'error';
    if (filter === 'all') return element;
    const isUpcomingGoogle = (element as GenericAd)?.startDate
      ? moment((element as GenericAd)?.startDate).isAfter(moment.utc())
      : false;
    const startDate = (element as FacebookCampaignInfo)?.ads && (element as FacebookCampaignInfo)?.ads[0]?.schedule?.startDate;
    let startDateStr;
    if (startDate && 'day' in startDate && 'month' in startDate && 'year' in startDate) {
      const { day, month, year } = startDate;
      startDateStr = moment({ year: Number(year), month: Number(month) - 1, day: Number(day) }).format('MM/DD/YY');
    }
    const isUpcomingMeta = startDateStr
      ? moment(startDateStr).isAfter(moment.utc())
      : false;
    const isFinishedGoogle = (element as GenericAd)?.endDate
      ? moment((element as GenericAd)?.endDate).isBefore(moment.utc())
      : false;
    const endDate = (element as FacebookCampaignInfo)?.ads && (element as FacebookCampaignInfo)?.ads[0]?.schedule?.endDate;
    let endDateStr;
    if (endDate && 'day' in endDate && 'month' in endDate && 'year' in endDate) {
      const { day, month, year } = endDate;
      endDateStr = moment({ year: Number(year), month: Number(month) - 1, day: Number(day) }).format('MM/DD/YY');
    }
    const isFinishedMeta = endDateStr
      ? moment(endDateStr).isBefore(moment.utc())
      : false;
    switch (element.status) {
      case 'live':
      case 'submitted':
        s = 'live'
        if (isUpcomingGoogle || isUpcomingMeta) {
          s = 'upcoming'
        }
        if (isFinishedGoogle || isFinishedMeta) {
          s = 'completed'
        }
        if ((element as GenericAd).withIssues) {
          s = 'error'
        }
        if ((element as GenericAd)?.google && element?.status === 'submitted' && !(element as GenericAd).withIssues) {
          s = 'publishing'
        }
        break;
      case 'failed':
      case 'with-issues':
        s = 'error'
        break;
      case 'in-process':
      case 'pre-approved':
      case 'draftApproved':
        s = 'publishing'
        break;
      case 'paused':
        s = 'paused'
        break;
      default:
        s = 'error'
    }
    if (s === filter) {
      return element
    }
    return false;
  };

  const hits = publishedCampaigns?.filter((i) => filterByStatus(i, currentStatusFilter));

  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
  };

  const handleSearchChange = async (val: string) => {
    await fetchCampaigns(val);
  };

  const multiSearch = useSearchAlgoliaMultiIndex(partnerIds);

  const safeAsync = useSafeAsync();

  const fetchCampaigns = useCallback(async (searchTerm?: string) => {
    setIsSearching(true);
    const params = { 
      filters: `NOT deletedOn:* AND NOT status:archived`,
      facetFilters: [
        partners.map((partner) => `chowlyPartnerId:${partner.id}`)
      ],
    }

    const [
      { hits: campaigns },
      { hits: googleAds }
    ] = await multiSearch<[FacebookCampaignInfo, GenericAd]>([
      { indexName: campaignsIndex, query: searchTerm || '', params },
      { indexName: googleAdsIndex, query: searchTerm || '', params }
    ]);

    const getAdDate = (item: PublishedCampaignItem) => {
      if(typeof (item as GenericAd).createdAt === 'string') {
        return new Date((item as GenericAd).createdAt).getTime();
      }

      return (item as FacebookCampaignInfo).created;
    }

    const excludedGoogleStatuses = [AdStatus.Draft, AdStatus.SmartFeed, AdStatus.Review, AdStatus.Archived, AdStatus.DraftRejected, AdStatus.DraftApproved];
    const googleAdsPublished = googleAds?.filter((ad) => !excludedGoogleStatuses.includes((ad as GenericAd).status));

    const excludedFbStatuses = ['draft', 'failed', 'archived'];
    const fbCampaignsPublished = campaigns?.filter((ad) => !excludedFbStatuses.includes((ad as FacebookCampaignInfo).status));

    const combined = [...fbCampaignsPublished, ...googleAdsPublished]
      .sort((a, b) => getAdDate(b) - getAdDate(a))
      .map(item => {
        const partner = partners.find(p => p.id === item.chowlyPartnerId);
        return {
          ...item,
          partnerName: partner?.name || 'Unknown Partner',
          organizationId: partner?.organization_id || '',
          organizationName: partner?.organization_name || '',
          partnerTz: partner?.timezone || '',
        }
      });

    setPublishedCampaigns(combined);
    setIsSearching(false);
  }, [multiSearch, partners]);

  const debouncedFetch = debounce(handleSearchChange, 500);

  const isActive = (scope: string) => {
    if (currentStatusFilter === scope) return true;
    return false;
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<unknown>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  };

  const startRow = (page - 1) * rowsPerPage;
  const endRow = Math.min(startRow + rowsPerPage, hits.length);
  const paginatedHits = hits?.slice(startRow, endRow);
 
  useEffect(() => {   
    safeAsync(fetchCampaigns())
    .catch((e) => {
      console.error(e);
      setIsSearching(false)
    });
  }, [partners, safeAsync, fetchCampaigns]);

  const handleOnShowItem = useCallback((item: PublishedCampaignItemWithPartnerName) => {
    setSelectedItem(item);
  }, []);

  const handleDrawerClose = () => {
    setSelectedItem(null);
  };

  const handlePauseResume = useCallback(async (item: PublishedCampaignItem, pause: boolean) => {
    if (!item) return;

    const updateActionedItem = () => {
      const updatedItem = { ...item, status: pause ? 'paused' : 'live' } as PublishedCampaignItemWithPartnerName;
    
      setPublishedCampaigns((prev) => [
        ...prev.filter((c) => c.id !== updatedItem.id), 
        updatedItem
      ]);
    
      setSelectedItem(updatedItem);
    };    

    const notifyError = (errorMsg: string) => {
      Toast.dismiss();
      Toast.error({ message: `We couldn't ${ pause ? 'pause' : 'resume' } the Ad, please try again. Error: ${errorMsg}` });
      return;
    }

    const notifySuccess = () => {
      updateActionedItem();
      Toast.dismiss();
      Toast.success({ message: `The Ad has been successfully ${ pause ? 'paused' : 'resumed' }.` }, { autoClose: 5000, hideProgressBar: true });
      return;
    }

    Toast.loading({ message: `Attempting to ${ pause ? 'pause' : 'resume' } the Ad. Please wait...` });
    
    try {
      if(item?.type === 'google'){
        await pauseOrResumeGoogleAd(item.chowlyPartnerId || '', item.id, pause);
      } else {
        await pauseOrResumeMetaAd(item.chowlyPartnerId || '', item.id);
      }
      return notifySuccess();
    } catch (error) {
      return notifyError(error instanceof Error ? error.message : 'Unknown error occurred');
    }
  }, [digitalMarketingApi]);

  return (
      <Container>
        <TabsContainer>
          <Tabs>
            <Tab active={isActive('all')} onClick={() => setCurrentStatusFilter('all')}>
              All ({publishedCampaigns?.filter((i) => filterByStatus(i, 'all')).length})
            </Tab>
            <Tab active={isActive('live')} onClick={() => setCurrentStatusFilter('live')}>
              Live ({publishedCampaigns?.filter((i) => filterByStatus(i, 'live')).length})
            </Tab>
            <Tab active={isActive('completed')} onClick={() => setCurrentStatusFilter('completed')}>
              Completed ({publishedCampaigns?.filter((i) => filterByStatus(i, 'completed')).length})
            </Tab>
            <Tab active={isActive('upcoming')} onClick={() => setCurrentStatusFilter('upcoming')}>
              Upcoming ({publishedCampaigns?.filter((i) => filterByStatus(i, 'upcoming')).length})
            </Tab>
            <Tab active={isActive('publishing')} onClick={() => setCurrentStatusFilter('publishing')}>
              Publishing ({publishedCampaigns?.filter((i) => filterByStatus(i, 'publishing')).length})
            </Tab>
            <Tab active={isActive('paused')} onClick={() => setCurrentStatusFilter('paused')}>
              Paused ({publishedCampaigns?.filter((i) => filterByStatus(i, 'paused')).length})
            </Tab>
            <Tab active={isActive('error')} onClick={() => setCurrentStatusFilter('error')}>
              Errors ({publishedCampaigns?.filter((i) => filterByStatus(i, 'error')).length})
            </Tab>
          </Tabs>
          <div>
            {searchVisible ? (
              <SearchInput
                visible={searchVisible}
                type="text"
                placeholder="Search by name..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                  const trimmedValue = deburr(e.target.value.trim()).toLowerCase();
                  debouncedFetch(trimmedValue);
                }}
                autoFocus
              />
            ) : (
              <SearchIconWrapper onClick={toggleSearch} data-testid="search-icon">
                <SearchIcon />
              </SearchIconWrapper>
            )}
          </div>
        </TabsContainer>
        <PublishedCampaignsTable>
          <TableHeader>
            <div>Campaign Name</div>
            <div>Location</div>
            <div>Budget</div>
            <div>Start Date</div>
            <div>End Date</div>
            <div>Marketing Platform</div>
            <div></div>
          </TableHeader>
          <div data-cy="table-body">
              {isSearching ? (
                <Skeleton />
              ) : (
                (partners.length === 0 || hits.length === 0) ? (
                  <NoData />
                ) : (
                  paginatedHits?.map((campaign) => {
                    return (
                      <CampaignItem 
                        key={campaign.id}
                        item={campaign}
                        onAction={handleOnShowItem}
                      />
                    )
                  })
                )
              )}
          </div>
          {hits?.length > 0 && (
            <PaginationWrapper>
              <RowsPerPageWrapper>
                <PaginationText>Rows per Page:</PaginationText>
                <PaginationSelect
                  value={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </PaginationSelect>
              </RowsPerPageWrapper>

              <CurrentPageInfo>
                {startRow + 1}-{endRow} of {hits?.length}
              </CurrentPageInfo>

              <Pagination
                count={Math.ceil(hits?.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                variant="text"
                shape="rounded"
              />
            </PaginationWrapper>
          )}
        </PublishedCampaignsTable>
        {selectedItem && partners.length > 0 && (
          <DigitalMarketingDetailsDrawer
            open
            item={selectedItem}
            partnerId={selectedItem.chowlyPartnerId || ''}
            onClose={handleDrawerClose}
            onPauseResume={handlePauseResume}
            disableActionButtons={isPausingOrResumingCampaign}
          />
      )}
      </Container>
  )
}

export default PublishedCampaigns