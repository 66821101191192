import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'

import client from '../chowly'
import { LoyaltyRedeemableResponse, LoyaltyRedeemablesResponse } from './models'

export const getLoyaltyRedeemables = async (organizationId: string, page = 1, query = '') => {
  const params = {
    ...(page > 1 && { page }),
    ...(query.length > 0 && { q: query }),
  }
  const url = generatePath(chowlyApi.loyaltyRedeemables, { organizationId })
  const { data } = await client.get<LoyaltyRedeemablesResponse>(url, { params })
  return data
}

export const getLoyaltyRedeemable = async (organizationId: string, id: string) => {
  const url = generatePath(chowlyApi.loyaltyRedeemable, { organizationId, id })
  const { data } = await client.get<LoyaltyRedeemableResponse>(url)
  return data
}

export const createLoyaltyRedeemable = async (organizationId: string, payload: any) => {
  const url = generatePath(chowlyApi.loyaltyRedeemables, { organizationId })
  const { data } = await client.post<LoyaltyRedeemableResponse>(url, payload)
  return data
}

export const updateLoyaltyRedeemable = async (organizationId: string, id: string, payload: any) => {
  const url = generatePath(chowlyApi.loyaltyRedeemable, { organizationId, id })
  const { data } = await client.put<LoyaltyRedeemableResponse>(url, payload)
  return data
}

export const deleteLoyaltyRedeemable = async (organizationId: string, id: string) => {
  const url = generatePath(chowlyApi.loyaltyRedeemable, { organizationId, id })
  await client.delete(url)
}
