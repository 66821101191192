import Tippy from '@tippyjs/react'
import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg'
import { Button } from 'components'
import { useIsMobile } from 'hooks'

type Props = {
  title: string
  description: string
  isDisabled?: boolean
  onClick: () => void
}

const LinkButton = ({
  title,
  description,
  isDisabled,
  onClick,
}: Props) => {
  const { isMobile } = useIsMobile()

  return (
    <>
      {/* Don't show tooltip on mobile */}
      {!isMobile ? (
        <Tippy content={description}>
            <Button
              onClick={onClick}
              narrow={true}
              nowrap={true}
              disabled={isDisabled}
              size='small'
              variant='outline'
            >
              <ExternalLink />
              {title}
            </Button>
        </Tippy>
      ) : (
          <Button
            style={{ fontSize: isMobile ? '0.700rem' : '0.875rem' }}
            onClick={onClick}
            narrow={true}
            nowrap={true}
            disabled={isDisabled}
            size='small'
            variant='outline'
          >
            <ExternalLink />
            {title}
          </Button>
      )}
    </>
  )
}

export default LinkButton
