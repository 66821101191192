import styled from 'styled-components'
import { breakpoints, colors } from 'theme'

const sigmaHeaderHeight = 25
const sigmaBottomTabsHeight = 10

export const Container = styled.div({
  width: '100%',
  height: '85vh',
  overflow: 'scroll',
  borderRadius: '8px',
  maxWidth: '1280px',
  backgroundColor: colors.white,
})

export const Embed = styled.iframe<{ top?: number }>(
  {
    width: 'calc(100% - 96px)',
    margin: '0 48px',
    height: `calc(100% + ${sigmaHeaderHeight + sigmaBottomTabsHeight}px)`,
    [`@media ${breakpoints.xxs}`]: {
      margin: 0,
      width: '100%',
    },
  },
  ({ top }) =>
    typeof top === 'number' && {
      height: `calc(100% + ${top + sigmaBottomTabsHeight}px)`,
    },
)
