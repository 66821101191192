import styled from 'styled-components'
import * as theme from 'theme'

export const Header = styled.div({
    color: theme.colors.primary.medium,
    fontFamily: theme.fontFamily.inter,
    fontSize: theme.typography.semiXS.fontSize,
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.bold,
    lineHeight: '150%', /* 22.5px */
})

export const HeaderContainer = styled.div({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
})

export const Value = styled.div({
    color: theme.colors.primary.medium,
    fontFamily: theme.fontFamily.inter,
    fontSize: theme.typography.semiXS.fontSize,
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.normal,
    lineHeight: '150%', /* 22.5px */
})

export const FeeContainer = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
})

export const Icon = styled.img<{ backGroundColor?: boolean }>(
    {
        width: '24px',
        height: '24px',
        padding: '4px',
        borderRadius: '6px',
        border: 'solid 0.75px',
    },
    ({ backGroundColor }) => (backGroundColor ? { backgroundColor: '#1B51A4' } : { backgroundColor: '#none', color: '#1B51A4' }),
)

export const IconContainer = styled.div({
    display: 'flex',
    gap: '4px'
})

export const ModalHeader = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
})

export const ModalText = styled.div({
    color: theme.colors.gray.mediumLight,
    fontFamily: theme.fontFamily.inter,
    fontSize: theme.typography.xxxs.fontSize,
    fontStyle: 'normal',
    fontWeight: theme.fontWeight.normal,
    lineHeight: '150%', /* 15px */
})

export const ModalFooter = styled.div({
    display: 'flex',
    gap: '12px',
    paddingTop: '16px'
})

export const PrimaryButton = styled.div<{ backGroundColor?: boolean }>(
    {
        fontFamily: theme.fontFamily.inter,
        fontSize: '15px',
        fontWeight: theme.fontWeight.semibold,
        lineHeight: '22.5px',
        textAlign: 'left',
        color: '#FEFEFE',
        height: '39px',
        padding: '8px 16px 8px 16px',
        gap: '8px',
        border: 'solid 1px #1B51A4',
        borderRadius: '6px',
        opacity: '0px',
    },
    ({ backGroundColor }) => (backGroundColor ? { backgroundColor: '#1B51A4' } : { backgroundColor: '#none', color: '#1B51A4' }),
)