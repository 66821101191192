import styled from 'styled-components';
import { Typography, Box, TextField, Dialog } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { Button } from 'components'
import { breakpoints, colors, fontFamily, fontWeight, typography } from 'theme'


export const StyledDialogTitle = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 8px;
`;

export const StyledInputAdornment = styled(Box)`
  & .MuiInputBase-inputAdornedStart {
    box-shadow: none;
  }
`;

export const StyledSpan = styled.span`
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    boxShadow: 'none',
  },
});

export const StyledIconButton = styled(IconButton)`
  color: #777777;
`;

export const StyledDialog = styled(Dialog)`
  width: 100%;
  padding: 8px;
  margin-top: 0;
`;

export const BaseButton = styled(Button)<{ variant?: string; noBorder?: boolean }>(
  {
    minWidth: 'auto',
    padding: '8px 16px 8px 16px',
    fontFamily: fontFamily.inter,
    fontSize: typography.semiXS.fontSize,
    fontWeight: fontWeight.semibold,
    lineHeight: typography.semiXS.lineHeight,
    textWrap: 'nowrap',
    letterSpacing: '0em',
    textAlign: 'left',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    color: colors.blue[700],
    borderColor: colors.primary.light,
    borderWidth: 1,
    height: '39px',
    [`@media ${breakpoints.xxs}`]: {
      fontSize: typography.xs.fontSize,
      lineHeight: typography.xs.lineHeight,
    },
  },
  ({ variant, noBorder }) => ({
    ...(variant === 'solid' ? {
      backgroundColor: colors.blue[700],
      color: colors.gray[450],
    } : {
      '&:hover': {
        backgroundColor: colors.blue[100],
      },
    }),
    ...(noBorder && {
      border: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    }),
  }),
)