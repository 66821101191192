import React, { useState, useEffect, useRef, ChangeEvent, useCallback, useMemo } from 'react'
import { debounce } from 'lodash'
import { Chip } from '@mui/material'
import { Spinner } from 'components'
import { v4 as uuidv4 } from 'uuid'
import { showError } from 'utils'
import * as Styled from './styles'
import { Location, LocationSummary } from 'types'
import { useGetLocations } from 'hooks'

type MultiLocationDropdownProps = {
  onLocationChange: (locations: Location[]) => void,
  checkDefaultAll?: boolean,
}

const MultiLocationDropdown: React.FC<MultiLocationDropdownProps> = ({
  onLocationChange,
  checkDefaultAll = false,
}) => {
  const { locations, isLoading, hasMore, defaultLocation, isAdminUser, searchLocations } = useGetLocations()
  const [filteredLocations, setFilteredLocations] = useState<LocationSummary[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [showDropDown, setShowDropDown] = useState(false)
  const [page, setPage] = useState(1)
  const [checkedLocations, setCheckedLocations] = useState<Location[]>([])
  const [isAllSelected, setIsAllSelected] = useState(false)
  const selectWrapperRef = useRef<HTMLDivElement | null>(null)
  const dropdownResultsRef = useRef<HTMLDivElement | null>(null)
  const searchRef = useRef<HTMLInputElement | null>(null)
  const activeRequestId = useRef<string | null>(null)
  const hasFetched = useRef<boolean>(false)
  const filterBoxRef = useRef<HTMLInputElement | null>(null)
  
  // Debounced filter for location search
  const debounceFetch = debounce(async (currentPage: number, term: string, requestId: string) => {
    // Ensure this is the latest request
    if (requestId !== activeRequestId.current) return
    
    try {
      const newLocations = await searchLocations(currentPage, term)
      setFilteredLocations((prev) =>
        currentPage === 1 ? newLocations : [...prev, ...newLocations],
      )
    } catch (error) {
      if (requestId === activeRequestId.current) showError(error)
    } finally {
      setPage(currentPage)
    }
  }, 300)

  const debounceWithRequestId = (nextPage: number, term: string) => {
    const requestId = uuidv4()
    activeRequestId.current = requestId
    debounceFetch(nextPage, term, requestId)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value
    setFilteredLocations([])
    setSearchTerm(term)
    setPage(1)
    debounceWithRequestId(1, term)
  }

  const resetSearch = () => {
    setSearchTerm('')
    setPage(1)
    isAdminUser ? debounceWithRequestId(1, '') : setFilteredLocations(locations)
    setShowDropDown(true)
  }

  const memoizedSelectedLocations = useMemo(() => {
    if (isAdminUser) {
      if (Array.isArray(locations) && Array.isArray(checkedLocations)) {
        return locations
          .concat(locations.filter(location => checkedLocations.some(loc => loc.id === location.id.toString()))) // Include already selected locations
          .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)) // Remove duplicates
          .map(location => ({
            id: location.id.toString(),
            name: location.name,
            organization_id: location.organization_id,
            organization_name: location.organization_name,
            timezone: location.timezone,
          }))
      } else {
        return []
      }    
    } else {
      return Array.isArray(checkedLocations) ? checkedLocations : []
    }
  }, [locations, checkedLocations, isAdminUser])

  const handleCheckboxToggle = useCallback((location: Location, isChecked: boolean) => {
    const { id, name, organization_id, organization_name, timezone } = location

    setCheckedLocations(prev => {
      let updatedCheckedLocations = [...prev] // Copy the previous checked locations
  
      if (isChecked) {
        // Add location directly to the checked locations (for admin, they may not be in `locations` array)
        updatedCheckedLocations = [
          ...updatedCheckedLocations, 
          { id: id.toString(), name, organization_id, organization_name, timezone }
        ]
      } else {
        updatedCheckedLocations = updatedCheckedLocations.filter(location => location.id !== id.toString())
      }
  
      // Pass the updated list to the parent component
      onLocationChange(updatedCheckedLocations.map(loc => ({ ...loc, id: loc.id.toString() })))
      return updatedCheckedLocations
    })
  }, [onLocationChange])

  const handleAllLocationsToggle = (isChecked: boolean) => {
    setFilteredLocations(locations)
    setIsAllSelected(isChecked)
  
    if (isChecked) {
      const allSelectedLocations = locations.map((location: LocationSummary) => ({
        id: location.id.toString(),  
        name: location.name,
        organization_id: location.organization_id,
        organization_name: location.organization_name,
        timezone: location.timezone,
      }))
      setCheckedLocations(allSelectedLocations)
    } else {
      setCheckedLocations([])
    }
  }

  const handleScroll = useCallback(debounce(() => {
    if (
      searchRef.current &&
      searchRef.current.scrollTop + searchRef.current.clientHeight >= searchRef.current.scrollHeight - 20 &&
      !isLoading && hasMore
    ) {
      const nextPage = page + 1
      debounceWithRequestId(nextPage, searchTerm)
    }
  }, 300), [page, isLoading, searchTerm, showDropDown])

  useEffect(() => {
    const container = searchRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectWrapperRef.current && 
        !selectWrapperRef.current.contains(event.target as Node) &&
        dropdownResultsRef.current &&
        !dropdownResultsRef.current.contains(event.target as Node)
      ) {
        setShowDropDown(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  // Update checkedLocations if defaultLocation exists
  useEffect(() => {
    if (defaultLocation && locations.length > 0) {
      const { id, name, organization_id, organization_name, timezone } = defaultLocation
      const updatedLocations = [{
        id: id.toString(),
        name,
        organization_id,
        organization_name,
        timezone,
      }]
      setCheckedLocations(updatedLocations)
      onLocationChange(updatedLocations)
    }
  }, [defaultLocation, locations])

  // Trigger location change when checkedLocations changes (only for external users)
  useEffect(() => {
    if (!isAdminUser) {
      onLocationChange(memoizedSelectedLocations.map(loc => ({ ...loc, id: loc.id.toString() })))
    }
  }, [memoizedSelectedLocations, onLocationChange, isAdminUser])

  // Ensure the effect runs only once when data is available
  useEffect(() => {
    if (locations && locations.length > 0 && !hasFetched.current) {
      hasFetched.current = true
      setFilteredLocations(locations)
    }

    if (checkDefaultAll) {
      setIsAllSelected(true)
      const allLocations = locations.map((location: LocationSummary) => ({
        id: location.id.toString(),
        name: location.name,
        organization_id: location.organization_id,
        organization_name: location.organization_name,
        timezone: location.timezone,
      }))
      setCheckedLocations(allLocations)
      onLocationChange(allLocations)
    }
  }, [locations, checkDefaultAll])

  // focus the input when dropdown is shown
  useEffect(() => {
    if (showDropDown && filterBoxRef.current) {
      filterBoxRef.current.focus()
      if (searchTerm) {
        filterBoxRef.current.select()
      }
    }
  }, [showDropDown])

  return (
    <Styled.Container>
      <Styled.DropdownWrapper>
        <Styled.SelectWrapper 
          ref={selectWrapperRef}
          onClick={(e) => {
            // Prevent closing if the click is inside the dropdown
            if (dropdownResultsRef.current && dropdownResultsRef.current.contains(e.target as Node)) {
              return
            }
            setShowDropDown(prev => !prev)
          }}
          isActive={showDropDown}
        >
          <Styled.SelectBox>
            <Styled.TextWrapper>
              <Styled.Input
                value={`Locations `}
                onChange={handleInputChange}
                readOnly={true}
              />
              <Styled.ChipWrapper>
                {checkedLocations.map((location: Location) => (
                  <Chip
                    key={location.id}
                    label={location.name}
                    onDelete={() => handleCheckboxToggle(location, false)}
                    style={{ margin: '2px' }}
                    size="small"
                  />
                ))}
              </Styled.ChipWrapper>
            </Styled.TextWrapper>
            <Styled.DownArrow $isExpanded={showDropDown} />
          </Styled.SelectBox>
        </Styled.SelectWrapper>

        {/* Dropdown results, only show when dropdown is expanded */}
        {showDropDown && (
          <Styled.DropdownResults ref={dropdownResultsRef}>
            <Styled.FilterContainer>
              <Styled.FilterBox
                ref={filterBoxRef}
                type="text" 
                value={searchTerm} 
                onChange={handleInputChange} 
                placeholder="Filter locations"
              />
              {!!searchTerm.length && (
                <Styled.ClearButton onClick={resetSearch} />
              )}
            </Styled.FilterContainer>
            {!isAdminUser && (
              <Styled.AllCheckedWrapper>
                <Styled.CheckboxWrapper style={{ marginRight: '8px', flex: 'none' }}>
                  <Styled.AllChecked
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={(e) => handleAllLocationsToggle(e.target.checked)}
                  />
                </Styled.CheckboxWrapper>
                <div style={{ display: 'flex', width: 'auto', justifyContent: 'flex-start', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                  All Locations ({checkedLocations.length})
                </div>
              </Styled.AllCheckedWrapper>
            )}
            <Styled.ResultsWrapper ref={searchRef}>
              {filteredLocations.map((location: LocationSummary) => {
                const isChecked = checkedLocations.some(loc => loc.id === location.id.toString())
                return (
                  <Styled.Result
                    key={location.id}
                    onClick={() => handleCheckboxToggle({ ...location, id: location.id.toString()}, !isChecked)}
                  >
                    <Styled.CheckboxWrapper>
                      <Styled.Checkbox
                        type="checkbox"
                        checked={isChecked}
                        readOnly
                      />
                    </Styled.CheckboxWrapper>
                    <span>{location.name}</span>
                  </Styled.Result>
                )  
              })}
              {isLoading && <Styled.SpinnerWrapper><Spinner /></Styled.SpinnerWrapper>}
              {filteredLocations.length === 0 && !isLoading && searchTerm.length > 0 && (
                <Styled.NoResults>No locations found.</Styled.NoResults>
              )}
            </Styled.ResultsWrapper>
          </Styled.DropdownResults>
        )}
      </Styled.DropdownWrapper>
    </Styled.Container>
  )
}

export default MultiLocationDropdown