import { useState, useCallback, useMemo } from 'react'
import PublishedCampaigns from './PublishedCampaigns/PublishedCampaigns'
import EmailMarketingCampaigns from './EmailMarketingCampaigns/EmailMarketingCampaigns'
import {
  PageContainer,
  CampaignsContainer,
  Tab,
  Tabs,
  TabsContainer,
  ToDoContainer,
  ToDoTitle,
  LocationDropdownWrapper,
} from './styles'
import ToDo from './ToDo/ToDo'
import MultiLocationDropdown from 'components/MultiLocationDropdown/MultiLocationDropdown'
import { NoLocationSelected } from './ToDo/components'
import { Location } from 'types'
import { useGetLocations } from 'hooks'

const DigitalMarketing = () => {
  const [currentTabFilter, setCurrentTabFilter] = useState('paid_ads');
  const [partners, setPartners] = useState<Location[]>([]);
  const [hasInteracted, setHasInteracted] = useState(false);
  const { isAdminUser } = useGetLocations()
  
  const isActive = (scope: string) => {
    if (currentTabFilter === scope) return true;
    return false;
  }

  const handleLocationChange = useCallback((locations: Location[]): void => {
    if (locations.length > 0) {
      setPartners(locations);
      setHasInteracted(true);
    } else {
      setPartners([]);
    }
  }, []);

  const memoizedPartners = useMemo(() => partners, [partners]);

  return (
    <PageContainer>
      <LocationDropdownWrapper>
        <MultiLocationDropdown 
          onLocationChange={handleLocationChange}
          checkDefaultAll={!isAdminUser ? true : false}
        />
      </LocationDropdownWrapper>
      {hasInteracted && partners.length === 0 ? (
        <CampaignsContainer>
          <NoLocationSelected />
        </CampaignsContainer>
      ) : (
        <>
          <ToDoContainer>
            <ToDoTitle>Digital Marketing To-Do</ToDoTitle>
            {memoizedPartners.length > 0 && <ToDo partners={memoizedPartners} />}
          </ToDoContainer>

          <TabsContainer>
            <Tabs>
              <Tab active={isActive('paid_ads')} onClick={() => setCurrentTabFilter('paid_ads')}>Paid Ads</Tab>
            </Tabs>
            <Tabs>
              <Tab active={isActive('email')} onClick={() => setCurrentTabFilter('email')}>Email</Tab>
            </Tabs>
          </TabsContainer>

          <CampaignsContainer>
            {memoizedPartners.length > 0 && currentTabFilter === 'paid_ads' && (
              <PublishedCampaigns partners={memoizedPartners} />
            )}
            {memoizedPartners.length > 0 && currentTabFilter === 'email' && (
              <EmailMarketingCampaigns partners={memoizedPartners} />
            )}
          </CampaignsContainer>
        </>
      )}
    </PageContainer>
  )
}

export default DigitalMarketing
