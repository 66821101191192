import React from 'react'
import Modal from 'features/modal/Modal'
import { PromoCode } from 'api/promoCodes/types'
import * as Styled from './../styles'

interface PromoCodesModalProps {
  isOpen: boolean
  onClose: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onCancelCoupon: (event: React.MouseEvent<HTMLButtonElement>) => void
  promoCode: PromoCode | null
  title: string
  description: string
  submitText: string
}

const PromoCodesModal: React.FC<PromoCodesModalProps> = ({
  isOpen,
  onClose,
  onCancelCoupon,
  promoCode,
  title,
  description,
  submitText,
}) => {
  if (!promoCode) return null

  return (
    <Modal isOpen={isOpen} onClose={onClose} width='500px' height='auto'>
      <Styled.ModalContentWrapper>
        <Styled.ModalTitle>{title}</Styled.ModalTitle>
        <Styled.ModalText>{description}</Styled.ModalText>
        <Styled.ModalActions>
          <Styled.ModalButton onClick={(event) => onClose(event)} customVariant='secondary'>
            Go Back
          </Styled.ModalButton>
          <Styled.ModalButton onClick={onCancelCoupon} customVariant='failure'>
            {submitText}
          </Styled.ModalButton>
        </Styled.ModalActions>
      </Styled.ModalContentWrapper>
    </Modal>
  )
}

export default PromoCodesModal
