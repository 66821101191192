import * as Sentry from '@sentry/react'
import { LocationProvider } from 'context/LocationContext'
import { OrganizationProvider } from 'context/OrganizationContext'
import UberEatsAuthLinkCheck from 'features/auth/UberEatsAuthLinkCheck'
import Billing from 'features/billing/Billing'
import CustomerSurveys from 'features/customer-surveys/CustomerSurveys'
import { DesignSystemView, NotificationBannerView, ToastView } from 'features/DesignSystemView'
import DigitalMarketing from 'features/digital-marketing/DigitalMarketing'
import Loyalty from 'features/digital-marketing/Loyalty/Loyalty'
import PromoCodes from 'features/digital-marketing/PromoCodes/PromoCodes'
import PromoCodesForm from 'features/digital-marketing/PromoCodes/PromoCodesForm'
import CreateNewMenu from 'features/manage-locations/IndividualMenu/CreateMenu/CreateNewMenu'
import ManagePlatform from 'features/manage-locations/ManagePlatform'
import DeliveryBuyDown from 'features/reporting/DeliveryBuyDown'
import DMReporting from 'features/reporting/DigitalMarketing/DigitalMarketing'
import { Suspense } from 'react'
import { Flags, FlagsProvider } from 'react-feature-flags'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from 'styled-components'

import { ErrorMessage, LoadingScreen, MainLayout, NotFound, PrivateRoute } from './components'
import SignupConfirmation from './components/ManageAccount/SignupConfirmation'
import Success from './components/Success/Success'
import { appSettings, flags } from './config'
import Login from './features/auth/Login'
import Logout from './features/auth/Logout'
import Register from './features/auth/Register'
import Verify from './features/auth/Verify'
import Health from './features/health/Health'
import AddLocation from './features/manage-locations/AddLocation/AddLocation'
import IndividualLocation from './features/manage-locations/IndividualLocation/IndividualLocation'
import IndividualMenu from './features/manage-locations/IndividualMenu/IndividualMenu'
import OrderManagement from './features/order-managment/OrderManagement'
import Disputes from './features/reporting/Disputes'
import Locations from './features/reporting/Locations'
import Orders from './features/reporting/Orders'
import PlatformStats from './features/reporting/PlatformStats'
import ProductMix from './features/reporting/ProductMix'
import Reconciliation from './features/reporting/Reconciliation'
import Reporting from './features/reporting/Reporting'
import Sales from './features/reporting/Sales'
import SmartPricing from './features/reporting/SmartPricing'
import Wow from './features/reporting/Wow'
import theme from './theme'
import * as url from './utils/urls'

function App() {
  return (
    <Sentry.ErrorBoundary fallback={ErrorMessage}>
      <FlagsProvider value={flags}>
        <RecoilRoot>
          <ThemeProvider theme={theme}>
            <Suspense fallback={<LoadingScreen title='Loading...' />}>
              <BrowserRouter>
                <OrganizationProvider>
                  <LocationProvider>
                    <Routes>
                      <Route path={url.getLoginURL()} element={<Login />} />
                      <Route path={url.getLogoutURL()} element={<Logout />} />
                      <Route path={url.getVerifyURL()} element={<Verify />} />
                      <Route path={url.getRegisterURL()} element={<Register />} />
                      <Route path={url.getConfirmationURL()} element={<SignupConfirmation />} />
                      <Route
                        path={url.getDoordashSuccessURL()}
                        element={
                          <Success
                            title={'Doordash Authenticated'}
                            message={
                              'please notify your chowly contact that you have successfully authenticated with doordash'
                            }
                          />
                        }
                      />
                      <Route
                        path={url.getUberEatsAuthLinkCheckURL()}
                        element={<UberEatsAuthLinkCheck />}
                      />
                      <Route
                        path='/'
                        element={
                          <PrivateRoute>
                            <MainLayout />
                          </PrivateRoute>
                        }
                      >
                        <Route index path={url.getHealthURL()} element={<Health />} />
                        <Route path={url.getAnalyticsLocationsURL()} element={<Locations />} />
                        <Route path={url.getAnalyticsSalesURL()} element={<Sales />} />
                        <Route path={url.getAnalyticsOrdersURL()} element={<Orders />} />
                        <Route path={url.getAnalyticsProductMixURL()} element={<ProductMix />} />
                        <Route
                          path={url.getAnalyticsPlatformStatsURL()}
                          element={<PlatformStats />}
                        />
                        <Route path={url.getAnalyticsURL()} element={<Reporting />} />
                        <Route
                          path={url.getAnalyticsReconciliationURL()}
                          element={<Reconciliation />}
                        />
                        <Route path={url.getAnalyticsDisputesURL()} element={<Disputes />} />
                        <Route
                          path={url.getAnalyticsSmartPricingURL()}
                          element={<SmartPricing />}
                        />
                        <Route path={url.getAnalyticsWowURL()} element={<Wow />} />
                        <Route
                          path={url.getAnalyticsDeliveryBuyDownURL()}
                          element={<DeliveryBuyDown />}
                        />
                        <Route path={url.getAnalyticsDMReportingURL()} element={<DMReporting />} />
                        <Route path={url.getBillingURL()} element={<Billing />} />
                        <Route path={url.getDigitalMarketingURL()} element={<DigitalMarketing />} />
                        <Route path={url.getPromoCodesURL()} element={<PromoCodes />} />
                        <Route path={url.getPromoCodesNewURL()} element={<PromoCodesForm />} />
                        <Route path={url.getLoyaltyURL()} element={<Loyalty />} />
                        <Route path={url.getCustomerSurveysURL()} element={<CustomerSurveys />} />
                        <Route path={url.getOrderManagementURL()} element={<OrderManagement />} />
                        <Route
                          path={url.getAddLocationURL()}
                          element={
                            <Flags authorizedFlags={['developmentOnly', 'stagingOnly']}>
                              <AddLocation />
                            </Flags>
                          }
                        />

                        <Route
                          path={url.getMenuManagementURL(':locationId')}
                          element={<IndividualLocation />}
                        />

                        <Route
                          path={url.getDefaultMenuManagementURL()}
                          element={<IndividualLocation />}
                        />
                        <Route
                          path={url.getMenuManagementGBPProfileSelectorURL(':locationId')}
                          element={<IndividualLocation />}
                        />
                        <Route
                          path={url.getMenuManagementPreviewURL(':locationId', ':menuSectionId')}
                          element={<IndividualMenu />}
                        />
                        <Route
                          path={url.getMenuManagementEditURL(':locationId', ':menuSectionId')}
                          element={<IndividualMenu isEditor />}
                        />
                        <Route
                          path={url.getMenuManagementNewURL(':locationId')}
                          element={<CreateNewMenu />}
                        />
                        <Route
                          path={url.getMenuManagementPlatformURL(':locationId', ':platformId')}
                          element={<ManagePlatform />}
                        />
                        {/* Routes for reviewing design style components */}
                        {!appSettings.isProduction && (
                          <Route path='design-system' element={<DesignSystemView />}>
                            <Route path='toast' element={<ToastView />} />
                            <Route
                              path='notification-banner'
                              element={<NotificationBannerView />}
                            />
                          </Route>
                        )}

                        {/* Ensure old routes redirect to a working url */}
                        <Route path='/home' element={<Navigate to='/' />} />
                        <Route path='/:locationId/home' element={<Navigate to='/' />} />
                        <Route
                          path='/:locationId/order-management'
                          element={<Navigate to={url.getOrderManagementURL()} />}
                        />
                        <Route
                          path='/:locationId/digital-marketing'
                          element={<Navigate to={url.getDigitalMarketingURL()} />}
                        />
                        <Route
                          path='/:locationId/analytics'
                          element={<Navigate to={url.getAnalyticsURL()} />}
                        />
                        <Route
                          path='/:locationId/analytics/*'
                          element={<Navigate to={url.getAnalyticsURL()} />}
                        />
                        <Route
                          path='/:locationId/billing'
                          element={<Navigate to={url.getBillingURL()} />}
                        />
                        <Route path='/manage-locations' element={<Navigate to='/' />} />

                        {/* Routes with a dynamic :locationId are setup on clicking the menu,
                          so there is not way to redirect them to the expected locationId from here */}
                        <Route
                          path='/:locationId/manage-platform/*'
                          element={<Navigate to='/' />}
                        />
                        <Route path='/:locationId/menu-management' element={<Navigate to='/' />} />
                        <Route
                          path='/:locationId/menu-management/menus/*'
                          element={<Navigate to='/' />}
                        />
                        <Route path='/manage-locations/:locationId' element={<Navigate to='/' />} />
                      </Route>
                      <Route path='*' element={<NotFound />} />
                    </Routes>
                  </LocationProvider>
                </OrganizationProvider>
              </BrowserRouter>
              <ToastContainer autoClose={false} draggable={false} />
            </Suspense>
          </ThemeProvider>
        </RecoilRoot>
      </FlagsProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
