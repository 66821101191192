export interface ConversationsListResponse {
  data: {
    conversations: Array<Conversation>;
  }
}

export interface ConversationTimelineResponse {
  data: {
    analytic_events: ConversationEvent[];
  }
}

export interface Conversation {
    resolved_by?: string;
    auto_resolved?: boolean;
    updated_at: Date;
    opened_by: Array<string>;
    created_at: Date;
    company: string;
    location: string;
    _id: string;
    last_communication?:  {
      date: string,
      text: string,
      media: string[],
      type: string,
      reference_id: string,
      direction: string,
    },
    customer: any; // TODO: type this
    resolved: boolean;
}

export interface ConversationMessage {
  data: {
    message: {
        _id: string;
        company: string;
        location: string;
        customer: string;
        conversation: string;
        user: string;
        text: string;
        type: "outgoing" | "incoming";
        media: string[];
        created_at: string;
    };
  }
}

export interface ConversationSearchResult {
  partner_ids?: string[];
  partner_id?: string;
  limit?: number;
  offset?: number;
  next_limit?: number;
  previous_limit?: number;
  resolved?: boolean | null;
  types?: string;
  starting_event_id?: string;
}

export enum ConversationStatus {
  CustomerAdded = "customer_added",
  CustomerSaved = "customer_saved",
  FeedbackOpened = "feedback_opened",
  LeftSurveyFeedback = "left_survey_feedback",
  LeftSurvey = "left_survey",
  MarketingOptIn = "marketing_opt_in",
  MarketingOptOut = "marketing_opt_out",
  Order = "order",
  InboundMessage = "inbound_message",
  OutboundMessage = "outbound_message",
  ClickedOutToReview = "clicked_out_to_review",
  SentSurvey = "sent_survey",
  AddCustomer = "add_customer",
  AutoResolved = "auto_resolved",
  Checkin = "checkin",
  CompletedCustomQuestionList = "completed_custom_question_list",
  CustomerTagged = "customer_tagged",
  CustomerUntagged = "customer_untagged",
  EarnedPoints = "earned_points",
  InternalNoteAdded = "internal_note_added",
  OrderAssociated = "order_associated",
  RedeemPromo = "redeem_promo",
  RemoveLocationSubscription = "remove_location_subscription",
  ReviewInvite = "review_invite",
  SentCampaign = "sent_campaign",
  SentCustomQuestionList = "sent_custom_question_list",
  SpentPoints = "spent_points",
  SentPromo = "sent_promo",
  SentGiftCard = "sent_gift_card",
  IncomingCall = "incoming_call"
}

export interface AnalyticEvent {
  type?: string,
  data: {
    analytic_event: ConversationEvent
  }
}
export interface ConversationCreated {
  type?: string,
  data: {
    conversation_created: Conversation
  }
}
export interface ConversationEvent {
  _id: string,
  type: ConversationStatus,
  location: string,
  customer: string,
  conversation: string,
  user: string,
  online_review_platform: null,
  created_at: string,
  message?: {
      _id: string,
      text: string
  },
  customer_order?: null,
  survey?: {
    _id: string,
    feedback: string
    rating: number
  }
  customer_saved_survey?: null
}