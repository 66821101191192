import {
  OrganizationResource,
} from 'api/models'

const formatOrganizations = (
  data: OrganizationResource[],
) => {
  const organizations = data
    .map(({ attributes }) => attributes)
    .sort((a, b) => a.name.localeCompare(b.name))

  return organizations
}

export default formatOrganizations
