import React from 'react'

import * as Styled from '../styles'

const Header = ({ title }: { title: string }) => {
  return (
    <Styled.EmptyStateWrapper>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Subtitle></Styled.Subtitle>
    </Styled.EmptyStateWrapper>
  )
}

export default Header
