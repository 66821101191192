import Pusher, { Channel } from 'pusher-js'
import { getPlatformsForPartner } from 'api'
import { ChowlyUser } from 'libs/auth0-react/auth-state'
import { MutableRefObject } from 'react'
import { PartnerChannelType } from 'types/Partner'

import { chowlyApi, pusherMessaging } from '../../config'
import { PlatformName, PlatformSummary } from '../../types'
import chowlyApiClient from 'api/chowly'

const pusherConnection = async (
  channelRef: MutableRefObject<Channel | undefined>,
  user: ChowlyUser | null | undefined,
  accessToken: string | null | undefined,
  locationID: string | null,
  setMenuPlatforms: (platforms: PlatformSummary[] | undefined) => void,
  setDoorDashOnboardingStatusWebHook: (obj: { type: string; time: number }) => void,
  setUberEatsPublishSuccessfulWebHook: (obj: { publish_successful: boolean | null }) => void,
  setOrderPlatformConnected: (connected: boolean) => void,
) => {
  if (!user?.email || !locationID) return

  // Initialize Pusher with your credentials
  const pusher = new Pusher(pusherMessaging.apiKey, {
    cluster: pusherMessaging.cluster,
    channelAuthorization: {
      headers: {
        Authorization: chowlyApiClient.defaults.headers.common['Authorization']
      },
      params: {
        partner_id: locationID
      },
      transport: "ajax",
      endpoint: chowlyApi.hostUrl + chowlyApi.pusherAuth}
  });

  // Subscribe to the private channel
  const channelName = `private-partner_notifications_channel_${locationID}`
  const channel = pusher.subscribe(channelName)
  channelRef.current = channel

  // Handle connection errors
  pusher.connection.bind('error', (err: any) => {
    console.warn('Pusher connection error:', err)
  })

  // Handle specific event types
  channel.bind('onboarding_status', (data: PartnerChannelType) => {
    const url = window.location.href
    const currentLocationId = url.substring(url.lastIndexOf('/') + 1)
    if (data.partner_id.toString() != currentLocationId) return
    const customHookMessage = { type: 'onboarding_status', time: Date.now() }
    setDoorDashOnboardingStatusWebHook(customHookMessage)
  })

  channel.bind('order_platform_connect', (data: PartnerChannelType) => {
    const url = window.location.href
    const currentLocationId = url.substring(url.lastIndexOf('/') + 1)
    if (data.partner_id.toString() != currentLocationId) return
    setOrderPlatformConnected(true)
  })

  channel.bind('publish', (data: PartnerChannelType) => {
    const url = window.location.href
    const currentLocationId = url.substring(url.lastIndexOf('/') + 1)
    if (data.partner_id.toString() != currentLocationId) return

    if (data.platform_name === 'UberEats') {
      const uberHookMessage = { publish_successful: data.publish_successful }
      setUberEatsPublishSuccessfulWebHook(uberHookMessage)
    }

    if (currentLocationId == data.partner_id.toString() && data.platform_name != PlatformName.CHOWLY) {
      getPlatformsForPartner(currentLocationId).then(platforms => {
        setMenuPlatforms(platforms)
      })
    }
  })

  // Clean up function (to be called by useEffect cleanup)
  return () => {
    if (channelRef.current) {
      pusher.unsubscribe(channelName)
      pusher.disconnect()
    }
  }
}

export default pusherConnection
