import { useMemo, useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { TitleLoader, Select } from 'components'
import infoGrayIcon from 'assets/icons/info-gray-icon.svg'
import { useGetLocations, useGetPaidAdsWidgetMetrics } from "../../../hooks"
import { Platform, TimeFrame } from 'api/reporting'
import { SearchResponse } from '@algolia/client-search'
import { useRecoilValue } from 'recoil'
import { userIdState } from 'state/atoms'
import { Business } from 'api/digitalMarketing/types'
import useSearchAlgoliaIndex from 'hooks/useAlgoliaSearch'

import * as Styled from './styles'

type BusinessOption = {
  value: string
  label: string
}

const tooltipTexts = {
  campaigns: 'The amount of active Chowly Paid Ad Campaigns within the time period',
  spend: 'The amount already spent on Paid Ads within the time period',
  totalBudget: 'The sum of the "Daily" & "Lifetime" Budget within the time period',
  dailyBudget: 'The budget sum of active Chowly Paid Ad Campaigns configured with a daily budget within the time period',
  lifetimeBudget: 'The budget sum of active Chowly Paid Ad Campaigns configured with a lifetime  budget within the time period',
}

function DigitalMarketing() {
  const [businessesOptions, setBusinessesOptions] = useState<BusinessOption[]>([])
  const [businessId, setBusinessId] = useState<string | undefined>(undefined)
  const [platform, setPlatform] = useState<Platform>('all')
  const [timeframe, setTimeframe] = useState<TimeFrame>('lifetime')
  const userId = useRecoilValue(userIdState)
  const { defaultLocation } = useGetLocations()
  const partnerId = defaultLocation?.id !== null ? String(defaultLocation?.id) : ''
  const { search, isIndexReady } = useSearchAlgoliaIndex('Businesses', partnerId as string)

  const fetchBusinesses = async () => {
    if (!isIndexReady) return []
    const searchNext = async (page: number) => {
      return await search('', {
        page,
        hitsPerPage: 100,
        attributesToRetrieve: ['id', 'name'],
        attributesToHighlight: [],
        filters: `rccIds:"${userId}"`
      })
    }
  
    let results: any[] = []
    let searchResult: SearchResponse<unknown> | undefined
    let lastPage = 0
  
    do {
      searchResult = await searchNext(lastPage)
      results = results.concat(searchResult?.hits)
      lastPage += 1
    } while (searchResult && searchResult.nbPages > lastPage)

    return results
  }

  const [{ metrics, isLoading }] = useGetPaidAdsWidgetMetrics(businessId || '', platform, timeframe)

  useEffect(() => {
    if (isEmpty(businessesOptions) && !metrics && !businessId) {
      const fetchAndSetBusinesses = async () => {
        const results = await fetchBusinesses()
        const mapBusinesses = results?.map((biz: Business) => ({
          value: biz.id,
          label: biz.name,
        }))
        const sortedBusinesses = mapBusinesses?.sort((a, b) => a.label.localeCompare(b.label))
        if (!isEmpty(sortedBusinesses)) {
          setBusinessesOptions(sortedBusinesses)
        } else {
          setBusinessesOptions([{
            value: 'no_options',
            label: 'No Options',
          }])
        }
        setBusinessId(sortedBusinesses[0]?.value || 'no_options')
      }
      fetchAndSetBusinesses()
    }
  }, [metrics, businessesOptions, businessId])
 
  const adPlatformsOptions = [
    { value: 'all', label: 'All Ad Platforms' },
    { value: 'facebook', label: 'Meta Ads' },
    { value: 'google', label: 'Google Search Ads' }
  ]

  const timeframeOptions = [
    { value: 'lifetime', label: 'Lifetime' },
    { value: 'last-30-days', label: 'Last 30 Days' },
    { value: 'last-14-days', label: 'Last 14 Days' },
    { value: 'last-7-days', label: 'Last 7 Days' },
    { value: 'this_month', label: 'This Month' },
    { value: 'last_month', label: 'Last Month' },
    { value: 'this_week_sun_today', label: 'This Week' },
    { value: 'last_week_sun_sat', label: 'Last Week' }
  ]

  const metricsCardData = useMemo(() => [
    { title: 'Campaigns', amount: metrics?.campaigns || 0, tooltip: tooltipTexts.campaigns },
    { title: 'Spend', amount: metrics?.spend || `$${0}`, tooltip: tooltipTexts.spend },
    { title: 'Total Budget', amount: metrics?.totalBudget || `$${0}`, tooltip: tooltipTexts.totalBudget },
    { title: 'Daily Budget', amount: metrics?.dailyBudget || `$${0}`, tooltip: tooltipTexts.dailyBudget },
    { title: 'Lifetime Budget', amount: metrics?.lifetimeBudget || `$${0}`, tooltip: tooltipTexts.lifetimeBudget }
  ], [metrics])

  const handleBusinessChange = (v: string) => {
    setBusinessId(v)
  }

  const handlePlatformChange = (v: string) => {
    setPlatform(v as Platform)
  }

  const handleTimeframeChange = (v: string) => {
    setTimeframe(v as TimeFrame)
  }

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Title>Paid Ads Spend Overview</Styled.Title>
        <Styled.Filters>
          <Select
              options={businessesOptions}
              placeholder={businessesOptions[0]?.label || 'Loading...'}
              styles={{
                container: Styled.selectContainer,
                control: Styled.selectControl,
                menu: Styled.selectMenu,
                menuList: Styled.selectMenuList,
                singleValue: Styled.selectSingleValue,
                placeholder: Styled.selectPlaceholder,
                option: Styled.selectOption,
              }}
              onChange={handleBusinessChange}
              value={businessesOptions.find(option => option.value === businessId)}
          />
          <Select
              options={adPlatformsOptions}
              placeholder='All Ad Platforms'
              styles={{
                container: Styled.selectContainer,
                control: Styled.selectControl,
                menu: Styled.selectMenu,
                menuList: Styled.selectMenuList,
                singleValue: Styled.selectSingleValue,
                placeholder: Styled.selectPlaceholder,
                option: Styled.selectOption,
              }}
              onChange={handlePlatformChange}
              isDisabled={businessId === 'no_options'}
          />
          <Select
              options={timeframeOptions}
              placeholder='Lifetime'
              styles={{
                container: Styled.selectContainer,
                control: Styled.selectControl,
                menu: Styled.selectMenu,
                menuList: Styled.selectMenuList,
                singleValue: Styled.selectSingleValue,
                placeholder: Styled.selectPlaceholder,
                option: Styled.selectOption,
              }}
              onChange={handleTimeframeChange}
              isDisabled={businessId === 'no_options'}
          />
        </Styled.Filters>
      </Styled.Header>
      <Styled.Grid>
        {metricsCardData.map((item, index) => (
          <Styled.Card key={index}>
            <Styled.CardHeader>
              <Styled.CardTitle>{item.title}</Styled.CardTitle>
              <Styled.InfoTooltip content={item.tooltip}>
                  <Styled.InfoIcon
                    src={infoGrayIcon}
                    alt={item.title}
                  />
              </Styled.InfoTooltip>
            </Styled.CardHeader>
            <Styled.Amount data-testid={item.title}>
              {isLoading ? <TitleLoader /> : item.amount}
            </Styled.Amount>
          </Styled.Card>
        ))}
      </Styled.Grid>
    </Styled.Container>
  )
}

export default DigitalMarketing