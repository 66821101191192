import Pusher, { Channel } from 'pusher-js'
import { chowlyApi } from 'config'
import { useAuth0 } from 'libs/auth0-react'
import capitalize from 'lodash/capitalize'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ActionStatus } from 'types'
import { PartnerChannelType } from 'types/Partner'

const TIMEOUT = 60000 // timeout milliseconds for importing
const UPDATE_CYCLE = 500 // what milliseconds will update progress per

type Props = {
  partnerId: number | undefined
  actionType: 'import' | 'publish'
  platformName?: string
}

const useActionProgressListener = ({
  partnerId,
  actionType,
  platformName,
}: Props) => {
  const { accessToken, user } = useAuth0()
  const [status, setStatus] = useState(ActionStatus.NotStarted)
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState<string>()
  const timerRef = useRef<number | null>(null)
  const channelRef = useRef<Channel>()
  const timeoutRef = useRef<number | null>(null)
  const pusherRef = useRef<Pusher>()

  const startProgressTimer = useCallback(() => {
    setProgress(0)
    timerRef.current = window.setInterval(() => {
      setProgress((old) => (old < 99 ? old + 1 : old))
    }, UPDATE_CYCLE)

    timeoutRef.current = window.setTimeout(() => {
      const errorMessage = `${capitalize(actionType)} didn't complete for 60 seconds`
      setError(errorMessage)
      setStatus(ActionStatus.Failed)
      stopProgressTimer()
    }, TIMEOUT)
  }, [actionType])

  const stopProgressTimer = useCallback(() => {
    if (timerRef.current) {
      window.clearInterval(timerRef.current)
      timerRef.current = null
    }
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [])

  const handleSyncError = useCallback(() => {
    setError(`${capitalize(actionType)} didn't complete. Reason: Connection rejected`)
    setStatus(ActionStatus.Failed)
    stopProgressTimer()
  }, [actionType, stopProgressTimer])

  const runProgressListener = useCallback(() => {
    try {
      setError('')
      setStatus(ActionStatus.NotStarted)
      if (!partnerId) {
        throw new Error('no partner_id')
      }
      if (!accessToken) {
        throw new Error('no access_token')
      }
      if (!user?.email) {
        throw new Error('no user info')
      }

      setStatus(ActionStatus.Started)
      startProgressTimer()

      // Initialize Pusher
      pusherRef.current = new Pusher(String(process.env.REACT_APP_PUSHER_KEY), {
        cluster: String(process.env.REACT_APP_PUSHER_CLUSTER),
        authEndpoint: `${chowlyApi.hostUrl}${chowlyApi.pusherAuth}`,
        auth: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      })

      // Subscribe to the private channel
      const channelName = `private-partner_notifications_channel_${partnerId}`
      channelRef.current = pusherRef.current.subscribe(channelName)

      // Handle connection errors
      pusherRef.current.connection.bind('error', () => {
        handleSyncError()
      })

      // Handle events
      channelRef.current.bind('partner_event', (data: PartnerChannelType) => {
        if (data.type !== actionType) return
        // if partner id from server does not match the partner id for what this file was initiated for
        if (data.partner_id != partnerId) return
        // when publishing we should only trigger the handler if we are publishing to the one are intending to
        // i.e. we shouldn't trigger the handler if we are publishing to UberEats and we get a message from Doordash
        if (
          data.type === 'publish' &&
          platformName &&
          data.platform_name?.toLowerCase() !== platformName.toLowerCase()
        ) {
          return
        }
        if (data.message === 'success') {
          setStatus(ActionStatus.Complete)
          stopProgressTimer()
          setProgress(100)
        } else {
          handleSyncError()
        }
      })

    } catch (error) {
      console.error(error)
      setStatus(ActionStatus.Failed)
      setError(`${capitalize(actionType)} didn't start. Reason: ${(error as Error).message}`)
    }
  }, [partnerId, actionType, accessToken, user?.email, platformName, handleSyncError, startProgressTimer, stopProgressTimer])

  useEffect(() => {
    return () => {
      if (channelRef.current && pusherRef.current) {
        const channelName = `private-partner_notifications_channel_${partnerId}`
        pusherRef.current.unsubscribe(channelName)
        pusherRef.current.disconnect()
      }
      stopProgressTimer()
    }
  }, [partnerId, stopProgressTimer])

  return {
    runProgressListener,
    error,
    status,
    progress,
    setStatus,
    setError,
    setProgress,
    stopProgressTimer,
  }
}

export default useActionProgressListener
