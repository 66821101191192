import { useEffect, useState } from 'react'
import { mobileWidthMax } from 'theme'

// Currently checks only mobile screen size, can extend to others later.
const getWindowDimensions = (): { isMobile: boolean } => {
  const { innerWidth: width } = window

  return {
    isMobile: width <= mobileWidthMax,
  }
}

/**
 * @name useIsMobile - checks if a user is on a mobile device
 */

const useIsMobile = (): { isMobile: boolean } => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export default useIsMobile
