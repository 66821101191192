import styled from 'styled-components'
import { breakpoints } from 'theme'

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '2rem',
})

export const Grid = styled.div(
  {
    display: 'grid',
    gap: '1.25rem',
    [`@media ${breakpoints.xs}, @media ${breakpoints.xxs}`]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
    [`@media ${breakpoints.sm}`]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },
    [`@media ${breakpoints.md}`]: {
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
  }
)
