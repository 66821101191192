import { Box, List, Typography, styled, Button, IconButton } from '@mui/material';
import { Heading } from 'features/customer-surveys/styles';
import { breakpoints } from 'theme';
import { Check as CheckIcon, MoreHoriz as ellipsisIcon, MenuOpen as MenuOpenIcon, ChevronLeft } from '@mui/icons-material';

export const StyledList = styled(List)`
  overflow-y: auto;
  @media ${breakpoints.xxs} {
    height: calc(97vh - 270px);
  }

  @media ${breakpoints.lg} {
    height: calc(100vh - 150px);
  }
`;

export const MessageContainer = styled(Box)<{ isMe: boolean }>(({ isMe }) => ({
  display: 'flex',
  flexDirection: isMe ? 'row-reverse' : 'row',
  gap: 20,
  width: '100%',
  padding: '20px 20px 0px 20px',
}));

export const InitialsCircle = styled(Box)<{ noborder: boolean }>(({ noborder }) => ({
  width: 24,
  height: 24,
  minWidth: 24,
  minHeight: 24,
  borderRadius: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: noborder ? 'transparent' : '#B1C2D9',
  color: '#F9FBFD',
  fontSize: noborder ? '22px' : '10px',
  fontWeight: 500,
  marginTop: 5,
}));

export const MessageWrapper = styled(Box)<{ isMe: boolean }>(({ isMe }) => ({
  backgroundColor: isMe ? '#2D7CE5' : '#EDF2F9',
  borderRadius: '6px',
  padding: '12px',
  maxWidth: '90%',
  position: 'relative',
}));

export const MessageText = styled(Typography)<{ isMe: boolean }>(({ isMe }) => ({
  color: isMe ? '#FFFFFF' : '#12263F',
  fontSize: '13px !important',
  fontWeight: '400 !important',
  padding: '0 0 6px 0',
  whiteSpace: 'pre-wrap',
}));

export const TimestampBox = styled('div')<{ isMe: boolean }>`
  display: flex;
  align-items: center;
  font-size: 10px;
  justify-content: flex-start;
  gap: 4px;
  margin-top: auto;
  font-weight: 500;
  color: ${({ isMe }) => isMe ? '#FFFFFF' : '#95AAC9'};
`;

export const MessageBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const MessageArrow = styled('div')<{ isMe: boolean }>`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  ${({ isMe }) => isMe ? `
    right: -8px;
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent #2D7CE5;
  ` : `
    left: -8px;
    border-width: 8px 8px 8px 0;
    border-color: transparent #EDF2F9 transparent transparent;
  `}
  top: 10px;
`;

export const ChatContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 80px;
  position: relative;
`;

export const InputContainer = styled(Box)`
  position: sticky;
  margin-top: 18px;
  z-index: 9;
  background-color: transparent;
  width: 100%;
`;

export const TextAreaWrapper = styled(Box)`
  position: relative;
  display: flex;
  height: 102px;
  margin: 0 20px;
  background-color: #FFF;
  border-radius: 8px;
  border: 1px solid #EDF2F9;
  box-shadow: 0px 4px 12px 0px rgba(18, 38, 63, 0.06);
  flex-shrink: 0;
`;

export const StyledTextArea = styled('textarea')`
  flex: 1;
  padding: 18px;
  padding-right: 76px;
  border: none;
  resize: none;
  height: 100%;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.21px;
  color: #12263F;
  background-color: transparent;

  &::placeholder {
    color: #95AAC9;
  }

  &:focus {
    outline: none;
  }
`;

export const SendButton = styled(Button)`
  position: absolute;
  right: 8px;
  bottom: 8px;
  min-width: 60px;
  height: 32px;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.225px;
  text-align: right;
  text-transform: capitalize;
  cursor: pointer;
  background-color: transparent;
  color: ${({ disabled }) => disabled ? '#95AAC9' : '#12263F'};

  &:hover {
    background-color: transparent;
  }

  &:disabled {
    cursor: default;
    color: #95AAC9;
    opacity: 0.5;
    background-color: transparent;
  }
`;

export const StyledHeading = styled(Heading)({
  paddingLeft: '16px'
});

export const chatContainerStyles = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
} as const;

export const inputContainerStyles = {
  bottom: 0,
  backgroundColor: 'transparent',
  zIndex: 1000,
  width: '99%',
  marginBottom: '16px',
} as const;

export const LoadingBox = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const FloatingDateHeader = styled(Typography)<{ visible: boolean }>(({ visible }) => ({
  display: 'block',
  textAlign: 'center',
  padding: '2px 8px',
  color: '#12263F',
  // fontFamily: '"Cerebri Sans"',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: '#FFFFFF',
  borderRadius: '16px',
  opacity: visible ? 1 : 0,
  transition: 'opacity 0.2s ease-in-out',
  pointerEvents: 'none',
  zIndex: 2,
  marginTop: '10px'
}));

export const DateHeader = styled(Typography)<{ visible?: boolean }>(({ visible = true }) => ({
  textAlign: 'center',
  padding: '4px 16px',
  color: '#12263F',
  // fontFamily: '"Cerebri Sans"',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  width: 'fit-content',
  margin: '0px auto 4px auto',
  visibility: visible ? 'visible' : 'hidden'
}));

export const HeaderContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 16px',
  borderBottom: '1px solid #D2DDEC'
})

export const HeaderTitle = styled(Box)({
  color: '#12263F',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '30px',
})

export const IconButtonStyled = styled(IconButton)({
  '& img': {
    maxWidth: '20px',
    maxHeight: '20px'
  }
})

const iconStyles = {
  width: 20,
  height: 20,
  color: '#95AAC9'
};

const ellipsisIconStyles = {
  width: 20,
  height: 20,
  color: '#12263F'
};

export const StyledCheckIcon = styled(CheckIcon)(iconStyles);
export const StyledEllipsisIcon = styled(ellipsisIcon)(ellipsisIconStyles);
export const StyledMenuOpenRightIcon = styled(MenuOpenIcon)(iconStyles);

export const StyledChevronLeftIcon = styled(ChevronLeft)`
  color: #12263F;
  width: 24px;
  height: 24px;
  margin-left: -25px;
`;

export const CenteredBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;
export const MobileBackBox = styled(Box)`
  display: none;
  margin-right: 8px;
  align-items: center;
  flex-direction: row;
  background-color: #F9FBFD;

  @media ${breakpoints.xxs} {
    display: flex;
  }

  @media ${breakpoints.lg} {
    display: none;
  }
`
