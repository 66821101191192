import { ReactComponent as ChowlyLogo } from 'assets/logo.svg'
import { FC } from 'react'

import * as Styled from './styles'

type Props = {
  title?: string
  description?: string
  variant?: 'success' | 'error'
}

const LoadingScreen: FC<Props> = ({ title = 'Loading', description, variant }) => {
  return (
    <Styled.Container>
      <Styled.Box>
        <ChowlyLogo />

        <Styled.Title variant={variant}>{title}</Styled.Title>
        <Styled.Description>{description}</Styled.Description>
      </Styled.Box>
    </Styled.Container>
  )
}

export default LoadingScreen
