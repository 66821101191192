import { Grid, Typography, Box } from '@mui/material'
import styled from 'styled-components'
import { breakpoints } from 'theme'

interface GridSectionProps {
  $showOnMobile: boolean;
}

export const LeftGridSection = styled(Grid)<GridSectionProps>`
  height: 100vh;
  ${({ $showOnMobile }) => `
    @media ${breakpoints.xxs}, ${breakpoints.xs}, ${breakpoints.sm} {
      display: ${$showOnMobile ? 'block' : 'none'};
      width: 100%;
      height: calc(100vh - 120px);
    }
    @media ${breakpoints.md}, ${breakpoints.lg}, ${breakpoints.xl} {
      width: auto;
      display: block;
    }
  `}
`

export const RightGridSection = styled(Grid)<GridSectionProps>`
  ${({ $showOnMobile }) => `
    @media ${breakpoints.xxs}, ${breakpoints.xs}, ${breakpoints.sm} {
      display: ${$showOnMobile ? 'block' : 'none'};
      width: 100%;
    }
    @media ${breakpoints.md}, ${breakpoints.lg}, ${breakpoints.xl} {
      width: auto;
      display: block;
    }
  `}
`

export const MiddleGridSection = styled(Box)<{ $showOnMobile: boolean; $expanded?: boolean }>`
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  height: 100vh;
  padding-bottom: 2px;
  width: ${({ $expanded, $showOnMobile }) => $expanded && !$showOnMobile ? '139%' : '100%'};
  transition: width 0.3s ease;
  ${({ $showOnMobile }) => `
    @media ${breakpoints.xxs}, ${breakpoints.xs}, ${breakpoints.sm} {
      display: ${$showOnMobile ? 'block' : 'none'};
      flex: 1;
      border-left: none;
      border-right: none;
      padding-bottom: 2px;
      > * {
        width: 100%;
        max-width: 100%;
      }
    }
    @media ${breakpoints.md}, ${breakpoints.lg}, ${breakpoints.xl} {
      display: block;
      border-left: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
    }
  `}
`

export const Heading = styled(Typography)`
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  color: #12263f !important;
  border-bottom: 1px solid #d2ddec !important;
  padding-bottom: 8px !important;
  padding: 8px 0;
`

export const GridContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  width: 100%;
  margin-bottom: 0px;
  padding-bottom: 0px;
  overflow-y: hidden;  
  @media ${breakpoints.xxs}, ${breakpoints.xs}, ${breakpoints.sm} {
    grid-template-columns: 1fr;
    height: calc(100vh - 120px);
    width: 100%;
  }
  @media ${breakpoints.md}, ${breakpoints.lg}, ${breakpoints.xl} {
    grid-template-columns: 1fr 2fr 1fr;
    height: 100vh;
  }
`
export const LocationDropdownWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  grid-column: 1 / 4;
  margin-right: auto;
`

export const ChilipiperSectionWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 4;
  margin-top: 20px;
`
export const CustomerSurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1280px;
  width: 100%;
  padding-bottom: 5rem;
  background-color: #FAFAFA;
  gap: 24px;
`