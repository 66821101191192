export enum PromoCodeStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  DISABLED = 'disabled',
}

export enum AppliesTo {
  ENTIRE_SALE = 'Entire sale',
  // SPECIFIC_ITEMS = 'Specific items',
  // CATEGORIES = 'Categories',
}

export enum Conditions {
  USAGE_PER_CUSTOMER = 'Usage per customer',
  REDEMPTION_COUNT = 'Redemption Count',
  MINIMUM_PURCHASE_AMOUNT = 'Minimum purchase amount',
}
