import { useOpenModal } from 'hooks'
import { useAuth0 } from 'libs/auth0-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import * as Styled from './styles'

type Props = {
  showDropdown?: boolean
  isCollapsed?: boolean
}

const UserMenu = ({ showDropdown, isCollapsed }: Props): JSX.Element => {
  const { user, logout } = useAuth0()
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const toggleDropdown = () => setIsOpen(!isOpen)
  const openModal = useOpenModal()
  const openManageAccount = () => openModal('ManageAccount')

  let userInitials = ''
  if (user && user.given_name && user.family_name) {
    userInitials = user.given_name.charAt(0) + user.family_name.charAt(0)
  }

  return (
    <Styled.Wrapper>
      {isCollapsed ? (
        <Styled.MobileDropdownWrapCollapsed>
          <Styled.Initials>{userInitials}</Styled.Initials>
        </Styled.MobileDropdownWrapCollapsed>
      ) : (
        <Styled.MobileContainer showDropdown={showDropdown} data-testid='user-menu-mobile'>
          <Styled.MobileDropdownWrap>
            <Styled.RoundButton />
            <Styled.Initials>{userInitials}</Styled.Initials>
          </Styled.MobileDropdownWrap>
          <Styled.Name>{user?.name}</Styled.Name>
        </Styled.MobileContainer>
      )}
      <Styled.Container data-testid='user-menu-dropdown' showDropdown={showDropdown} onClick={toggleDropdown}>
        <Styled.DropdownWrap>
          <Styled.RoundButton />
          <Styled.Initials>{userInitials}</Styled.Initials>

          {isOpen && (
            <Styled.DropdownList>
              {/* <Styled.Email>{user?.email}</Styled.Email> */}
              <Styled.TextButton onClick={openManageAccount}>Manage Account</Styled.TextButton>
              <Styled.TextButton onClick={() => navigate('/billing')}>Billing</Styled.TextButton>
              <Styled.TextLink
                onClick={() => {
                  logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })
                }}
              >
                Log Out
              </Styled.TextLink>
            </Styled.DropdownList>
          )}
        </Styled.DropdownWrap>
        <Styled.DownArrow aria-label='down arrow' $isExpanded={isOpen} />
      </Styled.Container>
    </Styled.Wrapper>
  )
}

export default UserMenu
