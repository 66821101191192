import Tippy from '@tippyjs/react'
import { ConnectionResource, MenuMappingSectionResource } from 'api'
import add_menu_icon from 'assets/icons/add-connection-icon.svg'
import ellipse_2_green from 'assets/icons/ellipse-2-green.svg'
import ellipse_grey from 'assets/icons/ellipse-2-grey.svg'
import ellipse_2_red from 'assets/icons/ellipse-2-red.svg'
import outline_preview_icon from 'assets/icons/outline-preview-icon.svg'
import re_sync_connection_icon from 'assets/icons/re-sync-connection-icon.svg'
import DeliveryBuydownButton from 'components/ButtonWithConfirmationDialog/DeliveryBuyDownButton'
import LinkButton from 'components/LinkButton/LinkButton'
import { ResetButton as ReSyncButton } from 'components/ModalButtons'
import { TableColumn } from 'components/Table/Table'
import { Link } from 'react-router-dom'
import { PlatformName, PlatformSummary } from 'types'
import { formatDate, getMenuManagementPlatformURL, getMenuManagementPreviewURL } from 'utils'

import * as Styled from './styles'

export const getMobilePlatformColumns = (
  onPause: (row?: PlatformSummary) => void,
  refetch: () => Promise<void>,
  goToCOO: (url: string) => void,
  locationId: string,
  isAdminUser?: boolean,
): Array<TableColumn<PlatformSummary>> => [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Platform',
    render: (row) => (
      <Styled.MobilePlatformContainer>
        {isAdminUser && (
          <Tippy content='Menus Might Not Be Updated In Real Time'>
            <Link to={getMenuManagementPlatformURL(locationId, row.id.toString())}>
              {row.name === 'Ubereats' ? 'Uber Eats' : row.name}
            </Link>
          </Tippy>
        )}
        {!isAdminUser && (
          <Link to={getMenuManagementPlatformURL(locationId, row.id.toString())}>
            {row.name === 'Ubereats' ? 'Uber Eats' : row.name}
          </Link>
        )}
        <ReSyncButton
          buttonText={
            row.aasmState !== 'enabled' || row.availability === 'Not Paused' ? 'Pause' : 'Resume'
          }
          size='small'
          navigation={() => onPause(row)}
          page={'location'}
          disabled={row.aasmState !== 'enabled'}
        />
      </Styled.MobilePlatformContainer>
    ),
  },
  {
    dataIndex: 'availabilty',
    key: 'availabilty',
    title: 'Availability',
    render: (row: PlatformSummary) => (
      <Styled.MobileRowContainer>
        {row.availability === 'Paused' ? (
          <Styled.MobileRow>
            <Styled.HeaderCell>Availibility</Styled.HeaderCell>
            <Styled.Icon src={ellipse_2_red} alt='ellipseIcon' />
            <Styled.RedStatus>Unavailable</Styled.RedStatus>
          </Styled.MobileRow>
        ) : row.availability === 'Not Paused' ? (
          <Styled.MobileRow>
            <Styled.HeaderCell>Availibility</Styled.HeaderCell>
            <Styled.Icon src={ellipse_2_green} alt='ellipseIcon' />
            <Styled.SuccessStatus>Available</Styled.SuccessStatus>
          </Styled.MobileRow>
        ) : (
          <Styled.MobileRow>
            <Styled.HeaderCell>Availibility</Styled.HeaderCell>
            <Styled.Icon src={ellipse_2_red} alt='ellipseIcon' />
            <Styled.RedStatus>{row.availability}</Styled.RedStatus>
          </Styled.MobileRow>
        )}
        {row.menuPublishStatus?.attributes.success ? (
          <Styled.MobileRow>
            <Styled.HeaderCell>Menu Publish Status</Styled.HeaderCell>
            <Styled.Icon src={ellipse_2_green} alt='ellipseIcon' />
            <Styled.SuccessStatus>Published</Styled.SuccessStatus>
          </Styled.MobileRow>
        ) : row.menuPublishStatus?.attributes.success === false ? (
          <Styled.MobileRow>
            <Styled.HeaderCell>Menu Publish Status</Styled.HeaderCell>
            <Styled.Icon src={ellipse_2_red} alt='ellipseIcon' />
            <Styled.RedStatus>Unpublished</Styled.RedStatus>
          </Styled.MobileRow>
        ) : (
          <Styled.MobileRow>
            <Styled.HeaderCell>Menu Publish Status</Styled.HeaderCell>
            <Styled.Icon src={ellipse_2_red} alt='ellipseIcon' />
            <Styled.RedStatus>Unpublished</Styled.RedStatus>
          </Styled.MobileRow>
        )}
        {row.status === 'Not Enabled' ? (
          <Styled.MobileRow>
            <Styled.HeaderCell>Status</Styled.HeaderCell>
            <Styled.Icon src={ellipse_2_red} alt='ellipseIcon' />
            <Styled.RedStatus>Enabled</Styled.RedStatus>
          </Styled.MobileRow>
        ) : row.status === 'Enabled' ? (
          <Styled.MobileRow>
            <Styled.HeaderCell>Status</Styled.HeaderCell>
            <Styled.Icon src={ellipse_2_green} alt='ellipseIcon' />
            <Styled.SuccessStatus>{row.status}</Styled.SuccessStatus>
          </Styled.MobileRow>
        ) : (
          <Styled.MobileRow>
            <Styled.HeaderCell>Status</Styled.HeaderCell>
            <Styled.Icon src={ellipse_2_red} alt='ellipseIcon' />
            <Styled.RedStatus>{row.status}</Styled.RedStatus>
          </Styled.MobileRow>
        )}
      </Styled.MobileRowContainer>
    ),
  },
  {
    dataIndex: 'deliveryBuydown',
    key: 'deliveryBuydown',
    title: 'Delivery Buydown',
    render: (row: PlatformSummary) => (
      <Styled.MobileRowContainer>
        {row.name === PlatformName.COO ? (
          <>
            <LinkButton
              title='Go To Chowly Online Ordering'
              description={`Go to Chowly Online Ordering Website`}
              onClick={() => goToCOO(row?.coo_url as string)}
            />
            <DeliveryBuydownButton
              title='Delivery Fee Setting'
              modalHeader='Chowly Online Ordering Delivery Fee Settings'
              description={`Enable Delivery Fee Setting?`}
              okText='Save'
              isDanger={false}
              row={row}
              onOk={refetch}
            />
          </>
        ) : null}
      </Styled.MobileRowContainer>
    ),
  },
]

export const getMobileMenuColumns = (
  locationId: string,
): Array<TableColumn<MenuMappingSectionResource>> => [
  {
    dataIndex: 'preview',
    key: 'preview',
    title: '',
    render: (row) => (
      <Styled.MobilePlatformContainer>
        {row.attributes.title === 'Ubereats' ? 'Uber Eats' : row.attributes.title}
        <Link to={getMenuManagementPreviewURL(locationId, row.id)}>
          <Styled.IconContainer>
            <Styled.Icon src={outline_preview_icon} alt='preview menu icon' iconSize={'24'} />
          </Styled.IconContainer>
        </Link>
      </Styled.MobilePlatformContainer>
    ),
  },
]

export const getPlatformColumns = (
  onPause: (row?: PlatformSummary) => void,
  refetch: () => Promise<void>,
  goToCOO: (url: string) => void,
  locationId: string,
  isAdminUser?: boolean,
): Array<TableColumn<PlatformSummary>> => [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Platform',
    render: (row) => (
      <Styled.PlatformContainer>
        {isAdminUser && (
          <Tippy content='Menus Might Not Be Updated In Real Time'>
            <Link to={getMenuManagementPlatformURL(locationId, row.id.toString())}>
              {row.name === 'Ubereats' ? 'Uber Eats' : row.name}
            </Link>
          </Tippy>
        )}
        {!isAdminUser && (
          <Link to={getMenuManagementPlatformURL(locationId, row.id.toString())}>
            {row.name === 'Ubereats' ? 'Uber Eats' : row.name}
          </Link>
        )}
        {row.name === PlatformName.COO && (
          <>
            <div style={{ marginLeft: '10px' }}>
              <LinkButton
                title=''
                description={`Go to Chowly Online Ordering Website`}
                onClick={() => goToCOO(row?.coo_url as string)}
              />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <DeliveryBuydownButton
                title=''
                modalHeader='Chowly Online Ordering Delivery Fee Settings'
                description={`Enable Delivery Fee Setting?`}
                okText='Save'
                isDanger={false}
                row={row}
                onOk={refetch}
              />
            </div>
          </>
        )}
      </Styled.PlatformContainer>
    ),
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    render: (row: PlatformSummary) => (
      <div>
        <Styled.AvailabilityContainer>
          {row.status === 'Not Enabled' ? (
            <Styled.StatusContainer>
              <Styled.Icon src={ellipse_2_red} alt='ellipseIcon' />
              <Styled.RedStatus>Enabled</Styled.RedStatus>
            </Styled.StatusContainer>
          ) : row.status === 'Enabled' ? (
            <Styled.StatusContainer>
              <Styled.Icon src={ellipse_2_green} alt='ellipseIcon' />
              <Styled.SuccessStatus>{row.status}</Styled.SuccessStatus>
            </Styled.StatusContainer>
          ) : (
            <Styled.StatusContainer>
              <Styled.Icon src={ellipse_2_red} alt='ellipseIcon' />
              <Styled.RedStatus>{row.status}</Styled.RedStatus>
            </Styled.StatusContainer>
          )}
        </Styled.AvailabilityContainer>
      </div>
    ),
  },
  {
    dataIndex: 'menuPublishStatus',
    key: 'menuPublishStatus',
    title: 'Menu Publish Status',
    render: (row: PlatformSummary) => (
      <div>
        <Styled.AvailabilityContainer isColumn={true}>
          {row.menuPublishStatus?.attributes.success ? (
            <Styled.StatusContainer>
              <Styled.Icon src={ellipse_2_green} alt='ellipseIcon' />
              <Styled.SuccessStatus>Published</Styled.SuccessStatus>
            </Styled.StatusContainer>
          ) : row.menuPublishStatus?.attributes.success === false ? (
            <Styled.StatusContainer>
              <Styled.Icon src={ellipse_2_red} alt='ellipseIcon' />
              <Styled.RedStatus>Unpublished</Styled.RedStatus>
            </Styled.StatusContainer>
          ) : (
            <Styled.StatusContainer>
              <Styled.Icon src={ellipse_2_red} alt='ellipseIcon' />
              <Styled.RedStatus>Unavailable</Styled.RedStatus>
            </Styled.StatusContainer>
          )}
          {row.menuPublishStatus?.attributes.timestamp && (
            <Styled.MenuPublishTimeStamp>
              {formatDate(row.menuPublishStatus?.attributes.timestamp, 'MM/DD/YY, h:mm A')}
            </Styled.MenuPublishTimeStamp>
          )}
        </Styled.AvailabilityContainer>
      </div>
    ),
  },
  {
    dataIndex: 'availabilty',
    key: 'availabilty',
    title: 'Availability',
    render: (row: PlatformSummary) => (
      <div>
        <Styled.AvailabilityContainer isColumn={true}>
          {row.availability === 'Paused' ? (
            <Styled.StatusContainer>
              <Styled.Icon src={ellipse_2_red} alt='ellipseIcon' />
              <Styled.RedStatus>Unavailable</Styled.RedStatus>
            </Styled.StatusContainer>
          ) : row.availability === 'Not Paused' ? (
            <Styled.StatusContainer>
              <Styled.Icon src={ellipse_2_green} alt='ellipseIcon' />
              <Styled.SuccessStatus>Available</Styled.SuccessStatus>
            </Styled.StatusContainer>
          ) : (
            <Styled.StatusContainer>
              <Styled.Icon src={ellipse_2_red} alt='ellipseIcon' />
              <Styled.RedStatus>{row.availability}</Styled.RedStatus>
            </Styled.StatusContainer>
          )}
          {row.availability !== 'Not Paused' && row.reopenTime && (
            <Styled.MenuPublishTimeStamp>
              <span>Store reopens&nbsp;</span>
              {formatDate(row.reopenTime, 'MM/DD/YY, h:mm A')}
            </Styled.MenuPublishTimeStamp>
          )}
        </Styled.AvailabilityContainer>
      </div>
    ),
  },
  {
    dataIndex: 'pauseAll',
    key: 'pauseAll',
    title: '',
    headerAlign: 'center',
    render: (row) => (
      <ReSyncButton
        buttonText={
          row.aasmState !== 'enabled' || row.availability === 'Not Paused' ? 'Pause' : 'Resume'
        }
        size='small'
        navigation={() => onPause(row)}
        page={'location'}
        disabled={row.aasmState !== 'enabled'}
      />
    ),
  },
]

export const getMenuColumns = (
  locationId: string,
): Array<TableColumn<MenuMappingSectionResource>> => [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Menu Name',
    render: (row) => (
      <Styled.MenuContainer>
        {row.attributes.title === 'Ubereats' ? 'Uber Eats' : row.attributes.title}
      </Styled.MenuContainer>
    ),
  },
  {
    dataIndex: 'preview',
    key: 'preview',
    title: '',
    render: (row) => (
      <Link to={getMenuManagementPreviewURL(locationId, row.id)}>
        <Styled.Icon src={outline_preview_icon} alt='preview menu icon' iconSize={'24'} />
      </Link>
    ),
  },
]

type ConnectionStatus = 'NOT_INTEGRATED' | 'SUCCESS'

export const getConnectionColumns = (
  connectToGoogleBusinessProfile: () => void,
  openGoogleBusinessProfile: (marketingChannelId: string) => void,
  marketingChannelId: string,
  status?: ConnectionStatus,
  disableConnectBtn?: boolean,
): Array<TableColumn<ConnectionResource>> => [
  {
    dataIndex: 'platform',
    key: 'platform',
    title: 'Platform',
    render: (row) => (
      <>
        {status === 'SUCCESS' ? (
          <Styled.ConnectionLink onClick={() => openGoogleBusinessProfile(marketingChannelId)}>
            {row.attributes.title}
          </Styled.ConnectionLink>
        ) : (
          <Styled.MenuContainer>{row.attributes.title}</Styled.MenuContainer>
        )}
      </>
    ),
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    render: () => (
      <>
        <Styled.StatusContainer>
          <Styled.Icon src={status === 'SUCCESS' ? ellipse_2_green : ellipse_grey} />
          <Styled.ConnectionStatusText isSuccess={status === 'SUCCESS'}>
            {status === 'SUCCESS' ? 'Enabled' : 'Not Integrated'}
          </Styled.ConnectionStatusText>
        </Styled.StatusContainer>
      </>
    ),
  },
  {
    dataIndex: 'connect',
    key: 'connect',
    title: '',
    headerAlign: 'center',
    render: () => (
      <Styled.Icon 
        hoverEffect={true}
        src={status === 'SUCCESS' ? re_sync_connection_icon : add_menu_icon} 
        alt='connect menu icon' 
        iconSize={'24'} 
        onClick={connectToGoogleBusinessProfile}
        disabled={disableConnectBtn}
      />
    ),
  },
]
