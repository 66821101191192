import { useMediaQuery, useTheme } from '@mui/material'

/**
 * @name useIsDesktop - checks if a user is on a desktop device using Material UI breakpoints
 */
const useIsDesktop = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  return { isDesktop }
}

export default useIsDesktop
