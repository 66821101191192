
import styled from '@emotion/styled';
import { Box, Card, IconButton, Typography } from '@mui/material';

export const CarouselContainer = styled(Box)`
  max-width: 1280px;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  position: relative;
  background-color: #fefefe;
`;

export const CarouselTitle = styled(Typography)`
  font-size: 20px;
  font-weight: 600;
  color: #292929;
  margin-bottom: 20px;
`;

export const SlideContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const StyledCard = styled(Card)`
  flex: 1;
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
`;

export const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const MetricTitle = styled(Typography)`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 8px;
`;

export const MetricHelperText = styled(Typography)`
  color: #6d6d6d;
  font-size: 0.875rem;
  margin-bottom: 8px;
`;

export const MetricValue = styled(Typography)`
  color: #1b51a4;
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 16px;
`;

export const NavigationContainer = styled(Box)<{ isMobile: boolean }>`
  position: ${(props) => (props.isMobile ? 'static' : 'absolute')};
  top: ${(props) => (props.isMobile ? 'auto' : '24px')};
  right: ${(props) => (props.isMobile ? 'auto' : '24px')};
  bottom: ${(props) => (props.isMobile ? '0' : 'auto')};
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 1;
  justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-start')};
  margin-top: ${(props) => (props.isMobile ? '16px' : '0')};
`;

export const StyledIconButton = styled(IconButton)`
  background-color: rgba(27, 81, 164, 0.1);
  padding: 4px;
  width: 32px;
  height: 32px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const Indicator = styled(Box)<{ active: boolean }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => (props.active ? '#1b51a4' : '#e0e0e0')};
  border-radius: 50%;
  cursor: pointer;
`;

export const ReportContainer = styled.div({
  width: '100%',
  height: '350px',
  overflow: 'hidden',
  padding: '0px',
})

export const ReportFrame = styled.iframe({
  width: '100%',
  height: '100%',
})