import { getAllPartnerLocations, getPartner } from 'api/partners'
import { useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { activePartnerIdState } from 'state/atoms'

// variables used to prevent duplicate state updates and API calls when multiple components
let lastSetPartnerId: string | null = null
let isFetching = false

export const checkValidLocationId = (locationId: string | undefined) => {
  if (!locationId || locationId === 'null' || locationId === 'undefined') {
    return false
  }
  return true
}

const useRouteLocationId = () => {
  const { locationId } = useParams()
  const navigate = useNavigate()
  const [activePartnerId, setActivePartnerId] = useRecoilState(activePartnerIdState)

  // updates the activePartnerId state only if the new ID is different from the last set ID.
  const updateActivePartnerId = useCallback((id: string) => {
    if (lastSetPartnerId === id) {
      return
    }

    lastSetPartnerId = id
    setActivePartnerId(id)
  }, [setActivePartnerId])

  const fetchDefaultLocation = useCallback(async () => {
    if (isFetching) {
      return
    }

    isFetching = true
      
    try {
      if (locationId && checkValidLocationId(locationId)) {
        const locationData = await getPartner(locationId)
        if (locationData?.data) {
          updateActivePartnerId(locationId)
          return
        }
      }
      
      const { data } = await getAllPartnerLocations()

      if (data?.length) {
        data.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10))
        const firstLocId = data[0]?.id
        if (firstLocId) {
          updateActivePartnerId(firstLocId)
          navigate(`/${firstLocId}/home`)
        }  
      }
    } catch (err) {
      console.error('Invalid Location Id', err)
      // We can't fetch default locations. Show Not Found page
      navigate('/')
    } finally {
      isFetching = false
    }
  }, [navigate, updateActivePartnerId, locationId])

  const fetchLocation = useCallback(async (locationId: string) => {
    if (isFetching) {
      return
    }

    if (lastSetPartnerId === locationId) {
      return
    }

    isFetching = true

    try {
      const location = await getPartner(locationId)

      if (location?.data) {
        updateActivePartnerId(locationId)
      }
    } catch (err) {
      // this id wasn't valid or we don't have the right permissions. Fetch the default location and set that instead.
      console.error('Invalid Location Id', err)
      fetchDefaultLocation()
    } finally {
      isFetching = false
    }
  }, [updateActivePartnerId, fetchDefaultLocation])

  useEffect(() => {
    const isValidLocationId = checkValidLocationId(locationId)

    if (isValidLocationId && locationId && !activePartnerId) {
      // set initial partner ID from URL if none is active yet
      updateActivePartnerId(locationId)
    } else if (!isValidLocationId && !activePartnerId) {
      // fetch the first alphanumeric location and set that to recoil state
      fetchDefaultLocation()
      // this happens if we redirect to a page with no set location, or if we just login to the application
    } else if (!isValidLocationId && activePartnerId) {
      // this happens if we are missing the locationId from the url for some reason. Reset params to whatever the global state object has.
      // set params to be the activePartnerId
      const currentPath = window.location.pathname
      if (!currentPath.includes(activePartnerId)) {
        navigate(`/${activePartnerId}/home`)
      }
    } else if (locationId && isValidLocationId && activePartnerId !== locationId) {
      // we are changing locationId, make sure this is a location that we have access to and set to global state
      // then we're trying to change locations
      fetchLocation(locationId)
    }

  }, [
    locationId, 
    activePartnerId, 
    location.pathname, 
    fetchDefaultLocation, 
    fetchLocation, 
    updateActivePartnerId, 
    navigate
  ])

  return activePartnerId
}

export default useRouteLocationId
