import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import { emailMarketingApi } from 'config'
import { getEmailMarketingCampaigns } from 'api/digitalMarketing'
import { Toast } from 'components'
import DigitalMarketingDetailsDrawer from 'components/DigitalMarketingDetailsDrawer/DigitalMarketingDetailsDrawer'
import { useAlgoliaMultiIndexSearch } from 'hooks'
import useSafeAsync from 'hooks/useSafeAsync'
import React, { useCallback, useEffect, useState } from 'react'
import { OptimizedCampaign, TargetableInsight } from 'types/digitalMarketing'
import { EmailMarketingCampaign } from 'api'
import { ToDoItem, TodoItemWithProperties } from 'types/digitalMarketing/common'
import { GenericAd } from 'types/digitalMarketing/googleAd'
import { getPrefixedIndexName } from 'utils/algolia'
import { shouldDisplayCampaign, shouldDisplayGoogleAd, withTodoAsMetaAd, isEmailMarketingAd } from 'utils/dmUtils'
import { uniqBy } from 'lodash'

import { CampaignItem, NoData, Skeleton, getToDoContentType } from './components'
import FeedbackModal from './FeedbackModal/FeedbackModal'
import { TableHeaderCell, ToDoTable, TodoContainer } from './styles'
import { Location } from 'types'

// NOTE: Consider moving this to the App root level.
const theme = createTheme({
  palette: {
    primary: {
      main: '#1B51A4',
    },
  },
  typography: {
    fontFamily: 'Inter',
  },
})

const insightsIndex = getPrefixedIndexName('Insights')
const suggestedCampaignsIndex = getPrefixedIndexName('SuggestedCampaigns')
const googleAdsIndex = getPrefixedIndexName('AdCampaigns')

type FeedbackModal = {
  todoItem: TodoItemWithProperties | null
  mode: 'approve' | 'reject'
}

interface ToDoProps {
  partners: Location[];
}

const getAdDate = (item: ToDoItem) => {
  if (typeof (item as GenericAd).createdAt === 'string') {
    return new Date((item as GenericAd).createdAt).getTime()
  }

  return (item as TargetableInsight).created
}

const ToDo = ({ partners }: ToDoProps) => {
  const [isSearching, setIsSearching] = useState(false)
  const [toDos, setToDos] = useState<TodoItemWithProperties[]>([])
  const partnerIds = partners.map(p => p.id)
  const multiSearch = useAlgoliaMultiIndexSearch(partnerIds)
 
  /**
   * Since Algolia syncing is async, we need to keep track of already actioned items
   * to hide them from the list immediately.
   */
  const [alreadyProcessed, setAlreadyProcessed] = useState<string[]>([])

  const [feedbackModal, setFeedbackModal] = useState<FeedbackModal>({
    todoItem: null,
    mode: 'approve',
  })

  const [selectedItem, setSelectedItem] = useState<TodoItemWithProperties | null>(null)

  const handleDrawerClose = () => {
    setSelectedItem(null)
  }

  const safeAsync = useSafeAsync()

  const handleCloseModal = useCallback(() => {
    setFeedbackModal({ todoItem: null, mode: 'approve' })
  }, [])

  const handleAction = useCallback((item: TodoItemWithProperties, approved: boolean) => {
    setFeedbackModal({ todoItem: item, mode: approved ? 'approve' : 'reject' })
  }, [])

  const handleModalSubmit = useCallback(
    (item: TodoItemWithProperties, approved: boolean) => {
      const message = approved
        ? `${getToDoContentType(item)} has been approved and will publish soon.`
        : `${getToDoContentType(item)} has been rejected.`

      handleCloseModal()

      Toast.success({ message }, { autoClose: 5000, hideProgressBar: true })
      setAlreadyProcessed((prev) => [...prev, item.id])
    },
    [handleCloseModal],
  )

  const handleEmailMarketingShowItem = useCallback((item: EmailMarketingCampaign) => {
    const baseUrl = emailMarketingApi.hostUrl;
    let url = `${baseUrl}/campaigns/${item.id}`;
   
    const editStatuses = ["draft", "pending_approval"];
  
    if (editStatuses.includes(item.status)) {
      url += `/edit`;
    }

    window.open(url, '_blank')
  }, []);

  const handleOnShowItem = useCallback((item: TodoItemWithProperties) => {
    if (isEmailMarketingAd(item)) {
      handleEmailMarketingShowItem(item as EmailMarketingCampaign) 
    } else {
      setSelectedItem(item)
    }
  }, [handleEmailMarketingShowItem])

  const fetchCampaigns = useCallback(async () => {
    if (partners.length === 0) return

    const inSmartFeed = `status:smartFeed`
    const isPublishedFilter = `status:published`
    const notDeletedFilter = `NOT deletedOn:*`
    const noGoogleGroupAdsFilter = `NOT group:*`

    const commonParams = {
      filters: `${notDeletedFilter}`,
      facetFilters: [
        partners.map(partner => `chowlyPartnerId:${partner.id}`)
      ],
    }

    const filterBy = (filters: string[]) => filters.join(' AND ')

    // Fetch multiple indexes at once
    const [{ hits: insights }, { hits: suggestedCampaigns }, { hits: googleAds }] =
      await multiSearch<[TargetableInsight, OptimizedCampaign, GenericAd]>([
        {
          indexName: insightsIndex,
          params: {
            ...commonParams,
            filters: filterBy([commonParams.filters, isPublishedFilter]),
          },
        },
        {
          indexName: suggestedCampaignsIndex,
          params: {
            ...commonParams,
            filters: filterBy([commonParams.filters, isPublishedFilter]),
          },
        },
        {
          indexName: googleAdsIndex,
          params: {
            ...commonParams,
            filters: filterBy([commonParams.filters, inSmartFeed, noGoogleGroupAdsFilter]),
          },
        },
      ]).catch((e) => {
        console.error('Error fetching campaigns from search index', e)
        // allows the process to continue running even if algolia fetching fails
        return [{ hits: [] }, { hits: [] }, { hits: [] }]
      })

    const combined = [...insights, ...suggestedCampaigns, ...googleAds].sort(
      (a, b) => getAdDate(b) - getAdDate(a),
    )

    const cleanResults = combined
      .map(item => {
        // Add partner name to each item
        const partner = partners.find(p => p.id === item.chowlyPartnerId)
        return {
          ...item,
          partnerName: partner?.name || 'Unknown Partner',
          partnerTz: partner?.timezone || '',
        }
      })
      .filter((item) => {
        // Filter out already processed items
        const processed = alreadyProcessed.includes(item.id)
        const fbCampaign = withTodoAsMetaAd(item)

        // Filter out multi-store campaigns
        const isMultiStore = (fbCampaign?.ads?.length || 0) > 1
        const displayFbOrInsight = shouldDisplayCampaign(item, item.partnerTz)
        const displayGoogleAd = shouldDisplayGoogleAd(item, item.partnerTz)
        return !processed && !isMultiStore && displayFbOrInsight && displayGoogleAd
      })

    // Email Marketing - Fetch for partners selected
    const uniqueOrgPartners = uniqBy(
      partners.filter(partner => partner?.organization_id),
      'organization_id'
    );
    
    const emailMarketingPromises = uniqueOrgPartners.map(partner => 
      getEmailMarketingCampaigns(partner.organization_id || '', 'pending_approval')
        .then(campaigns => campaigns.map(campaign => ({
          ...campaign,
          organizationName: partner.organization_name,
          organizationId: partner.organization_id,
        })))
        .catch((e) => {
          console.error(`Error fetching email marketing campaigns for org ${partner.organization_id}`, e)
          return []
        })
    );

    const emailMarketingResults = await Promise.all(emailMarketingPromises);
    const emailMarketingCampaigns = emailMarketingResults.flat().map(campaign => ({
      ...campaign,
      partnerName: 'Unknown Partner Name',
    }));
    
    setToDos([...cleanResults, ...emailMarketingCampaigns] as unknown as TodoItemWithProperties[]);
    setIsSearching(false)
  }, [multiSearch, alreadyProcessed, partners])
 
  useEffect(() => {
    if (partners.length === 0) {
      return
    }
    setIsSearching(true)
    setToDos([])
    safeAsync(fetchCampaigns()).catch((e) => {
      console.error(e)
    })
  }, [safeAsync, fetchCampaigns, partners])

  return (
    <ThemeProvider theme={theme}>
      <FeedbackModal
        todoItem={feedbackModal.todoItem}
        mode={feedbackModal.mode}
        onClose={handleCloseModal}
        onSubmitted={handleModalSubmit}
        partnerId={feedbackModal?.todoItem?.chowlyPartnerId || ''}
        organizationId={feedbackModal?.todoItem?.organizationId || ''}
      />
      <TodoContainer>
        <ToDoTable>
          {toDos.length > 0 ? <TableHeaderCell>Name</TableHeaderCell> : null}
          <div data-cy='table-body'>
            {!isSearching && partners.length === 0 && <Skeleton />}
            {isSearching && partners.length >= 0 && <Skeleton />}
            {!isSearching && toDos.length === 0 && partners.length > 0 && <NoData />}
            {toDos.map((todo) => {
              return (
                <CampaignItem
                  key={todo.id}
                  item={todo}
                  onAction={handleAction}
                  onClick={handleOnShowItem}
                />
              )
            })}
          </div>
        </ToDoTable>
      </TodoContainer>
      {selectedItem && partnerIds.length > 0 && (
        <DigitalMarketingDetailsDrawer
          open
          item={selectedItem as TodoItemWithProperties}
          partnerId={selectedItem?.chowlyPartnerId || ''}
          onClose={handleDrawerClose}
          onAction={handleAction}
        />
      )}
    </ThemeProvider>
  )
}

export default ToDo
