import {
  OnboardingLocation,
  OnboardingLocationCreateInput,
  SquareLocation,
  createOnboardingLocation,
  requestMenuImport,
} from 'api'
import { Button, LocationSearch } from 'components'
import { useActionProgressListener, useSquareApi } from 'hooks'
import startCase from 'lodash/startCase'
import { useState } from 'react'
import { ActionStatus, SupportedPOS } from 'types'
import { getMenuManagementURL, showError } from 'utils'

import SelectPosLocation from './SelectPosLocation'
import SelectPosSystem from './SelectPosSystem'
import * as Styled from './styles'

const formatOnboardingLocation = (location: OnboardingLocation | null) =>
  location ? `${location.name} - ${location.address}, ${location.city}, ${location.state}` : ''

const formatPosLocationName = ({ name, merchant_id }: SquareLocation) => `${name} (${merchant_id})`

const getStatusText = (status: ActionStatus, actionType: string, error?: string) => {
  if (status === ActionStatus.Started) {
    return startCase(`${actionType}ing Categories`)
  }
  if (status === ActionStatus.Complete) {
    return startCase(`Categories ${actionType}ed`)
  }
  if (status === ActionStatus.Failed) {
    return error
  }
  return ''
}

const AddLocation = () => {
  const { loginSquare, getSquareLocations, submitSquareLocation } = useSquareApi()
  const [newLocation, setNewLocation] = useState<OnboardingLocation | null>(null)
  const [pos, setPos] = useState<SupportedPOS | null>(null)
  const [squarePosLocations, setSquarePosLocations] = useState<SquareLocation[]>([])
  const [posLocation, setPosLocation] = useState<SquareLocation | null>(null)
  const onboardingLocationId = newLocation?.id
  const partnerId = newLocation?.partner_id
  const actionType = 'import'
  const { runProgressListener, progress, status, error } = useActionProgressListener({
    partnerId,
    actionType,
  })
  const statusText = getStatusText(status, actionType, error)

  const handleSubmit = async (values: OnboardingLocationCreateInput) => {
    try {
      const { data } = await createOnboardingLocation(values)
      setNewLocation(data.attributes)
    } catch (error) {
      showError(error)
    }
  }

  const handlePosSystemSelect = async (pos: SupportedPOS) => {
    try {
      if (onboardingLocationId && pos === SupportedPOS.SQUARE) {
        await loginSquare(onboardingLocationId)
        const locations = await getSquareLocations(onboardingLocationId)
        setSquarePosLocations(locations)
        setPos(pos)
      }
    } catch (error) {
      showError(error)
    }
  }

  const handlePosLocationSelect = async (location: SquareLocation) => {
    try {
      if (!onboardingLocationId) {
        throw new Error('onboarding_location_id is undefined')
      }
      setPosLocation(location)
      const partner = await submitSquareLocation(onboardingLocationId, location.id)
      setNewLocation({ ...newLocation, partner_id: Number(partner.id) })
    } catch (err) {
      showError(err)
    }
  }

  const importCategories = async () => {
    try {
      if (partnerId) {
        await requestMenuImport(partnerId, true)
        runProgressListener()
      }
    } catch (error) {
      showError(error)
    }
  }

  return (
    <Styled.Container>
      <Styled.Title>Add New Location</Styled.Title>
      {newLocation ? (
        <>
          <Styled.Row>
            <Styled.Label>Location Selected</Styled.Label>
            <div>
              <Styled.Value>{formatOnboardingLocation(newLocation)}</Styled.Value>
              <Styled.TextButton onClick={() => setNewLocation(null)}>
                Change location
              </Styled.TextButton>
            </div>
          </Styled.Row>
          {pos === SupportedPOS.SQUARE ? (
            <>
              <Styled.Row>
                <Styled.Label>Square Account</Styled.Label>
                <Styled.Value>{newLocation.name}</Styled.Value>
              </Styled.Row>
              {posLocation ? (
                <>
                  <Styled.Row>
                    <Styled.Label>Square Location</Styled.Label>
                    <Styled.Value>{formatPosLocationName(posLocation)}</Styled.Value>
                  </Styled.Row>
                  <Styled.Spacer />
                  {status === ActionStatus.NotStarted && (
                    <>
                      <Styled.Description>
                        Next, we will import all the category names found in your menu. After that
                        you can select only the ones you'd like to include in your online menus.
                      </Styled.Description>
                      <Styled.Spacer />
                      <Button size='small' onClick={importCategories}>
                        Import Categories
                      </Button>
                    </>
                  )}
                  {status !== ActionStatus.NotStarted && (
                    <>
                      <Styled.ProgressContainer>
                        <Styled.Progress
                          id='sync'
                          data-testid='syncProgress'
                          value={progress}
                          max={100}
                        />
                        <Styled.ProgressLabel htmlFor='sync'>{progress}%</Styled.ProgressLabel>
                      </Styled.ProgressContainer>
                      <Styled.StatusText
                        hasError={status === ActionStatus.Failed}
                        data-testid='syncStatus'
                      >
                        {statusText}
                        {status === ActionStatus.Failed && (
                          <Styled.TextButton onClick={importCategories}>Retry</Styled.TextButton>
                        )}
                      </Styled.StatusText>
                      {status === ActionStatus.Complete && (
                        <Styled.FooterButtons>
                          <Styled.LinkButton
                            to={getMenuManagementURL(newLocation.partner_id as unknown as string)}
                          >
                            Go To This Location's Page
                          </Styled.LinkButton>
                          <Button size='small' onClick={() => alert('Coming soon!')}>
                            Continue to Menu Creation
                          </Button>
                        </Styled.FooterButtons>
                      )}
                    </>
                  )}
                </>
              ) : (
                <SelectPosLocation
                  locations={squarePosLocations}
                  onSelect={handlePosLocationSelect}
                />
              )}
            </>
          ) : (
            <SelectPosSystem onSelect={handlePosSystemSelect} />
          )}
        </>
      ) : (
        <LocationSearch submitButtonTitle='Use This Location' onSubmit={handleSubmit} />
      )}
    </Styled.Container>
  )
}

export default AddLocation
