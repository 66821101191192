import MetricsCarousel from 'components/MetricsCarousel/MetricsCarousel'
import SingleLocationDropdown from 'components/SingleLocationDropdown/SingleLocationDropdown'
import { mapLocation } from 'context/LocationContext'
import { PageContainer, ToDoContainer, ToDoTitle } from 'features/digital-marketing/styles'
import ToDo from 'features/digital-marketing/ToDo/ToDo'
import { useGetLocations } from 'hooks'
// import { SmartPricing as SmartPricingCard } from './ActionCards'
import { useCallback, useMemo } from 'react'
import { Location } from 'types'

import SalesOrderReport from './SalesOrderReport'
import { ShortCut } from './Shortcuts'
import * as Styled from './styles'

const Health = () => {
  const { defaultLocation, setDefaultLocation, locations, isAdminUser } = useGetLocations()
  const locationId = defaultLocation !== null ? String(defaultLocation?.id) : ''
  const locationName = defaultLocation?.name || ''
  const organizationId = defaultLocation !== null ? String(defaultLocation?.organization_id) : ''

  let toDoLocations = []
  if (isAdminUser) {
    toDoLocations = useMemo(
      () => [{ id: locationId, name: locationName, organization_id: organizationId }],
      [locationId, locationName, organizationId],
    )
  } else {
    toDoLocations = useMemo(() => {
      return locations.map((location) => mapLocation(location))
    }, [locations])
  }

  // Handle location change from the dropdown
  const handleLocationChange = useCallback(
    (location: Location) => {
      // Update state only when a location is selected
      setDefaultLocation({
        id: location.id,
        name: location.name,
        organization_id: location.organization_id,
        organization_name: location.organization_name,
        timezone: location.timezone,
      })
    },
    [setDefaultLocation],
  )

  return (
    <Styled.HomeContainer>
      <SingleLocationDropdown onLocationChange={handleLocationChange} />

      <ShortCut locationId={locationId} />

      <PageContainer style={{ padding: 0 }}>
        <ToDoContainer>
          <ToDoTitle>Digital Marketing To-Do</ToDoTitle>
          <ToDo partners={toDoLocations} />
        </ToDoContainer>
      </PageContainer>

      {!isAdminUser && (
        <>
          <MetricsCarousel />
          <SalesOrderReport />
          {/* <SmartPricingCard isLoading={isLoading} /> */}
        </>
      )}
    </Styled.HomeContainer>
  )
}

export default Health
