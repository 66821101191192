import {
  OnboardingLocationResource,
  OrderPlatformResource,
  PartnerResource,
  TypeName,
} from 'api/models'
import capitalize from 'lodash/capitalize'
import { PlatformName } from 'types'

const formatLocations = (
  data: PartnerResource[],
  included?: (OrderPlatformResource | OnboardingLocationResource)[] | undefined,
) => {
  const locations = data
    .map(({ attributes, relationships }) => {
      let platforms: number | OrderPlatformResource[] = []

      if (included) {
        platforms = relationships.order_platforms.data.map(
          (platform) =>
            (included ?? []).find(
              ({ id, type }) => type === TypeName.OrderPlatform && platform.id === id,
            ) as OrderPlatformResource,
        )
      }

      return {
        id: attributes.id,
        name: attributes.name,
        city: attributes.city,
        state: attributes.state,
        timezone: attributes.timezone,
        status: capitalize(attributes.aasm_state),
        organization_id: attributes.organization_id,
        organization_name: attributes.organization_name,
        enabledPlatforms: platforms?.length
          ? platforms.filter(
              ({ attributes }) =>
                attributes.aasm_state === 'enabled' &&
                attributes.name !== PlatformName.CHOWLY &&
                attributes.name !== PlatformName.GARCON,
            )
          : [],
        disabledPlatforms: platforms?.length
          ? platforms.filter(
              ({ attributes }) =>
                attributes.aasm_state === 'disabled' &&
                attributes.name !== PlatformName.CHOWLY &&
                attributes.name !== PlatformName.GARCON,
            )
          : [],
        onboardingPlatforms: platforms?.length
          ? platforms.filter(
              ({ attributes }) =>
                attributes.aasm_state === 'onboarding' &&
                attributes.name !== PlatformName.CHOWLY &&
                attributes.name !== PlatformName.GARCON,
            )
          : [],
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))

  return locations
}

export default formatLocations
