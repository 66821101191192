import { sigmaPages } from 'config'
import { useEmbedUrl } from 'hooks'

import { ReportContainer, ReportFrame } from './styles'

type Props = {
  partnerId?: string
}

const OrdersComeFromReport = ({ partnerId }: Props) => {
  const url = useEmbedUrl(sigmaPages.ordersComeFromReport, partnerId)

  return (
    <ReportContainer>
      <ReportFrame src={url} title='Where do my Orders Come From' />
    </ReportContainer>
  )
}

export default OrdersComeFromReport
