import {
  AccountCircleOutlined,
  AnalyticsOutlined,
  CampaignOutlined,
  ChatOutlined,
  ChevronLeft,
  ChevronRight,
  HelpOutline,
  Home,
  KeyboardArrowDown,
  PaidOutlined,
  ReceiptOutlined,
  Restaurant,
} from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { ReactComponent as ChowlyNavLogoCollapsed } from 'assets/icons/chowly-nav-small.svg'
import { ReactComponent as ChowlyNavLogo } from 'assets/icons/ChowlyNavImg.svg'
import closeIcon from 'assets/icons/close-icon.svg'
import { HamburgerButton, UserMenu } from 'components'
import {
  useGetLocationPartners,
  useGetLocations,
  useIsDesktop,
  useIsMobile,
  useOpenModal,
} from 'hooks'
import { useAuth0 } from 'libs/auth0-react'
import { ReactNode, createElement, useEffect, useState } from 'react'
import {
  getAnalyticsURL,
  getBillingURL,
  getCustomerSurveysURL,
  getDigitalMarketingURL,
  getHealthURL,
  getLoyaltyURL,
  getMenuManagementURL,
  getOrderManagementURL,
  getPromoCodesURL,
} from 'utils'

import * as Styled from './styles'

type NavLinkItem = {
  title: string
  route?: string
  href?: string
  target?: string
  popOut?: boolean
  icon?: ReactNode
  collapsedIcon?: ReactNode
  onClick?: () => void
  subNav?: Array<NavLinkItem>
}

const NavBar = ({ onCollapseChange }: { onCollapseChange?: (isCollapsed: boolean) => void }) => {
  const { defaultLocation } = useGetLocations()
  const locationId = defaultLocation !== null ? String(defaultLocation?.id) : ''

  const { organization } = useGetLocationPartners(locationId)
  const isPlatformOrganization = organization?.attributes?.chowly_platform ?? false

  const { isMobile } = useIsMobile()
  const { isDesktop } = useIsDesktop()
  const { logout, user } = useAuth0()

  const [isNavBarOpen, setIsNavBarOpen] = useState(isMobile ? false : true)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [expandedItems, setExpandedItems] = useState<string[]>([])

  const toggleNavBar = () => setIsNavBarOpen(!isNavBarOpen)
  const isAdminUser = user?.email?.includes('@chowlyinc.com')

  const openModal = useOpenModal()

  const navLinks: NavLinkItem[] = [
    {
      title: 'Home',
      route: getHealthURL(),
      icon: <Home />,
    },
    {
      title: 'Menu Management',
      route: getMenuManagementURL(locationId),
      icon: <Restaurant />,
    },
    {
      title: 'Order Management',
      route: getOrderManagementURL(),
      icon: <ReceiptOutlined />,
    },
    {
      title: 'Digital Marketing',
      route: getDigitalMarketingURL(),
      icon: <CampaignOutlined />,
      subNav: [
        {
          title: "Marketing To-Do's",
          route: getDigitalMarketingURL(),
        },
        ...(isAdminUser && isPlatformOrganization
          ? [
              {
                title: 'Loyalty Program',
                route: getLoyaltyURL(),
              },
              {
                title: 'Promo Codes',
                route: getPromoCodesURL(),
              },
            ]
          : []),
      ],
    },
    {
      title: 'Customer Surveys',
      route: getCustomerSurveysURL(),
      icon: <ChatOutlined />,
    },
    {
      title: 'Analytics',
      route: getAnalyticsURL(),
      icon: <AnalyticsOutlined />,
    },
  ]

  const manageAccountLinks: NavLinkItem[] = [
    {
      title: 'Billing',
      route: getBillingURL(),
      icon: <PaidOutlined />,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: isMobile ? toggleNavBar : () => {},
    },
    {
      title: 'Account Management',
      icon: <AccountCircleOutlined />,
      onClick: () => {
        openModal('ManageAccount')
      },
    },
    {
      title: 'Help Center',
      icon: <HelpOutline />,
      onClick: () => {
        window.open('https://chowly.help/s/', '_blank')
      },
    },
    {
      title: 'Log Out',
      icon: <Styled.RedLogoutIcon />,
      onClick: () => {
        logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })
      },
    },
  ]

  const toggleCollapse = () => {
    if (isDesktop) {
      setIsCollapsed((prev) => {
        const newCollapsedState = !prev
        if (onCollapseChange) onCollapseChange(newCollapsedState)
        return newCollapsedState
      })
    }
  }

  const toggleExpand = (title: string) => {
    if (expandedItems.includes(title)) {
      setExpandedItems((prevItems) => prevItems.filter((item) => item !== title))
    } else {
      setExpandedItems((prevItems) => [...prevItems, title])
    }
  }

  // Disable the scroll of the body content when the menu is open
  useEffect(() => {
    if (isMobile) {
      if (isNavBarOpen) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
    }
  }, [isNavBarOpen, isMobile])

  return (
    <>
      {!isDesktop && (
        <Styled.MobileContainer>
          <HamburgerButton onClick={toggleNavBar} />
          <Styled.LogoLinkMobile to='/'>
            <ChowlyNavLogo />
          </Styled.LogoLinkMobile>
        </Styled.MobileContainer>
      )}

      {/* Left SideBar */}
      {isMobile && isNavBarOpen && <Styled.Overlay isOpen />}
      <Styled.SideBar
        $isOpen={isDesktop || isNavBarOpen}
        $isCollapsed={isDesktop ? isCollapsed : false}
      >
        {/* Show collapse button only on desktop */}
        {isDesktop ? (
          <Styled.CollapseButton onClick={toggleCollapse}>
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </Styled.CollapseButton>
        ) : (
          <Styled.CloseButton alt='cancel' src={closeIcon} onClick={toggleNavBar} />
        )}

        <Styled.ToolBar $isCollapsed={isDesktop ? isCollapsed : false}>
          {createElement(
            !isDesktop ? Styled.LogoSectionDesktop : Styled.LogoSection,
            null,
            <Styled.LogoLink to={`${locationId}/home`} $isCollapsed={isCollapsed}>
              {isCollapsed ? <ChowlyNavLogoCollapsed /> : <ChowlyNavLogo />}
            </Styled.LogoLink>,
          )}

          <Styled.MenuItemsContainer>
            <Styled.Nav $isCollapsed={isCollapsed}>
              {navLinks.map((link, linkIndex) => (
                <Tooltip
                  key={`${linkIndex}-${link.title}`}
                  title={isCollapsed && isDesktop ? link.title : ''}
                  placement='right'
                  arrow
                >
                  {link.subNav ? (
                    <>
                      <Tooltip
                        key={`${linkIndex}-${link.title}`}
                        title={isCollapsed && isDesktop ? link.title : ''}
                        placement='right'
                        arrow
                      >
                        <Styled.NavLink
                          key={`${linkIndex}-${link.title}`}
                          onClick={(e) => {
                            if (!isCollapsed) {
                              e.preventDefault()
                              toggleExpand(link.title)
                            }
                          }}
                          to={isCollapsed ? getDigitalMarketingURL() : 'javascript:void(0)'}
                          className={
                            link.subNav?.some(
                              (subLink) => window.location.pathname === subLink.route,
                            )
                              ? 'active'
                              : ''
                          }
                          end
                        >
                          <Styled.NavLinkTextDigitalMarketing $isCollapsed={isCollapsed}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                              {link.icon}
                              {!isCollapsed && <span>{link.title}</span>}
                            </div>
                            {!isCollapsed && (
                              <KeyboardArrowDown
                                style={{
                                  transform: expandedItems.includes(link.title)
                                    ? 'rotate(180deg)'
                                    : 'rotate(0)',
                                  transition: 'transform 0.3s ease',
                                }}
                              />
                            )}
                          </Styled.NavLinkTextDigitalMarketing>
                        </Styled.NavLink>
                      </Tooltip>
                      <Styled.SubNav
                        $isCollapsed={isCollapsed}
                        $isExpanded={expandedItems.includes(link.title)}
                      >
                        {!isCollapsed &&
                          link.subNav.map((subLink, subIndex) => (
                            <Styled.NavLink
                              key={`${linkIndex}-${subIndex}`}
                              to={subLink.route ?? ''}
                              {...(isMobile && { onClick: toggleNavBar })}
                              $isSubNav
                              end
                            >
                              <Styled.NavLinkText $isCollapsed={isCollapsed}>
                                <span>{subLink.title}</span>
                              </Styled.NavLinkText>
                            </Styled.NavLink>
                          ))}
                      </Styled.SubNav>
                    </>
                  ) : (
                    <Styled.NavLink
                      key={`${linkIndex}-${link.title}`}
                      to={link.route ?? ''}
                      {...(isMobile && { onClick: toggleNavBar })}
                      end
                    >
                      <Styled.NavLinkText $isCollapsed={isCollapsed}>
                        {link.icon}
                        {!isCollapsed && <span>{link.title}</span>}
                      </Styled.NavLinkText>
                    </Styled.NavLink>
                  )}
                </Tooltip>
              ))}
            </Styled.Nav>
            <Styled.ManageAccount>
              <UserMenu isCollapsed={isCollapsed} />
              {manageAccountLinks.map((link, linkIndex) => (
                <Tooltip
                  key={`${linkIndex}-${link.title}`}
                  title={isCollapsed && isDesktop ? link.title : ''}
                  placement='right'
                  arrow
                >
                  <Styled.ManageLink to={link.route ?? '#'} onClick={link.onClick}>
                    <Styled.NavLinkText $isCollapsed={isCollapsed}>
                      {link.icon}
                      {!isCollapsed &&
                        (link.title === 'Log Out' ? (
                          <Styled.Logout>{link.title}</Styled.Logout>
                        ) : (
                          <span>{link.title}</span>
                        ))}
                    </Styled.NavLinkText>
                  </Styled.ManageLink>
                </Tooltip>
              ))}
            </Styled.ManageAccount>
          </Styled.MenuItemsContainer>
        </Styled.ToolBar>
      </Styled.SideBar>
    </>
  )
}

export default NavBar
