import React, { createContext, useState, useEffect, useCallback } from 'react'
import { getAllOrganizations, Organization } from 'api'
import { useAuth0 } from 'libs/auth0-react'
import { OrganizationContextType } from 'types/OrganizationContextTypes'
import formatOrganizations from 'utils/formatOrganizations'

const OrganizationContext = createContext<OrganizationContextType | undefined>(undefined)

// TODO - Add query fields for organizations
const organizationQueryFields = { 'fields[organization]': 'id,name' }

export const OrganizationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [defaultOrganization, setDefaultOrganization] = useState<{ id: number, name: string } | null>(null)
  const { user } = useAuth0()
  const isAdminUser = user?.email?.includes('@chowlyinc.com') ?? false

  /**
   * fetchOrganizations: 
   * For external users, fetches all organizations recursively and stores them in global state.
   * For non-external users, fetches organizations based on pagination and stores them in global state.
   */
  const fetchOrganizations = useCallback(async (page: number, allOrganizations: Organization[] = []): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await getAllOrganizations(page, '', organizationQueryFields)
      const data = response?.data || []
      const formattedOrganizations = formatOrganizations(data)

      const updatedOrganizations = [...allOrganizations, ...formattedOrganizations]

      if (data.length > 0 && page === 1) {
        // Set defaultLocation if more than one location exists
        setDefaultOrganization({
          id: formattedOrganizations[0].id, 
          name: formattedOrganizations[0].name
        })
      }

      if (!isAdminUser) {
        if (response?.meta?.total && updatedOrganizations.length < response.meta.total) {
          setCurrentPage(page + 1)
          return fetchOrganizations(page + 1, updatedOrganizations) // Recursive call to fetch all locations (only for external users)
        } else {
          setHasMore(false)
        }
      } else {
        if (response?.meta?.total) {
          setHasMore(page * 25 < response.meta.total)
        }
      }
      setOrganizations(updatedOrganizations)
    } catch (err) {
      setError(new Error('Failed to fetch locations'))
    } finally {
      setIsLoading(false)
    }
  }, [organizations, isAdminUser])

  
  /**
   * searchOrganizations:
   * For external users, filters the already fetched organizations from global state based on search term or page.
   * For non-external users, fetches organizations based on search term or page and returns the results without saving to global state.
   */
  const searchOrganizations = useCallback(async (page: number, term: string): Promise<Organization[]> => {
    try {
      setIsLoading(true)

      if (!isAdminUser) {
        const filteredOrganizations = organizations.filter(organization =>
          organization.name.toLowerCase().includes(term.toLowerCase())
        )
        const paginatedOrganizations = filteredOrganizations.slice((page - 1) * 25, page * 25)
        return paginatedOrganizations
      } else {
        const response = await getAllOrganizations(page, term, organizationQueryFields)
        const data = response?.data || []
        const formattedOrganizations = formatOrganizations(data)
        return formattedOrganizations
      }
    } catch (err) {
      setError(new Error('Failed to search locations'))
      return []
    } finally {
      setIsLoading(false)
    }
  }, [organizations, isAdminUser])

  useEffect(() => {
    if (!isLoading && user && currentPage === 1) {
      fetchOrganizations(currentPage)
    }
  }, [isAdminUser, user, currentPage])

  return (
    <OrganizationContext.Provider value={{
      organizations,
      isLoading,
      error,
      hasMore,
      currentPage,
      defaultOrganization,
      isAdminUser,
      fetchOrganizations,
      searchOrganizations,
      setOrganizations,
      setCurrentPage,
      setDefaultOrganization,
    }}>
      {children}
    </OrganizationContext.Provider>
  )
}

export { OrganizationContext }