import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  MenuItem,
  TableCell as MuiTableCell,
  Radio,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { PromoCodeStatus } from 'api/promoCodes/enums'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-icon.svg'
// @ts-ignore
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { breakpoints, colors, fontFamily, fontWeight } from 'theme'

const commonFontStyle = {
  fontFamily: fontFamily.inter,
  fontSize: '13px',
}

const cellPadding = {
  padding: '10px 16px',
}

const hideOnMobile = {
  [`@media ${breakpoints.xs_max}`]: {
    '&&': {
      display: 'none',
    },
  },
}

const showOnMobile = {
  [`@media ${breakpoints.xs_max}`]: {
    '&&': {
      display: 'table-cell',
    },
  },
}

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '1280px',
  rowGap: '1rem',
  paddingBottom: 'calc(2vh + 24px)',
})

export const PromoCodesContainer = styled.div({
  width: '100%',
  margin: '24px auto',
  padding: '20px 15px',
  borderRadius: '8px',
  background: '#FEFEFE',
})

export const PromoCodesTitle = styled.span({
  ...commonFontStyle,
  color: `var(--Global/Heading/Heading 4, ${colors.black})`,
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '140%',
  letterSpacing: '0%',
  verticalAlign: 'bottom',
})

export const StyledTableRow = styled(TableRow)({
  borderTop: '1px solid #ddd',
  borderBottom: '1px solid #ddd',
  cursor: 'pointer',
})

export const CustomTableCell = styled(MuiTableCell)({
  '&&': {
    ...commonFontStyle,
    fontSize: '11px',
    fontWeight: fontWeight.medium,
    ...cellPadding,
    color: '#777777',
  },
  ...hideOnMobile,
})

export const CustomLink = styled.a({
  color: '#1B51A4',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
})

export const TableCell = styled(MuiTableCell)({
  '&&': {
    ...commonFontStyle,
    fontWeight: fontWeight.medium,
    ...cellPadding,
    color: '#000',
  },
  ...hideOnMobile,
})

export const MobileVisibleTableCell = styled(TableCell)({
  ...showOnMobile,
})

export const MobileVisibleTableHeader = styled(MuiTableCell)({
  '&&': {
    ...commonFontStyle,
    fontSize: '11px',
    fontWeight: fontWeight.medium,
    ...cellPadding,
    color: '#777777',
  },
  ...showOnMobile,
})

export const RowPair = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
})

export const RowSingle = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0',
})

export const MenuItems = styled(MenuItem)({
  '&&': {
    ...commonFontStyle,
  },
})

export const CenteredIconButton = styled(IconButton)({
  '&&': {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
})

export const CenterTableCell = styled(TableCell)({
  '&&': {
    textAlign: 'center',
  },
})

export const PaginationContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '1rem',
})

export const ModalTitle = styled(Typography)({
  '&&': {
    fontSize: '20px',
    fontWeight: fontWeight.semibold,
    fontFamily: fontFamily.default,
    color: colors.black,
  },
})

export const ModalText = styled(Typography)({
  '&&': {
    fontSize: '15px',
    fontFamily: fontFamily.default,
    marginBottom: '0px',
    color: colors.black,
  },
})

export const ModalActions = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
})

export const ModalContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  gap: '16px',
})

export const ModalButton = styled(Button)<{ customVariant?: 'primary' | 'secondary' | 'failure' }>(
  ({ customVariant }) => ({
    '&&': {
      padding: '10px 16px',
      marginLeft: '10px',
      fontSize: '14px',
      fontWeight: fontWeight.medium,
      fontFamily: fontFamily.default,
      borderRadius: '4px',
      textTransform: 'none',
      backgroundColor:
        customVariant === 'primary'
          ? colors.primary.DEFAULT
          : customVariant === 'failure'
          ? 'var(--Global-Utility-Failure, #C13030)'
          : colors.gray.medium,
      color: customVariant === 'primary' ? colors.white : customVariant === 'failure' ? colors.white : colors.black,
      '&:hover': {
        backgroundColor:
          customVariant === 'primary'
            ? colors.primary.light
            : customVariant === 'failure'
            ? 'var(--Global-Utility-FailureHover, #9F2525)'
            : colors.gray.dark,
      },
    },
  }),
)

export const CustomTooltip = styled(Tooltip)({
  '& .MuiTooltip-tooltip': {
    maxWidth: '250px',
    fontSize: '14px',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    padding: '10px',
  },
})

export const DrawerHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px',
  borderBottom: 'solid 1px #ccc',

  '& h2': {
    fontFamily: fontFamily.inter,
    fontSize: '20px',
    fontWeight: fontWeight.semibold,
    margin: 0,
  },
})

export const DrawerContent = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column' as const,
  padding: 15,
}))

export const Row = styled.div<{ full?: boolean }>(({ full }) => ({
  display: 'flex',
  padding: '8px 0',
  flexDirection: 'column' as const,
  fontFamily: fontFamily.inter,
  fontSize: 13,
  color: '#292929',
  width: full ? '100%' : '496px',
  fontWeight: fontWeight.medium,
  [`@media ${breakpoints.xs_max}`]: {
    width: '300px',
  },
}))

export const Label = styled.div({
  fontFamily: 'Inter, sans-serif',
  fontSize: '11px',
  marginBottom: 10,
  color: '#777777',
  fontWeight: fontWeight.medium,
})

export const Value = styled.div<{ horizontal?: boolean }>(({ horizontal }) => ({
  textAlign: 'left',
  color: '#292929',
  display: 'flex',
  flexDirection: horizontal ? 'row' : 'column',
  alignItems: horizontal ? 'center' : 'flex-start',
  justifyContent: 'flex-start',
  gap: horizontal ? '8px' : '0',
  marginBottom: 10,
  whiteSpace: 'nowrap',
}))

export const WrappedValue = styled(Value)({
  wordBreak: 'break-all',
})

export const CouponName = styled.div({
  textAlign: 'left',
  color: '#292929',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '0',
})

export const InfoItem = styled.div({
  marginBottom: '10px',
  textAlign: 'left',
  color: '#292929',
  '& a': {
    color: '#1B51A4',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '&:last-child': {
    marginBottom: '0',
  },
})

export const SubmissionTime = styled(Typography)({
  '&&': {
    fontFamily: fontFamily.inter,
    fontSize: '13px',
    fontWeight: fontWeight.medium,
    color: '#000',
  },
})

export const SubmissionDate = styled(Typography)({
  '&&': {
    fontFamily: fontFamily.inter,
    fontSize: '13px',
    color: '#777777',
  },
})

export const ButtonContainer = styled.div(() => ({
  '&&': {
    display: 'flex',
    marginTop: 30,
  },
}))

export const StyledButton = styled(Button)({
  '&&': {
    backgroundColor: '#fff',
    border: '1px solid #1B51A4',
    borderRadius: '6px',
    color: '#1B51A4',
    boxShadow: 'none',
    fontFamily: fontFamily.inter,
    fontSize: 15,
    fontWeight: fontWeight.semibold,
    marginRight: 30,
    textTransform: 'capitalize',
    '&:hover': {
      boxShadow: 'none',
    },
  },
})

export const StyledCancelButton = styled(StyledButton)({
  '&&': {
    border: '1px solid var(--Global-Utility-Failure, #C13030)',
    color: 'var(--Global-Utility-Failure, #C13030)',
  },
})

const getStatusStyles = (status: PromoCodeStatus) => {
  switch (status) {
    case PromoCodeStatus.ACTIVE:
      return {
        backgroundColor: 'rgba(50, 150, 93, 0.1)',
        color: '#32965D',
      }
    case PromoCodeStatus.CANCELED:
      return {
        backgroundColor: 'rgba(193, 48, 48, 0.1)',
        color: '#C13030',
      }
    case PromoCodeStatus.EXPIRED:
    case PromoCodeStatus.DISABLED:
      return {
        backgroudColor: 'rgba(119, 119, 119, 0.1)',
        color: '#777777',
      }
    default:
      return {
        backgroundColor: 'transparent',
        color: '#000',
      }
  }
}

export const StyledChip = styled(Chip)<{ status: PromoCodeStatus }>(({ status }) => ({
  '&&': {
    fontFamily: fontFamily.inter,
    fontSize: '13px',
    fontWeight: fontWeight.medium,
    borderRadius: '5px',
    padding: 0,
    height: '23px',
    backgroundColor: getStatusStyles(status).backgroundColor,
    color: getStatusStyles(status).color,
    textTransform: 'capitalize',
  },
}))

export const FormContainer = styled.div({
  width: '100%',
  margin: '24px auto',
  padding: '20px 15px',
  borderRadius: '8px',
  background: '#FEFEFE',
})

export const Input = styled.input({
  width: '496px',
  height: '47px',
  gap: '8px',
  borderRadius: '8px',
  padding: '12px',
  border: '1px solid #D7D7D7',
  fontSize: '1rem',
  lineHeight: '1.3rem',
  outline: 'none',
  '&:focus': {
    borderColor: '#0062cc',
  },
  [`@media ${breakpoints.xs_max}`]: {
    width: '300px',
  },
})

export const FormLabel = styled.span({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '13px',
  lineHeight: '150%',
  letterSpacing: '0%',
  verticalAlign: 'bottom',
  color: `var(--Global-Text-Primary, ${colors.primary.medium})`,
})

export const Subtext = styled.span({
  maxWidth: '496px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '150%',
  letterSpacing: '0%',
  verticalAlign: 'bottom',
  color: 'var(--Global-Background-Tertiary, #777777)',
})

export const DatePickerWrapper = styled.div({
  width: '50%',
  height: '47px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '.react-datepicker-wrapper > div': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [`@media ${breakpoints.xs_max}`]: {
      maxWidth: '150px',
    },
  },
})

export const DateInput = styled(DatePicker)<{ error?: boolean }>(({ error }) => ({
  '&&': {
    display: 'flex',
    padding: '8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '6px',
    width: '-webkit-fill-available',
    color: '#484848',
    fontFamily: 'Inter',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '150%',
    border: error ? '1px solid #C13030' : '1px solid #D7D7D7',
    cursor: 'pointer',

    '&:focus': {
      outline: 'none',
      border: error ? '1px solid #C13030' : '1px solid #0062CC',
      boxShadow: 'none',
    },

    '& input': {
      paddingLeft: '40px',
      background: `url(${CalendarIcon}) no-repeat 12px center`,
      backgroundSize: '20px 20px',
    },
  },
}))

export const InlineRadioWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px',
  alignItems: 'center',
  marginBottom: '12px',
})

export const CustomRadio = styled(Radio)({
  '&.Mui-checked': {
    color: '#1B51A4',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 24,
  },
})

export const InlineFormControlLabel = styled(FormControlLabel)({
  margin: 0,
  '& .MuiTypography-root': {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '150%',
    letterSpacing: '0%',
    verticalAlign: 'bottom',
  },
})

export const ColumnCheckboxWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

export const CustomCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
    color: '#1B51A4',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 24,
  },
})

export const ActiveDatesText = styled.span({
  flex: 1,
  textAlign: 'left',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 600,
  fontSize: '15px',
  lineHeight: '150%',
  letterSpacing: '0%',
  verticalAlign: 'bottom',
  color: `var(--Global-Text-Primary, ${colors.primary.medium})`,
})

export const DateWrapper = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

export const ErrorText = styled.div({
  color: 'red',
  fontSize: '12px',
  marginTop: '4px',
})

// Header Styles
export const EmptyStateWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px',
  borderRadius: '8px',
  background: '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(27, 81, 164, 0.04)',
  width: '100%',
  height: '148px',
  justifyContent: 'center',
  gap: '4px',
  maxWidth: '1280px',
})

export const Title = styled.h1({
  color: '#000',
  fontFamily: 'Inter',
  fontSize: '25px',
  fontStyle: 'normal',
  fontWeight: 600,
  margin: 0,
})

export const Subtitle = styled.p({
  color: '#777',
  fontFamily: 'Inter',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  margin: 0,
})
