import { algolia } from "config"
import algoliasearch, { SearchClient } from 'algoliasearch'
import { useCallback, useState, useEffect } from "react"
import type { SearchResponse } from '@algolia/client-search'
import { getAlgoliaSecuredKey, getAlgoliaSecuredKeyByPartnerIds } from "api"
import { getPrefixedIndexName } from "utils/algolia"

let globalSearchClient: SearchClient | null = null;

const indexes = [
  getPrefixedIndexName('Campaigns'),
  getPrefixedIndexName('AdCampaigns'),
  getPrefixedIndexName('Insights'),
  getPrefixedIndexName('SuggestedCampaigns'),
]

/**
 * Initializes the Algolia Search Client with a secured key for multiple indices.
 */
const initializeSearchClient = async (indexNames: string[], partnerId: string | string[]): Promise<SearchClient | null> => {
  try {
    if (!globalSearchClient) {
      const securedApiKey = Array.isArray(partnerId)
        ? await getAlgoliaSecuredKeyByPartnerIds(indexNames, partnerId)
        : await getAlgoliaSecuredKey(indexNames, partnerId);
        
      globalSearchClient = algoliasearch(algolia.appId, securedApiKey);
    }
  } catch (error) {
    console.log("Unable to initilize the Algolia search client: ", error)
  }
  return globalSearchClient;
};

/**
 * @returns a function that can be used to search multiple indices at once.
 */
const useSearchAlgoliaMultiIndex = (partnerId: string | string[]) => {
  const [searchClient, setSearchClient] = useState<SearchClient | null>(null);

  // Reinitialize search client when partnerId changes
  useEffect(() => {
    const updateSearchClient = async () => {
      if (partnerId) {
        const client = await initializeSearchClient(indexes, partnerId);
        setSearchClient(client);
      } else {
        setSearchClient(null);
      }
    };

    updateSearchClient();
  }, [partnerId]);

  /**
   * A memoized function that can be used to search multiple indices at once.
   */
  const search = useCallback(async <T extends any[]>(
    queries: Parameters<SearchClient['search']>[0],
    cacheable = false
  ) => {
    if (!searchClient) {
      console.log("Search client is not initialized.");
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { results } = await (searchClient || globalSearchClient)!.search<T>(queries, {
      cacheable,
    });

    // Remove algolia specific properties
    const list = (results || []) as SearchResponse<T[number]>[];
    const cleanResults = list.map((result) => ({
      ...result,
      hits: result.hits?.map((hit) => {
        const { objectID, _highlightResult, ...rest } = hit;
        return rest as T[number];
      })
    }));

    return cleanResults;
  },
  [searchClient]
  )

  return search;
}

export default useSearchAlgoliaMultiIndex;