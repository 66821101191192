import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { updatePartner, PartnerResource } from 'api'
import settings from 'assets/icons/Settings.svg'
import { useState } from 'react'
import { toast } from 'react-toastify'
import * as Styled from './styles'

interface PosServiceFeeIdProps {
  locationId: string | null
  attributes: PartnerResource['attributes'] | undefined
  posServiceFeeId?: string
  refetch: () => void
}

const PosServiceFeeId = ({ locationId, attributes, posServiceFeeId, refetch }: PosServiceFeeIdProps) => {
  const [showModal, setShowModal] = useState(false)
  const [localValue, setLocalValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const clearModalValues = () => {
    setLocalValue('')
  }

  const closeModal = () => {
    setShowModal(false)
    clearModalValues()
  }

  const handleSave = async () => {
    if (!locationId || !attributes) return
    if (localValue === posServiceFeeId) {
      toast.error('Please enter a different value', { autoClose: 5000 })
      return
    }

    setIsLoading(true)
    try {
      await updatePartner(locationId, {
        attributes: {
            ...attributes,
            pos_service_charge_id: localValue.trim()
        }
      })
      toast.success('POS Service Fee ID updated successfully')
      refetch()
      closeModal()
    } catch (error) {
      toast.error('Failed to update POS Service Fee ID')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Styled.FeeContainer>
        <Styled.HeaderContainer>
          <Styled.Header>POS Service Fee ID</Styled.Header>
          <Styled.Value>{posServiceFeeId || ''}</Styled.Value>
        </Styled.HeaderContainer>
        <IconButton style={{ padding: 0 }} onClick={() => setShowModal(true)}>
          <Styled.Icon src={settings} alt='settings' />
        </IconButton>
      </Styled.FeeContainer>

      {showModal && (
        <Dialog
          open={showModal}
          onClose={closeModal}
          sx={{
            '& .MuiDialog-paper': {
              width: '343px',
              height: '212px',
              borderRadius: '8px',
            },
          }}
        >
          <DialogTitle style={{ paddingTop: '24px' }}>
            <Styled.ModalHeader>
              <Styled.Header>POS Service Fee ID</Styled.Header>
            </Styled.ModalHeader>
          </DialogTitle>
          <DialogContent sx={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <TextField
                placeholder={posServiceFeeId || 'Enter POS Service Fee ID'}
                onChange={(e) => setLocalValue(e.target.value)}
                fullWidth
                value={localValue}
                sx={{
                  display: 'flex',
                  padding: 0,
                  alignItems: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                  width: '100%',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#e5e7eb',
                    },
                  },
                  '& .MuiInputBase-input, .MuiOutlinedInput-input': {
                    boxSizing: 'border-box',
                    boxShadow: 'none',
                    height: '2.4375em',
                  },
                }}
              />
              <Typography>
                <Styled.ModalText>Enter the POS Service Fee ID for this location</Styled.ModalText>
              </Typography>
            </div>
            <DialogActions sx={{ padding: 0 }}>
              <Styled.ModalFooter>
                <Button sx={{ padding: 0 }} onClick={closeModal}>
                  <Styled.PrimaryButton data-testid={'cancel'}>Cancel</Styled.PrimaryButton>
                </Button>
                <Button 
                  sx={{ padding: 0 }} 
                  onClick={handleSave} 
                  variant='contained'
                  disabled={isLoading}
                >
                  <Styled.PrimaryButton backGroundColor={true} data-testid={'apply'}>
                    Apply
                  </Styled.PrimaryButton>
                </Button>
              </Styled.ModalFooter>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
} 

export default PosServiceFeeId