import { ovationApi } from 'config'
import { generatePath } from 'react-router-dom'
import { ConversationSearchResult, Conversation, ConversationTimelineResponse, ConversationsListResponse, ConversationMessage } from './models'

import client from '../chowly'

const generateParams = (conversationBody: ConversationSearchResult) => {
  const params = new URLSearchParams()
  Object.entries(conversationBody).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => params.append(key, v))
    } else {
      params.append(key, value)
    }
  })
  return params
}

export const resolveConversation = async (id: string, partnerId: string) => {
  const url = generatePath(ovationApi.resolveConversation, {conversation_id: id})
  const { data } = await client.put<Conversation>(url + '?' + generateParams({partner_id: partnerId}))
  return data
}

export const openConversation = async (id: string, partnerId: string) => {
  const url = generatePath(ovationApi.openConversation, {conversation_id: id})
  const { data } = await client.put<Conversation>(url + '?' + generateParams({partner_id: partnerId}))
  return data
}

export const replyToConversation = async (id: string, reply: string, partnerId: string) => {
  const url = generatePath(ovationApi.replyToConversation, {conversation_id: id})
  const { data } = await client.post<ConversationMessage>(url, { reply: reply, partner_id: partnerId })
  return data
}

export const listConversations = async (conversationBody: ConversationSearchResult) => {
  const url = generatePath(ovationApi.listConversations)

  const { data } = await client.get<ConversationsListResponse>(url + '?' + generateParams(conversationBody))
  return data
}
export const conversationTimeline = async (id: string, conversationBody: ConversationSearchResult) => {
  const url = generatePath(ovationApi.conversationTimeline, {conversation_id: id})
  const { data } = await client.get<ConversationTimelineResponse>(url + '?' + generateParams(conversationBody))
  return data
}

export const unresolveConversation = async (id: string, partnerId: string) => {
  const url = generatePath(ovationApi.unresolveConversation, {conversation_id: id})
  const { data } = await client.put<Conversation>(url + '?' + generateParams({partner_id: partnerId}))
  return data
}
