import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { AlertModal } from 'components'
import LoadingSkeleton from 'components/LoadingSkeleton/LoadingSkeleton'
import { useLoyaltyRedeemables } from 'hooks'
import React, { useState } from 'react'

import { SectionTitle } from '../styles'
import {
  CardBox,
  CreateButton,
  CurrentPageInfo,
  EmptyStateText,
  EmptyStateWrapper,
  PaginationSelect,
  PaginationText,
  PaginationWrapper,
  RewardsHeader,
  RowsPerPageWrapper,
  StyledMenuItem,
  StyledTableCell,
} from './styles'
import UpsertRewardModal from './UpsertRewardModal/UpsertRewardModal'

const LoyaltyRewards: React.FC<{ organizationId: string }> = ({ organizationId }) => {
  const { loyaltyRedeemables, loading, createReward, updateReward, deleteReward } =
    useLoyaltyRedeemables(organizationId || '')

  const [isModalOpen, setModalOpen] = useState(false)
  const [currentReward, setCurrentReward] = useState<{
    name: string
    discountType: string
    pointValue: string
    discount: string
  } | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedReward, setSelectedReward] = useState<any | null>(null)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleCreateOrUpdateReward = (
    rewardData: { name: string; discountType: string; pointValue: string; discount: string },
    isUpdate: boolean,
  ) => {
    if (isUpdate) {
      updateReward(selectedReward?.id || '', rewardData)
    } else {
      createReward(rewardData)
    }
    setModalOpen(false)
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, reward: any) => {
    setAnchorEl(event.currentTarget)
    setSelectedReward(reward)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleDeleteClick = () => {
    setConfirmationModalOpen(true)
    handleCloseMenu()
  }

  const handleConfirmDelete = () => {
    if (selectedReward) {
      deleteReward(selectedReward.id)
    }
    setConfirmationModalOpen(false)
  }

  const handleCancelDelete = () => {
    setConfirmationModalOpen(false)
  }

  // Calculate the current items to display
  const indexOfLastItem = currentPage * rowsPerPage
  const indexOfFirstItem = indexOfLastItem - rowsPerPage
  const currentItems = loyaltyRedeemables.slice(indexOfFirstItem, indexOfLastItem)

  return (
    <CardBox>
      <RewardsHeader>
        <SectionTitle data-testid='rewards-title'>Rewards</SectionTitle>
        <CreateButton
          onClick={() => {
            setCurrentReward(null)
            setModalOpen(true)
          }}
        >
          Create
        </CreateButton>
      </RewardsHeader>
      {loading ? (
        <LoadingSkeleton lineCount={3} />
      ) : (
        <EmptyStateWrapper>
          {loyaltyRedeemables.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Discount</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell>Points Required</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'center' }}>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItems.map((reward) => (
                    <TableRow key={reward.id} style={{ borderBottom: '1px solid #d7d7d7' }}>
                      <TableCell data-testid={`reward-name-${reward.id}`}>
                        {reward?.attributes?.name}
                      </TableCell>
                      <TableCell data-testid={`reward-discount-${reward.id}`}>
                        {reward?.attributes?.discount}
                      </TableCell>
                      <TableCell data-testid={`reward-discount-type-${reward.id}`}>
                        {reward?.attributes?.discount_type === 'fixed' ? '$' : '%'}
                      </TableCell>
                      <TableCell data-testid={`reward-points-required-${reward.id}`}>
                        {reward?.attributes?.cost}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <IconButton
                          onClick={(event) => handleMenuClick(event, reward)}
                          sx={{ borderRadius: '4px', width: '28px', height: '28px' }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <EmptyStateText>
              No rewards have been created yet. Click the Create button to add your first reward.
            </EmptyStateText>
          )}
        </EmptyStateWrapper>
      )}

      <PaginationWrapper>
        <CurrentPageInfo>
          {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, loyaltyRedeemables.length)} of{' '}
          {loyaltyRedeemables.length}
        </CurrentPageInfo>

        <Pagination
          count={Math.ceil(loyaltyRedeemables.length / rowsPerPage)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          shape='rounded'
          color='primary'
          size='small'
        />

        <RowsPerPageWrapper>
          <PaginationText>Results per page:</PaginationText>
          <PaginationSelect
            value={rowsPerPage}
            onChange={(event) => {
              setCurrentPage(1) // Reset to first page
              setRowsPerPage(Number(event.target.value))
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </PaginationSelect>
        </RowsPerPageWrapper>
      </PaginationWrapper>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left', // Aligns the left side of the button
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right', // Aligns the menu's right side to the button's left side
        }}
        PaperProps={{
          style: {
            width: '100px',
            borderRadius: '8px',
            boxShadow: '0px 4px 60px 0px rgba(0, 0, 0, 0.20)',
          },
        }}
      >
        <StyledMenuItem
          onClick={() => {
            setCurrentReward({
              name: selectedReward?.attributes?.name || '',
              discountType: selectedReward?.attributes?.discount_type || '',
              pointValue: selectedReward?.attributes?.cost || '',
              discount: selectedReward?.attributes?.discount || '0',
            })
            setModalOpen(true)
            handleCloseMenu()
          }}
        >
          Edit
        </StyledMenuItem>
        <StyledMenuItem onClick={handleDeleteClick}>Delete</StyledMenuItem>
      </Menu>

      <AlertModal
        isOpen={isConfirmationModalOpen}
        title='Delete Reward'
        message='Are you sure you want to delete this reward?'
        buttonTitle='Delete'
        buttonAction={handleConfirmDelete}
        onClose={handleCancelDelete}
        showInfoIcon={false}
      />

      <UpsertRewardModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={handleCreateOrUpdateReward}
        initialData={currentReward || undefined}
      />
    </CardBox>
  )
}

export default LoyaltyRewards
