import leftArrowIcon from 'assets/icons/left-arrow.svg'
import { useGetLocations, useGetOrderPlatform, useRoutePlatformId } from 'hooks'
import { Link, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { partnerQuery } from 'state/atoms'
import { getHealthURL, getMenuManagementPlatformURL, getMenuManagementURL } from 'utils'

import * as Styled from './styles'

function Header() {
  const { pathname } = useLocation()
  const { defaultLocation } = useGetLocations()
  const locationId = defaultLocation?.id !== null ? String(defaultLocation?.id) : ''
  const platformId = useRoutePlatformId()
  const partner = useRecoilValue(partnerQuery(locationId))
  const { data: platformResponse } = useGetOrderPlatform(platformId)
  const partnerAttrs = platformResponse?.included?.find(({ type }) => type === 'partner')
  const platformLocationId = partnerAttrs?.id
  const locationName = partnerAttrs?.attributes.name
  const setPageTitle = (pathname: string) => {
    let title

    if (pathname === getHealthURL()) {
      title = 'Health Dashboard'
    } else if (pathname === '/wow') {
      title = 'Week Over Week'
    } else if (locationId || pathname === '/add-location') {
      if (pathname.includes('/preview') || pathname.includes('/menus')) {
        title = (
          <Link to={getMenuManagementURL(locationId)}>
            <Styled.LocationsBackContainer>
              <Styled.LeftArrowImg src={leftArrowIcon} alt='left arrow' />
              <Styled.LocationHeaderText>{`Location: ${partner?.data.attributes.name}`}</Styled.LocationHeaderText>
            </Styled.LocationsBackContainer>
          </Link>
        )
      }
    } else if (platformId) {
      title = (
        <Link to={getMenuManagementPlatformURL(locationId, platformLocationId)}>
          <Styled.LocationsBackContainer>
            <Styled.LeftArrowImg src={leftArrowIcon} alt='left arrow' />
            <Styled.LocationHeaderText>Manage Location : {locationName}</Styled.LocationHeaderText>
          </Styled.LocationsBackContainer>
        </Link>
      )
    } else {
      title = pathname.split('/').slice(-1)[0]?.replace(/[/-]/g, ' ').trim().toUpperCase()
    }
    return title
  }

  return (
    <Styled.Container>
      <Styled.Title>{setPageTitle(pathname)}</Styled.Title>

      {/* <Styled.ToolBar>
        <ChowlyHelpButton />
        <UserMenu />
      </Styled.ToolBar> */}
    </Styled.Container>
  )
}

export default Header
