import InboxIcon from '@mui/icons-material/Inbox'
import { Typography, IconButton } from '@mui/material'
import React from 'react'
import { startCase, camelCase } from 'lodash';
import { StyledChevronLeftIcon } from '../ConversationsList/ConversationChat/styles'

import {
  CustomerInfoContainer,
  CustomerInfoList,
  InfoLabel,
  InfoValue,
  CustomerInfoRow,
  CenteredBox,
  StyledHeading,
  MobileBackBox,
} from './styles';

interface CustomerInfoSectionProps {
  customerInfo: {
    _id: string;
    email: string;
    name: string;
    phone?: string;
    marketingOptIn?: boolean;
    frequency?: string;
    lastVisit?: string;
    avgSatisfaction?: string;
  } | null;
  onBack: () => void;
}

const CustomerInfoSection: React.FC<CustomerInfoSectionProps> = ({ customerInfo, onBack }) => {

  const infoToDisplay = customerInfo ? Object.entries(customerInfo).reduce((acc, [key, value]) => {
    if (key !== '_id') {
      acc[startCase(camelCase(key))] = value || 'N/A';
    }
    return acc;
  }, {} as Record<string, any>) : {};

  return (
    <>
      <StyledHeading variant='h6'>
        <MobileBackBox>
          <IconButton size="small" onClick={onBack}>
            <StyledChevronLeftIcon />
          </IconButton>
        </MobileBackBox>
        Customer Info
      </StyledHeading>
      <CustomerInfoContainer>
        {Object.entries(infoToDisplay).length ? (
          <CustomerInfoList>
            {Object.entries(infoToDisplay).map(([label, value], index) => (
              <CustomerInfoRow key={index}>
                <InfoLabel>{label}</InfoLabel>
                <InfoValue>{value}</InfoValue>
              </CustomerInfoRow>
            ))}
          </CustomerInfoList>
        ) : (
          <CenteredBox>
            <InboxIcon sx={{ fontSize: 40, marginBottom: 1 }} />
            <Typography variant='body1'>No conversation selected</Typography>
          </CenteredBox>
        )}
      </CustomerInfoContainer>
    </>
  )
}

export default CustomerInfoSection;
