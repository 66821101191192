import { PromoCodeAttributes } from 'api/models'

type CouponType = {
  label: string
  value: string
}

export type CouponTypeKeys = 'FIXED_DISCOUNT'

export type CouponTypesMap = {
  [key in CouponTypeKeys]: CouponType
}

export const couponValues: CouponTypesMap = {
  FIXED_DISCOUNT: { label: 'Fixed discount', value: 'fixed_amount' },
  // PERCENTAGE_OFF: { label: 'Percentage off', value: 'percentage' },
  // FREE_ITEM: { label: 'Free item', value: 'free_item' },
}

export type CouponTypeValues = CouponTypesMap[keyof CouponTypesMap]['value']

export type PromoCode = PromoCodeAttributes & {
  created?: Date | string
  redeemed?: number
  applies_to?: string // TODO: Review this
  conditions?: string
}

export type Meta = {
  limit: string
  page: number
  page_total: number
  total: number
}

export type ModalConfig = {
  title: (promoCodeId: number) => string
  description: string
  submitText: string
  action: (promoCodeId: number) => Promise<void>
}
