export const getLoginURL = () => '/login'
export const getLogoutURL = () => '/logout'
export const getVerifyURL = () => '/verify'
export const getRegisterURL = () => '/register'
export const getConfirmationURL = () => '/confirmation'
export const getDoordashSuccessURL = () => '/doordash_success'
export const getUberEatsAuthLinkCheckURL = () => '/ubereats_auth_link_check'
export const getHealthURL = () => '/'
export const getAnalyticsURL = () => '/analytics'
export const getAnalyticsLocationsURL = () => '/analytics/locations'
export const getAnalyticsSalesURL = () => '/analytics/sales'
export const getAnalyticsOrdersURL = () => '/analytics/orders'
export const getAnalyticsProductMixURL = () => '/analytics/product-mix'
export const getAnalyticsReconciliationURL = () => '/analytics/reconciliation'
export const getAnalyticsDisputesURL = () => '/analytics/disputes'
export const getAnalyticsSmartPricingURL = () => '/analytics/smart-pricing'
export const getAnalyticsWowURL = () => '/analytics/wow'
export const getAnalyticsDeliveryBuyDownURL = () => '/analytics/delivery-buy-down'
export const getAnalyticsDMReportingURL = () => '/analytics/dm-reporting'
export const getAnalyticsPlatformStatsURL = () => '/analytics/platform-stats'
export const getBillingURL = () => '/billing'
export const getOrderManagementURL = () => '/order-management'
export const getAddLocationURL = () => '/add-location'
export const getDigitalMarketingURL = () => '/digital-marketing'
export const getLoyaltyURL = () => '/digital-marketing/loyalty-setup'
export const getPromoCodesURL = () => '/digital-marketing/promo-codes'
export const getPromoCodesNewURL = () => '/digital-marketing/promo-codes/new'
export const getCustomerSurveysURL = () => '/customer-surveys'
export const getDefaultMenuManagementURL = () => '/menu-management'
export const getMenuManagementURL = (locationId: string) => `/menu-management/${locationId}`
export const getMenuManagementGBPProfileSelectorURL = (locationId: string) =>
  `/menu-management/${locationId}/gbp/profile-selector`
export const getMenuManagementPlatformURL = (locationId: string, platformId?: string) =>
  `/menu-management/${locationId}/platform/${platformId}`
export const getMenuManagementPreviewURL = (locationId: string, menuSectionId?: string) =>
  `/menu-management/${locationId}/menus/${menuSectionId}/preview`
export const getMenuManagementEditURL = (locationId: string, menuSectionId?: string) =>
  `/menu-management/${locationId}/menus/${menuSectionId}/edit`
export const getMenuManagementNewURL = (locationId: string) =>
  `/menu-management/${locationId}/menus/new`
