import { getPlatformsForPartner } from 'api'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { PlatformName, PlatformSummary } from 'types'

import { orderPlatformConnectedStatus } from '../state/atoms'
import { useGetLocations } from 'hooks'

const useGetPlatformsOnboarding = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [platforms, setPlatforms] = useState<PlatformSummary[]>()
  const [filterDoordashPlatforms, setFilterDoordashPlatforms] = useState<PlatformSummary[]>([])
  const [filterUberPlatforms, setFilterUberPlatforms] = useState<PlatformSummary[]>([])
  const [existingUberPlatform, setExistingUberPlatform] = useState(false)
  const [existingDoordashPlatform, setExistingDoordashPlatform] = useState(false)
  const orderPlatformConnectedStatusState = useRecoilValue(orderPlatformConnectedStatus)
  const { defaultLocation } = useGetLocations()
  const locationId = defaultLocation !== null ? String(defaultLocation?.id) : ''

  const fetch = async () => {
    try {
      if (!locationId) {
        return
      }
      setIsLoading(true)
      const menuPlatforms = await getPlatformsForPartner(locationId, true)
      setPlatforms(menuPlatforms)
      const doordashPlatforms =
        menuPlatforms?.filter((item) => {
          return item.internalName === PlatformName.DOORDASH && item.aasmState !== 'disabled'
        }) || []
      setFilterDoordashPlatforms(doordashPlatforms)
      setExistingDoordashPlatform(doordashPlatforms.length > 0)

      const uberPlatforms =
        menuPlatforms?.filter((item) => {
          return item.internalName === PlatformName.UBEREATS && item.aasmState !== 'disabled'
        }) || []
      setFilterUberPlatforms(uberPlatforms)

      setExistingUberPlatform(uberPlatforms.length > 0)
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [locationId, orderPlatformConnectedStatusState])

  return {
    isLoading,
    platforms,
    filterDoordashPlatforms,
    filterUberPlatforms,
    existingUberPlatform,
    existingDoordashPlatform,
    reload: fetch,
  }
}

export default useGetPlatformsOnboarding
