import {
  Button,
  Chip,
  IconButton,
  MenuItem,
  TableCell as MuiTableCell,
  Tab,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import styled from 'styled-components'
import { breakpoints, colors, fontFamily, fontWeight } from 'theme'

import { OrderDisplayStatus } from './OrderManagement'

const getStatusStyles = (status: OrderDisplayStatus) => {
  switch (status) {
    case OrderDisplayStatus.COMPLETED:
      return {
        backgroundColor: 'rgba(50, 150, 93, 0.1)',
        color: '#32965D',
      }
    case OrderDisplayStatus.CANCELLED:
      return {
        backgroundColor: 'rgba(193, 48, 48, 0.1)',
        color: '#C13030',
      }
    default:
      return {
        backgroundColor: 'transparent',
        color: '#000',
      }
  }
}

const commonFontStyle = {
  fontFamily: fontFamily.inter,
  fontSize: '13px',
}

const cellPadding = {
  padding: '10px 16px',
}

const hideOnMobile = {
  [`@media ${breakpoints.xs_max}`]: {
    '&&': {
      display: 'none',
    },
  },
}

const hideOnDesktop = {
  [`@media ${breakpoints.xs_max}`]: {
    '&&': {
      display: 'none',
    },
  },
}

const showOnMobile = {
  [`@media ${breakpoints.xs_max}`]: {
    '&&': {
      display: 'table-cell',
    },
  },
}

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '1280px',
  rowGap: '1rem',
  paddingBottom: 'calc(2vh + 24px)',
})

export const StyledTableRow = styled(TableRow)({
  borderTop: '1px solid #ddd',
  borderBottom: '1px solid #ddd',
})

export const StyledTab = styled(Tab)<{ active?: boolean }>(({ active }) => ({
  '&&': {
    ...commonFontStyle,
    fontSize: '13px',
    fontWeight: fontWeight.semibold,
    color: `${colors.blue.DEFAULT} !important`,
    backgroundColor: active ? colors.blue[400] : 'transparent',
    borderRadius: active ? '4px' : '0',
    textTransform: 'none',
    padding: '8px',
    minHeight: 'auto',
    minWidth: 'auto',
  },
}))

export const StyledChip = styled(Chip)<{
  status: OrderDisplayStatus
  shouldHideOnDesktop?: boolean
}>(({ status, shouldHideOnDesktop }) => ({
  '&&': {
    ...commonFontStyle,
    fontSize: '10px',
    fontWeight: fontWeight.medium,
    borderRadius: '5px',
    padding: 0,
    height: '23px',
    backgroundColor: getStatusStyles(status).backgroundColor,
    color: getStatusStyles(status).color,
    textTransform: 'capitalize',
    ...(shouldHideOnDesktop && hideOnDesktop), // Correctly references the prop
  },
  '& .MuiChip-icon': {
    color: `${getStatusStyles(status).color} !important`,
    marginLeft: 0,
    marginRight: 0,
  },
}))

export const CustomTableCell = styled(MuiTableCell)({
  '&&': {
    ...commonFontStyle,
    fontSize: '11px',
    fontWeight: fontWeight.medium,
    ...cellPadding,
    color: '#777777',
  },
  ...hideOnMobile,
})

export const CustomLink = styled.a({
  color: '#1B51A4',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
})

export const TableCell = styled(MuiTableCell)({
  '&&': {
    ...commonFontStyle,
    fontWeight: fontWeight.medium,
    ...cellPadding,
    color: '#000',
  },
  ...hideOnMobile,
})

export const MobileVisibleTableCell = styled(TableCell)({
  ...showOnMobile,
})

export const MobileVisibleTableHeader = styled(MuiTableCell)({
  '&&': {
    ...commonFontStyle,
    fontSize: '11px',
    fontWeight: fontWeight.medium,
    ...cellPadding,
    color: '#777777',
  },
  ...showOnMobile,
})

export const RowPair = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
})

export const RowSingle = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 0',
})

export const SubmissionTime = styled(Typography)({
  '&&': {
    ...commonFontStyle,
    fontWeight: fontWeight.medium,
    color: '#000',
  },
})

export const SubmissionDate = styled(Typography)({
  '&&': {
    ...commonFontStyle,
    color: '#777777',
  },
})

export const Address = styled(Typography)({
  '&&': {
    ...commonFontStyle,
    fontWeight: fontWeight.medium,
    color: '#000',
  },
})

export const MenuItems = styled(MenuItem)({
  '&&': {
    ...commonFontStyle,
  },
})

export const EmptyAddress = styled(Typography)({
  '&&': {
    ...commonFontStyle,
    color: '#777',
  },
})

export const Location = styled(Typography)({
  '&&': {
    ...commonFontStyle,
    color: '#777777',
  },
})

export const CenteredIconButton = styled(IconButton)({
  '&&': {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
})

export const CenterTableCell = styled(TableCell)({
  '&&': {
    textAlign: 'center',
  },
})

export const PaginationContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '1rem',
})

export const ModalTitle = styled(Typography)({
  '&&': {
    fontSize: '20px',
    fontWeight: fontWeight.semibold,
    fontFamily: fontFamily.default,
    color: colors.black,
  },
})

export const ModalText = styled(Typography)({
  '&&': {
    fontSize: '15px',
    fontFamily: fontFamily.default,
    marginBottom: '0px',
    color: colors.black,
  },
})

export const ModalActions = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
})

export const ModalContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  gap: '16px',
})

export const ModalButton = styled(Button)<{ customVariant?: 'primary' | 'secondary' }>(
  ({ customVariant }) => ({
    '&&': {
      padding: '10px 16px',
      marginLeft: '10px',
      fontSize: '14px',
      fontWeight: fontWeight.medium,
      fontFamily: fontFamily.default,
      borderRadius: '4px',
      textTransform: 'none',
      backgroundColor: customVariant === 'primary' ? colors.primary.DEFAULT : colors.gray.medium,
      color: customVariant === 'primary' ? colors.white : colors.black,
      '&:hover': {
        backgroundColor: customVariant === 'primary' ? colors.primary.light : colors.gray.dark,
      },
    },
  }),
)

export const LocationText = styled.div({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.medium,
  fontSize: '14px',
  color: colors.black,
  cursor: 'pointer',
})

export const CustomTooltip = styled(Tooltip)({
  '& .MuiTooltip-tooltip': {
    maxWidth: '250px',
    fontSize: '14px',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    padding: '10px',
  },
})
