import { EmailMarketingCampaign } from 'api'

import { FacebookCampaignInfo, OptimizedCampaign } from './campaigns'
import { GenericAd } from './googleAd'
import { TargetableInsight } from './insights'

export type WithPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

/**
 * This type requires that exactly one property from a set of properties is present and non-undefined.
 * If more than one of these properties is present, or if none of them are present, TypeScript will throw an error.
 * Example usage: RequireOnlyOne<MyType, 'prop1' | 'prop2'>
 */
export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>
  }[Keys]

export const enum RadiusLocationUnits {
  Kilometers = 'KILOMETERS',
  Miles = 'MILES',
}

export type AdvancedLocationTargeting = RequireOnlyOne<
  {
    /** if provided, radius cannot be used */
    location: {
      geoTargetConstant: string
      /** if true, the location will be excluded */
      excluded?: boolean
    }
    /** if provided, location cannot be used. */
    radius: {
      distance: number
      unit: 'MILES' | 'KILOMETERS'
      address: {
        streetAddress: string
        countryCode?: string
        postalCode?: string
        cityName?: string
        provinceCode?: string
        provinceName?: string
      }
    }
  },
  'location' | 'radius'
>

export type AdRequestInfo = {
  /** the id of the Ad Request that originated this ad */
  id: string
}

export type ToDoItem = TargetableInsight | OptimizedCampaign | GenericAd | EmailMarketingCampaign

export type TodoItemWithProperties = ToDoItem & { partnerName: string } & { chowlyPartnerId: string } & { organizationId: string }
export type PublishedCampaignItem = FacebookCampaignInfo | GenericAd

export type PublishedCampaignItemWithPartnerName = PublishedCampaignItem & { partnerName: string }

export type NonEmailMarketingToDoItem = Exclude<ToDoItem, EmailMarketingCampaign>

export const enum CommonAdStatuses {
  /** user or admin draft */
  Draft = 'draft',
  /** the ad has been published to the marketing channel (Google or facebook) */
  Submitted = 'submitted',
  Live = 'live',
  Paused = 'paused',
  /** the ad exhausted all publishing attempts */
  Failed = 'failed',
  Archived = 'archived',
  WithIssues = 'with-issues',
}

export const enum DigitalMarketingTypes {
  Google = 'google',
  Facebook = 'facebook',
  Mailchimp = 'mailchimp',
}
