import { sigmaPages } from 'config'
import { useEmbedUrl } from 'hooks'

import * as Styled from './styles'

type Props = {
  partnerId?: string
}

const SalesOrderReport = ({ partnerId }: Props) => {
  const url = useEmbedUrl(sigmaPages.salesOrderByPlatform, partnerId)

  return (
    <Styled.SalesOrderReportContainer>
      <Styled.SalesOrderReportFrame src={url} title='Sales Order by Platform' />
    </Styled.SalesOrderReportContainer>
  )
}

export default SalesOrderReport
