import { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import chowlyApiClient from 'api/chowly';
import { chowlyApi, pusherMessaging } from 'config';
import { AnalyticEvent, Conversation, ConversationCreated, ConversationEvent } from 'api/ovation/models';

const usePusherMessages = (channelName: string, locationId: string, events: Array<string>) => {
  const [messages, setMessages] = useState<ConversationEvent[]>([]);
  const [newMessage, setNewMessage] = useState<ConversationEvent | null>(null);
  const [newConversation, setNewConversation] = useState<Conversation | null>(null);
  
  useEffect(() => {
    if (!locationId) {
      return;
    }
    // Initialize Pusher
    const pusher = new Pusher(pusherMessaging.apiKey, {
          cluster: pusherMessaging.cluster,
          channelAuthorization: {
            headers: {
              Authorization: chowlyApiClient.defaults.headers.common['Authorization']
            },
            params: {
              partner_id: locationId
            },
            transport: "ajax",
            endpoint: chowlyApi.hostUrl + chowlyApi.pusherAuth}
    });

    // Subscribe to the channel
    const channel = pusher.subscribe(channelName);
    events.forEach((event) => {
    // Listen for messages / conversations
    channel.bind(event, (data: AnalyticEvent | ConversationCreated) => {
      if ('analytic_event' in data.data) {
        // Handle AnalyticEvent
        const analyticEventData = data.data as { analytic_event: ConversationEvent };
        if (analyticEventData.analytic_event.type === 'inbound_message') {
          setNewMessage(analyticEventData.analytic_event);
          setMessages((prevMessages) => [...prevMessages, analyticEventData.analytic_event]);
        }
        // Handle New Conversation
      } else if ('conversation' in data.data) {
        const conversationData = data.data as { conversation: Conversation };
        setNewConversation(conversationData.conversation);
      }
    });
  });

    // Cleanup on unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, [channelName, locationId]);

  return { messages, newMessage, newConversation };
};

export default usePusherMessages;