import styled from 'styled-components'
import Chevron_Down from '../../assets/icons/Chevron-Down.svg'
import SearchIcon from '../../assets/icons/search.svg'
import ClearIcon from '../../assets/icons/clear-icon.svg'

export const Container = styled.div`
  position: relative;  
  width: 100%;
  box-sizing: border-box;
`

export const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
  position: relative;
`

export const SelectWrapper = styled.div<{ isActive: boolean }>`
  width: 100%;
  height: 32px;
  padding: 6px 12px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${(props) =>
    props.isActive ? 'var(--Global-Core-Primary, #1B51A4)' : 'var(--Global-Interaction-Inactive, #D7D7D7)'};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
`

export const SelectBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
`

export const TextWrapper = styled.div`
  display: inline-flex;
  align-items: left;
  justify-content: flex-start;
  white-space: nowrap;
  max-width: 100%;
`

export const Input = styled.input`
  width: 65px;
  height: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  background-color: transparent;
  text-align: left;
  align-self: center;
`

export const InputBoldText = styled.div`
  font-size: 14px;
  background-color: transparent;
  text-align: left;
  font-weight: 600;
  padding-right: 24px;
  margin-left: 4px;  /* Slight space between regular and bold text */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
`

export const DownArrow = styled.div<{ $isExpanded: boolean }>`
  width: 14px;
  height: 14px;
  
  background-image: url(${Chevron_Down});
  background-size: contain;
  transform: rotate(${props => (props.$isExpanded ? '180deg' : '0deg')});
  transition: transform 0.3s ease-in-out;
  position: absolute;
  right: 10px; /* Align to the right side of the input */
  top: 50%;
  transform: translateY(-50%) rotate(${props => (props.$isExpanded ? '180deg' : '0deg')});
`

export const DropdownResults = styled.div`
  width: 100%;
  max-height: 200px;
  background-color: #FEFEFE;
  box-sizing: border-box;
`

export const FilterContainer = styled.div`
  width: 100%;
  height: 45px;
  padding: 8px;
`

export const FilterBox = styled.input`
  position: relative;
  width: 100%;
  height: 33px;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--Global-Core-Primary, #1B51A4);
  color: #292929;
  font-size: 11px;
  margin-bottom: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
  box-sizing: border-box; /* Ensure padding doesn't affect overall size */

  background-image: url(${SearchIcon});
  background-position: 12px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;

  ::placeholder {
    position: absolute;
    color: #484848;
    text-align: left;
    font-size: 11px;
    top: 50%;
    left: 32px; /* Align placeholder text to the right of the icon */
    transform: translateY(-50%);
  }
    
  &:not(:placeholder-shown) {
    background-image: none;
  }
    
  &:focus {
    border: 0;
  }
`

export const ClearButton = styled.img.attrs({
  role: 'button',
  src: ClearIcon,
  alt: 'Clear search'
})`
  position: absolute;
  right: 16px;
  top: 56px;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  cursor: pointer;
  z-index: 1;
`

export const ResultsWrapper = styled.div`
  width: 100%;
  max-height: 160px;
  overflow-y: auto;
  padding-right: 4px;
  background: inherit;
`

export const Result = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 32px;
  padding: 6px;
  gap: 8px;
  cursor: pointer;
  background-color: ${props => (props.isActive ? '#1B51A41A' : 'white')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: var(--Global-Core-Hover, #0000001A);
  }
  border-radius: 5px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #292929;
`

export const NoResults = styled.div`
  padding: 10px;
  text-align: center;
  color: #888;
`

export const SpinnerWrapper = styled.div`
  padding: 7px 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover: {
    background: rgba(0, 0, 0, 0.10);
  },
  &.active: {
    background: rgba(27, 81, 164, 0.10);
  },
`