import { generatePath } from 'react-router'
import { chowlyApi } from 'config'
import client from '../chowly'
import {
  PromoCodeAttributes,
  PromoCodeInput,
  PromoCodeResponse, 
  PromoCodesResponse,
  PromoCodeStatusAttribute,
  PromoCodeStatusInput,
  TypeName,
} from '../models'

// GET /api/v3/organizations/:organizationId/discount_codes
export const getPromoCodes = async ({
  organizationId,
  link = null,
  limit = 15,
}:{
  organizationId: string
  link?: string | null
  limit: number
}) => {
  const page = 1
  const endpoint = link || generatePath(chowlyApi.promoCodes, { organizationId })
  const { data } = await client.get<PromoCodesResponse>(endpoint, {
    params: !link ? { organizationId, page, limit } : undefined,
  })
  return data
}

// POST /api/v3/organizations/:organizationId/discount_codes
export const createPromoCode = async (
  organizationId: string,
  payload: PromoCodeInput
) => {
  const url = generatePath(chowlyApi.promoCodes, { organizationId })
  const { data } = await client.post<PromoCodeResponse>(url, payload)
  return data
}

// PATCH /api/v3/organizations/:organizationId/discount_codes/:id
export const updatePromoCodeStatus = async (
  organizationId: string,
  id: string,
  payload: PromoCodeStatusInput
) => {
  const url = generatePath(chowlyApi.promoCode, { organizationId, id })
  const { data } = await client.patch<PromoCodeResponse>(url, payload)
  return data
}

export const formatPromoCodePayload = (attributes: PromoCodeAttributes): PromoCodeInput => {
  return {
    data: {
      type: TypeName.DiscountCode,
      attributes,
    },
  }
}

export const formatUpdatePromoCodeStatusPayload = (attributes: PromoCodeStatusAttribute): PromoCodeStatusInput => {
  return {
    data: {
      type: TypeName.DiscountCode,
      attributes,
    },
  }
}