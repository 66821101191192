import GreenButton from 'components/modern/GreenButton'
import RedButton from 'components/modern/RedButton'
import RightDrawer from 'components/RightDrawer/RightDrawer'
import { getToDoTitle } from 'features/digital-marketing/ToDo/components'
import useSearchAlgoliaIndex from 'hooks/useAlgoliaSearch'
import useSafeAsync from 'hooks/useSafeAsync'
import { useCallback, useEffect, useState } from 'react'
import { FacebookCampaignInfo, InsightTypes, TargetableInsight } from 'types/digitalMarketing'
import { CommonAdStatuses, DigitalMarketingTypes, PublishedCampaignItem, ToDoItem, TodoItemWithProperties, NonEmailMarketingToDoItem } from 'types/digitalMarketing/common'
import { GenericAd } from 'types/digitalMarketing/googleAd'
import { ButtonLoadingIcon } from 'features/digital-marketing/ToDo/FeedbackModal/styles'

import FacebookAdDetails from './FacebookAdDetails'
import GoogleAdDetails from './GoogleAdDetails'
import LiveMarketingUpdateDetails from './LiveMarketingUpdateDetails'
import { ActionsButtons, AdPreviewLabel } from './styles'

interface DigitalMarketingDetailsDrawerProps {
  open: boolean
  item: ToDoItem | PublishedCampaignItem | null
  partnerId: string
  onClose: () => void
  onAction?: (item: TodoItemWithProperties, approved: boolean) => void
  onPauseResume?: (item: PublishedCampaignItem, paused: boolean) => void
  disableActionButtons?: boolean
}

const DigitalMarketingDetailsDrawer: React.FC<DigitalMarketingDetailsDrawerProps> = ({
  open,
  item,
  partnerId,
  onClose,
  onAction,
  onPauseResume,
  disableActionButtons,
}) => {
  const [storeLoading, setStoreLoading] = useState(false)
  const [associatedStore, setAssociatedStore] = useState<any>(null)
  const { search, isIndexReady } = useSearchAlgoliaIndex('Stores', partnerId)
  const safeAsync = useSafeAsync()

  const fetchAssociatedStore = useCallback(async () => {
    if (!(item as NonEmailMarketingToDoItem)?.chowlyPartnerId) return
    if (!isIndexReady) return
    setStoreLoading(true)

    const result = await search('', {
      hitsPerPage: 10,
      filters: `NOT deletedOn:*`,
      attributesToHighlight: [],
      facetFilters: [`chowlyPartnerId:${(item as NonEmailMarketingToDoItem)?.chowlyPartnerId}`],
    })
    const stores = result?.hits
    setAssociatedStore(stores?.[0])
    setStoreLoading(false)
  }, [(item as NonEmailMarketingToDoItem)?.chowlyPartnerId, search])

  // Fetch the associated store when partnerId is updated
  useEffect(() => {
    safeAsync(fetchAssociatedStore()).catch((e) => {
      console.error(e)
      setStoreLoading(false)
    })
  }, [(item as NonEmailMarketingToDoItem)?.chowlyPartnerId, safeAsync, fetchAssociatedStore])

  return (
    <RightDrawer
      isOpen={open}
      onClose={onClose}
      title={getToDoTitle(item as ToDoItem)}
      hideBackdrop
    >
      <AdPreviewLabel variant='caption'>Ad Preview</AdPreviewLabel>
      {(!(item as NonEmailMarketingToDoItem)?.type || (item as NonEmailMarketingToDoItem)?.type as DigitalMarketingTypes === DigitalMarketingTypes.Facebook) && !storeLoading ? (
        <FacebookAdDetails
          campaign={(item as FacebookCampaignInfo) || {}}
          associatedStore={associatedStore}
        />
      ) : null}
      {(item as NonEmailMarketingToDoItem)?.type as DigitalMarketingTypes === DigitalMarketingTypes.Google ? (
        <GoogleAdDetails campaign={item as GenericAd} partnerId={partnerId} />
      ) : null}
      {(item as NonEmailMarketingToDoItem)?.type as InsightTypes === InsightTypes.CampaignUpdate ? (
        <LiveMarketingUpdateDetails insight={item as TargetableInsight} partnerId={partnerId} />
      ) : null}

      <ActionsButtons data-type='actions-buttons'>
        {![
          CommonAdStatuses.Submitted,
          CommonAdStatuses.Live,
          CommonAdStatuses.Paused,
          CommonAdStatuses.Failed,
          CommonAdStatuses.WithIssues
        ].includes(
          (item as NonEmailMarketingToDoItem)?.status as CommonAdStatuses,
        ) && !(item as GenericAd)?.withIssues ? (
          <>
            <GreenButton
              disabled={disableActionButtons}              
              onClick={() => onAction && onAction(item as TodoItemWithProperties, true)}>
              Approve
            </GreenButton>
            <RedButton
              disabled={disableActionButtons}
              variant='outlined'
              onClick={() => onAction && onAction(item as TodoItemWithProperties, false)}
            >
              Reject
            </RedButton>
          </>
        ) : (
          <>
          {((item as NonEmailMarketingToDoItem)?.status as CommonAdStatuses) !== CommonAdStatuses.Failed && (
            <>
              {((item as NonEmailMarketingToDoItem)?.status as CommonAdStatuses) === CommonAdStatuses.Paused ? (
                <GreenButton
                  disabled={disableActionButtons}
                  variant='outlined'
                  onClick={() => onPauseResume && onPauseResume(item as PublishedCampaignItem, false)}
                  endIcon={<ButtonLoadingIcon size={16} loading={disableActionButtons}/>}
                >
                  Resume
                </GreenButton>
              ) : (
                <RedButton
                  disabled={disableActionButtons}
                  variant='outlined'
                  onClick={() => onPauseResume && onPauseResume(item as PublishedCampaignItem, true)}
                  endIcon={<ButtonLoadingIcon size={16} loading={disableActionButtons}/>}
                >
                  Pause
                </RedButton>
              )}
            </>
          )}
          </>
        )}
      </ActionsButtons>
    </RightDrawer>
  )
}

export default DigitalMarketingDetailsDrawer
