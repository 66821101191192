import { sigmaPages } from 'config'
import { useEmbedUrl } from 'hooks'

import { ReportContainer, ReportFrame } from './styles'

type Props = {
  partnerId?: string
}

const RepeatCustomersAddedReport = ({ partnerId }: Props) => {
  const url = useEmbedUrl(sigmaPages.repeatCustomersReport, partnerId)

  return (
    <ReportContainer>
      <ReportFrame src={url} title='Repeat Customers Added' />
    </ReportContainer>
  )
}

export default RepeatCustomersAddedReport
