import { CSSObject, css, keyframes } from 'styled-components'

export const mobileWidthMax = 768

export const breakpoints = {
  xxs: '(min-width: 320px)',
  xs_max: '(max-width: 375px)',
  xs: '(min-width: 375px)', // mobile
  sm: '(min-width: 768px)', // tablet
  md: '(min-width: 900px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  xxl: '(min-width: 1536px)',
}

export const elevation = {
  backdrop: 20,
}

export const backdrop: CSSObject = {
  backdropFilter: 'blur(8px)',
  backgroundColor: 'rgba(33, 33, 33, 0.2)',
  position: 'fixed',
  zIndex: elevation.backdrop,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

export const borderRadius = {
  DEFAULT: '0.25rem',
  lg: '0.5rem',
  xl: '0.75rem',
  xxl: '1rem',
  xxxl: '1.5rem',
  full: '9999px',
}

export const colors = {
  black: '#000',
  white: '#FAFAFA',
  gray: {
    DEFAULT: '#9e9e9e',
    dark: '#757575',
    darkest: '#424242',
    medium: '#D7D7D7',
    150: '#EBEBEB',
    mediumLight: '#777777',
    180: '#484848',
    200: '#4848480D',
    250: '#4848481A',
    300: '#DADADA',
    400: '#FAFAFA',
    450: '#FEFEFE',
    500: '#ACACAC',
    600: '#484848',
    900: '#101010',
  },
  primary: {
    DEFAULT: '#00509d',
    light: '#003f88',
    dark: '#00296b',
    medium: '#292929',
  },
  secondary: {
    DEFAULT: '#fdc500',
  },
  red: {
    DEFAULT: '#ca054d',
    darkRed: '#C13030',
    500: '#d33f49',
  },
  orange: {
    DEFAULT: '#ff7d00',
  },
  green: {
    DEFAULT: '#56b27e',
    darkGreen: '#32965D',
    500: '#0D874A',
  },
  slate: {
    200: '#e2e8f0',
  },
  blue: {
    DEFAULT: '#003F88',
    40: '#F4F7FE',
    50: '#eff6ff',
    60: '#e1e9f4',
    100: '#dbeafe',
    300: '#ADC7E0',
    400: '#1B51A41A',
    500: '#1B51A433',
    700: '#1B51A4',
    900: '#222144',
  },
  cyan: {
    300: '#5C8FC0',
  },
  yellow: {
    DEFAULT: '#FDC500',
    darkYellow: '#FFBE0B',
  },
  warning: {
    DEFAULT: '#FFEB0B',
  },
}

export const fontFamily = {
  default: "'Inter', sans-serif",
  italic: 'italic',
  inter: 'Inter',
}

export const transition = {
  transitionProperty:
    'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '150ms',
}

export const inset0 = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}

export const shadow = {
  DEFAULT: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
  md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
  xxl: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
  all: '0 0 0px 0 rgb(0 0 0 / 1)',
  inner: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
  modal: '0px 4px 60px 0px rgba(0, 0, 0, 0.10)',
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const pulse = keyframes`
  50% {
    opacity: .5;
  }
`

export const animation = {
  spin: {
    linear: css`
      animation: ${spin} 1s linear infinite;
    `,
    ease: css`
      animation: ${spin} 1s ease infinite;
    `,
  },
  pulse: css`
    animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  `,
}

export const helpers = {
  mdHidden: {
    [`@media ${breakpoints.md}`]: {
      display: 'none',
    },
  },
}

export const typography = {
  xxxs: {
    fontSize: '0.625rem',
    lineHeight: '0.938rem',
  },
  xxs: {
    fontSize: '0.813rem',
    lineHeight: '1.25rem',
  },
  semiXS: {
    fontSize: '0.938rem',
    lineHeight: '1.438rem',
  },
  midXS: {
    fontSize: '0.875rem',
    lineHeight: '1.438rem',
  },
  xs: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  base: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  xl: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
  xxl: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
  xxxl: {
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
  },
  xxxxl: {
    fontSize: '2.25rem',
    lineHeight: '2.5rem',
  },
}

export const fontWeight = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
}

const theme = {
  animation,
  backdrop,
  borderRadius,
  breakpoints,
  colors,
  fontFamily,
  inset0,
  shadow,
  transition,
  helpers,
  fontWeight,
}

export default theme
