import { ReactComponent as DownArrowIcon } from 'assets/icons/Chevron-Down.svg'
// import { relative } from 'path'
import styled from 'styled-components'
import { borderRadius, breakpoints, colors, shadow } from 'theme'

export const Container = styled.div.attrs({ role: 'button' })<{ showDropdown?: boolean }>`
  position: relative;
  display: ${({ showDropdown }) => showDropdown ? 'flex' : 'none'};
  column-gap: 5px;
  align-items: center;
  margin-left: 10px;
`

export const MobileContainer = styled.div<{ showDropdown?: boolean }>`
  display: ${({ showDropdown }) => showDropdown ? 'none' : 'flex'};
  align-items: center;
  margin: 8px 0;
`

export const MobileDropdownWrap = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  borderRadius: borderRadius.lg,
  borderWidth: 1,
  borderColor: colors.blue[700],
  background: colors.blue[700],
  margin: '0 12px 0 12px',
  fontSize: 13,
})

export const MobileDropdownWrapCollapsed = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  borderRadius: borderRadius.lg,
  borderWidth: 1,
  borderColor: colors.blue[700],
  background: colors.blue[700],
  margin: '12px',
  fontSize: 13,
})

// TO-DO: during cool-down expand commonly used rem sizing for to be reused and move to theme file
const remSize = {
  primary: '2rem',
  secondary: '3rem',
}

export const DropdownWrap = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  borderRadius: borderRadius.lg,
  borderWidth: 1,
  borderColor: colors.blue[700],
  background: colors.blue[700],
  margin: '0 0 0 24px',
  fontSize: 13,
  [`@media ${breakpoints.sm}`]: {
    margin: 0,
    padding: 0,
  },
})

export const Initials = styled.div({
  color: colors.white,
  marginRight: 0,
  textAlign: 'center',
  [`@media ${breakpoints.sm}`]: {
    marginRight: 0,
  },
})

export const Name = styled.div({
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '1.5rem',
  color: '#484848',
})

export const RoundButton = styled.button({
  width: remSize.primary,
  height: remSize.primary,
  overflow: 'hidden',
  zIndex: 10,
  borderRadius: borderRadius.full,
  boxShadow: shadow.lg,
  display: 'none',
  '&:focus': {
    outline: '2px solid transparent',
    outlineOffset: 2,
  },
  [`@media ${breakpoints.sm}`]: {
    width: 0,
    height: remSize.secondary,
    display: 'block',
  },
})

export const Avatar = styled.img({
  width: remSize.primary,
  height: remSize.primary,
  [`@media ${breakpoints.sm}`]: {
    width: remSize.secondary,
    height: remSize.secondary,
  },
})

export const Wrapper = styled.div({})

export const DropdownList = styled.div({
  position: 'absolute',
  width: '16rem',
  top: '4rem',
  right: '0.5rem',
  padding: '0.5rem',
  color: colors.black,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: '700',
  textAlign: 'left',
  backgroundColor: '#fefefe',
  borderRadius: borderRadius.xl,
  boxShadow: shadow.DEFAULT,
  '&:hover': {
    cursor: 'default',
  },
})

export const Email = styled.h4({
  display: 'block',
  padding: '1rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  borderColor: colors.white,
  borderBottomWidth: 1,
})

export const TextButton = styled.button({
  display: 'block',
  padding: '1rem',
  width: '100%',
  textAlign: 'left',
})

export const TextLink = styled.button({
  display: 'block',
  padding: '1rem',
  width: '100%',
  textAlign: 'left',
  color: '#C13030',
})

export const DownArrow = styled(DownArrowIcon)<{ $isExpanded: boolean }>(
  {
    width: '12px',
    height: '12px',
    transform: 'rotate(0deg)',
    transition: 'transform .1s ease-in',
  },
  ({ $isExpanded }) => $isExpanded && { transform: 'rotate(-180deg)' },
)

export const CollapsedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 8px;
`
