import styled from '@emotion/styled'
import { Dialog } from '@mui/material'
import PrimaryButton from 'components/modern/PrimaryButton'

export type BasicHolidayHourConfig = {
  date: string
  closed: boolean
  timePeriod?: { startTime: string; endTime: string }
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 8px;
  background: #fefefe;
`

export const BaseModal = styled(Dialog)`
  & .MuiBackdrop-root {
    background-color: #fafafacc;
  }

  & .MuiDialog-paper {
    max-width: 667px;
    border-radius: 8px;
    width: 100%;
    padding: 24px;
  }
`

export const Title = styled.h1`
  color: #292929;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`

export const ItemWrapper = styled.div`
  border-bottom: 1px solid #d7d7d7;
  width: 100%;
  height: 40px;

  &:last-child {
    border-bottom: none;
  }
`

export const ItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`

export const ItemDate = styled.span`
  color: #292929;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`

export const ItemTime = styled.span`
  color: #292929;
  text-align: right;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 118%;
`

export const ItemActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  button {
    fill: currentColor;
  }

  svg,
  path {
    color: inherit;
    fill: inherit;
  }
`

export const LocationPrimaryButton = styled(PrimaryButton)`
  padding: 8px 16px;
`
