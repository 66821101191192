import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Divider, Tooltip } from '@mui/material';
import { truncate } from 'lodash'
import moment from 'moment'
import React from 'react'

import {
  ConversationName,
  ConversationWrapper,
  HeaderBox,
  // IconContainer,
  MessageBox,
  MessageText,
  TimestampBox,
  TimePassedContainer,
} from './styles'
import { formatTimePassed } from 'utils';

interface ConversationItemProps {
  conversation: any
  isSelected: boolean
  onSelect: () => void
}

const ConversationItem: React.FC<ConversationItemProps> = ({
  conversation,
  isSelected,
  onSelect,
}) => {
  const createdMoment = moment(conversation?.created_at)
  const timePassedText = createdMoment.fromNow()
  const timeColor = moment().diff(createdMoment, 'hours') > 5 ? '#F6C343' : '#96ABCA'

  return (
    <>
      <ConversationWrapper $isSelected={isSelected} onClick={onSelect}>
        <HeaderBox>
          <ConversationName>{conversation?.customer?.name}</ConversationName>
          <TimestampBox color={'#96ABCA'}>{formatTimePassed(conversation?.last_communication?.date)}</TimestampBox>
        </HeaderBox>
        <MessageBox>
          <MessageText>{truncate(conversation?.last_communication?.text, { length: 50 })}</MessageText>
          {/* <IconContainer>{icon}</IconContainer> */}
        </MessageBox>
        <TimestampBox color={timeColor}>
          <Tooltip title={`Conversation created: ${createdMoment.format('MMMM Do YYYY, h:mm A')}`}>
            <TimePassedContainer>
              <AccessTimeIcon fontSize="small" />
              <div>{timePassedText}</div>
            </TimePassedContainer>
          </Tooltip>
        </TimestampBox>
      </ConversationWrapper>
      <Divider sx={{ margin: '8px 0' }} />
    </>
  )
}

export default ConversationItem
