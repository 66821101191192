import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, CircularProgress } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

import ConversationItem from '../ConversationItem/ConversationItem';
import { Heading } from '../styles';
import { LoadingBox, OuterContainer, SearchBar, StyledError, StyledScrollContainer, StyledTab, StyledTabs } from './styles';
import { listConversations } from 'api/ovation/conversations';
import { Conversation, ConversationSearchResult } from 'api/ovation/models';
import { useGetLocations, usePusherMessages } from 'hooks';

interface ConversationsListProps {
  setSelectedConversation: React.Dispatch<React.SetStateAction<Conversation | null>>;
  isOnboarded?: boolean | undefined | null;
}

const ConversationsList: React.FC<ConversationsListProps> = ({ setSelectedConversation, isOnboarded }) => {
  const { defaultLocation } = useGetLocations()
  const partnerId = defaultLocation?.id !== null ? String(defaultLocation?.id) : ''
  const [tabValue, setTabValue] = useState<number>(0);
  const [selectedChat, setSelectedChat] = useState<number | null>(null);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [skip, setSkip] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const listRef = useRef<HTMLDivElement>(null);
  const limit = 25;

  // Add Pusher subscription for new conversations
  const { newConversation } = usePusherMessages(
    `private-ovation-${partnerId}`,
    partnerId || '',
    ['conversation_created']
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setIsLoading(false);
    setTabValue(newValue);
  };

  const handleSelectChat = (index: number): void => {
    const conversation = conversations[index];
    setSelectedChat(index);
    setSelectedConversation(conversation);
  };

  const debouncedSearch = debounce((search: string): void => {
    console.log('searchTerm', search);
  }, 300);

   const handleSearchChange = (value: string): void => {
    debouncedSearch(value);
  };

  const resetAndFetch = (): void => {
    setSkip(0);
    setConversations([]);
    setHasMore(true);
    fetchData(0, tabValue);
  };

  const fetchData = async (newSkip: number, filterIndex?: number): Promise<void> => {
    if (isLoading || !partnerId || !isOnboarded) return;
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const params: ConversationSearchResult = {
        partner_ids: [partnerId],
        limit,
        offset: newSkip,
        ...(filterIndex !== 0 ? { resolved: filterIndex === 2 } : {})
      };
      const { data: { conversations: newConversations } } = await listConversations(params);

      setConversations((prev) => {
        const uniqueConversations = newConversations.filter(
          newConv => !prev.some(existingConv => existingConv._id === newConv._id)
        );
        return [...prev, ...uniqueConversations];
      });
      setHasMore(newConversations.length === limit);
    } catch (error) {
      console.log(error);
      setErrorMessage('Error fetching conversations. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = (): void => {
    if (!hasMore || isLoading || !listRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    if (scrollHeight - scrollTop === clientHeight) {
      const newSkip = skip + limit;
      setSkip(newSkip);
      fetchData(newSkip, tabValue);
    }
  };

  useEffect(() => {
    if (newConversation && partnerId) {
      const event = newConversation as unknown as Conversation;
      // Add the new conversation to the beginning of the list
      setConversations(prevConversations => {
        // Check if conversation already exists to prevent duplicates
        const conversationExists = prevConversations.some(
          conv => conv._id === event._id
        );
        
        if (conversationExists) {
          return prevConversations;
        }
        
        return [event, ...prevConversations];
      });
    }
  }, [newConversation, partnerId]);

  useEffect(() => {
    if (partnerId) {
      resetAndFetch();
    }
  }, [partnerId, tabValue]);

  useEffect(() => {
    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
      return () => {
        listElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  return (
    <>
      <Heading variant="h6">Conversations</Heading>
      <OuterContainer>
        <SearchBar
          placeholder="Search"
          fullWidth
          variant="outlined"
          size="small"
          onChange={(e) => handleSearchChange(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: '#B1C2D9' }} />
              </InputAdornment>
            ),
          }}
        />
        <StyledTabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
          <StyledTab label="All"  data-testid="conversationsList_all_tab"/>
          <StyledTab label="Unresolved"  data-testid="conversationsList_unresolved_tab"/>
          <StyledTab label="Resolved"  data-testid="conversationsList_resolved_tab"/>
        </StyledTabs>

        <StyledScrollContainer ref={listRef} data-testid="conversationsList_scroll_container">
          {errorMessage && <StyledError>{errorMessage}</StyledError>}
          {conversations.map((conversation, index) => (
            <ConversationItem
              key={conversation._id}
              conversation={conversation}
              isSelected={selectedChat === index}
              onSelect={() => handleSelectChat(index)}
            />
          ))}
          {isLoading && (
            <LoadingBox>
              <CircularProgress size={24} />
            </LoadingBox>
          )}
        </StyledScrollContainer>

      </OuterContainer>
    </>
  );
};

export default ConversationsList;
