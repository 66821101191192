import { FacebookCampaignInfo } from "types/digitalMarketing/campaigns";
import moment from 'moment'
import { GenericAd } from "types/digitalMarketing/googleAd";
import { LoadingSkeleton } from 'components'
import {
  TableRow,
  TableRowLoading,
  TableRowNoData,
  TableRowName,
  TableCell,
  TableCellStatus,
  StatusBadge,
  TruncatedText
} from "./styles";
import { Check, Schedule, CalendarToday, ErrorOutline, RocketLaunch, Pause } from '@mui/icons-material';
import { startCase } from "lodash";
import { PublishedCampaignItem, PublishedCampaignItemWithPartnerName } from 'types/digitalMarketing/common'
import { formatCurrency } from 'utils/dmUtils';
import Tooltip from '@mui/material/Tooltip';

export const Skeleton = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <TableRowLoading key={index}>
          <TableCell>
            <LoadingSkeleton />
          </TableCell>
        </TableRowLoading>
      ))}
    </>
  )
}

export const NoData = () => {
  return (
    <TableRowNoData>
      <TableCell centered fullWidth>
        No data
      </TableCell>
    </TableRowNoData>
  )
}

/**
 * Published Campaigns table Row
 */
export const CampaignItem = (props: { 
  item: PublishedCampaignItemWithPartnerName;
  onAction: (item: PublishedCampaignItemWithPartnerName) => void;
}) => {
  const { item, onAction } = props;
  const name = getName(item);
  const marketingPlatform = getContentType(item);
  const startDate = getStartDate(item);
  const endDate = getEndDate(item);
  const budget = getBudget(item);
  const status = getStatus(item);
  const location = item.partnerName;

  return (
    <TableRow>
      <TableRowName onClick={() => onAction(item)}>{name}</TableRowName>
      <TableCell>
        <Tooltip title={location} placement="top">
          <TruncatedText>{location}</TruncatedText>
        </Tooltip>
      </TableCell>
      <TableCell>{budget}</TableCell>
      <TableCell>{startDate}</TableCell>
      <TableCell>{endDate}</TableCell>
      <TableCell>{marketingPlatform}</TableCell>
      <TableCellStatus>
        <CampaignStatusBadge status={status} />
      </TableCellStatus>
    </TableRow>
  )
}

const isGoogleAd = (item: PublishedCampaignItem) => {
  return (item as GenericAd).google !== undefined;
}

const isFacebookAd = (item: PublishedCampaignItem) => {
  return (item as FacebookCampaignInfo).ads !== undefined;
}

/**
 * @returns the name of the item based on the type of ad
 * @param item - a google, facebook
 */
export const getName = (item?: PublishedCampaignItem | null) => {
  if(!item) return '';

  if(isFacebookAd(item)) {
    return (item as FacebookCampaignInfo).name;
  }

  if(isGoogleAd(item)) {
    return (item as GenericAd).name  
  }
}

/**
 * @returns the budget of the item based on the type of ad
 * @param item - a google, facebook
 */
 export const getBudget = (item?: PublishedCampaignItem | null) => {
  if(!item) return '';

  if(isFacebookAd(item)) {
    const firstAd = (item as FacebookCampaignInfo).ads[0];
    return `${startCase(firstAd?.budget?.type)}: $${formatCurrency(Number(firstAd?.budget?.amount))}`
  }

  if(isGoogleAd(item)) {
     return `Daily: ${formatCurrencyGoogle(Number((item as GenericAd)?.google?.budget?.amount))}`
  }
}

/**
 * @returns the start date of the item based on the type of ad
 * @param item - a google, facebook
 */
 export const getStartDate = (item?: PublishedCampaignItem | null) => {
  if(!item) return '';

  if(isFacebookAd(item)) {
    const startDate = (item as FacebookCampaignInfo).ads[0].schedule.startDate;
    if (startDate && 'day' in startDate && 'month' in startDate && 'year' in startDate) {
      const { day, month, year } = startDate;
      return moment({ year: Number(year), month: Number(month) - 1, day: Number(day) }).format('MM/DD/YY');
    }
    return '-';
  }

  if(isGoogleAd(item)) {
     const startDate = (item as GenericAd).startDate;
     return moment(startDate).format('MM/DD/YY');
  }
  return '-';
}

/**
 * @returns the end date of the item based on the type of ad
 * @param item - a google, facebook
 */
 export const getEndDate = (item?: PublishedCampaignItem | null) => {
  if(!item) return '';

  if(isFacebookAd(item)) {
    const endDate = (item as FacebookCampaignInfo).ads[0].schedule.endDate;
    if (endDate && 'day' in endDate && 'month' in endDate && 'year' in endDate) {
      const { day, month, year } = endDate;
      return moment({ year: Number(year), month: Number(month) - 1, day: Number(day) }).format('MM/DD/YY');
    }
    return 'Never';
  }

  if(isGoogleAd(item)) {
     const endDate = (item as GenericAd).endDate;
     if (!endDate) {
      return 'Never';
     }
     return moment(endDate).format('MM/DD/YY');
  }
}

/**
 * @returns the content type of the item based on the type of ad
 * @param item - a google, facebook
 */
export const getContentType = (item: PublishedCampaignItem) => {
  if(isFacebookAd(item)) {
    return 'Meta Paid Ads'
  }

  if(isGoogleAd(item)) {
    return 'Google Paid Search Ads'
  }
}

/**
 * @returns the status of the item based on the type of ad
 * @param item - a google, facebook
 */
 export const getStatus = (item: PublishedCampaignItem) => {
  let s = 'error';
  const isUpcomingGoogle = (item as GenericAd)?.startDate
    ? moment((item as GenericAd)?.startDate).isAfter(moment.utc())
    : false;
  const startDate = (item as FacebookCampaignInfo)?.ads && (item as FacebookCampaignInfo)?.ads[0]?.schedule?.startDate;
  let startDateStr;
  if (startDate && 'day' in startDate && 'month' in startDate && 'year' in startDate) {
    const { day, month, year } = startDate;
    startDateStr = moment({ year: Number(year), month: Number(month) - 1, day: Number(day) }).format('MM/DD/YY');
  }
  const isUpcomingMeta = startDateStr
    ? moment(startDateStr).isAfter(moment.utc())
    : false;
  const isFinishedGoogle = (item as GenericAd)?.endDate
    ? moment((item as GenericAd)?.endDate).isBefore(moment.utc())
    : false;
  const endDate = (item as FacebookCampaignInfo)?.ads && (item as FacebookCampaignInfo)?.ads[0]?.schedule?.endDate;
  let endDateStr;
  if (endDate && 'day' in endDate && 'month' in endDate && 'year' in endDate) {
    const { day, month, year } = endDate;
    endDateStr = moment({ year: Number(year), month: Number(month) - 1, day: Number(day) }).format('MM/DD/YY');
  }
  const isFinishedMeta = endDateStr
    ? moment(endDateStr).isBefore(moment.utc())
    : false;

  switch (item.status) {
    case 'live':
    case 'submitted':
      s = 'live'
      if (isUpcomingGoogle || isUpcomingMeta) {
        s = 'upcoming'
      }
      if (isFinishedGoogle || isFinishedMeta) {
        s = 'completed'
      }
      if ((item as GenericAd).withIssues) {
        s = 'error'
      }
      if ((item as GenericAd)?.google && item?.status === 'submitted' && !(item as GenericAd).withIssues) {
        s = 'publishing'
      }
      break;
    case 'failed':
    case 'with-issues':
      s = 'error'
      break;
    case 'in-process':
    case 'pre-approved':
    case 'draftApproved':
      s = 'publishing'
      break;
    case 'paused':
      s = 'paused'
      break;
    default:
      s = 'error'
  }
  
  return s; 
}

export const StatusIcon = ({ status } : { status: string }) => {
  switch (status) {
    case 'completed':
      return <Check style={{ color: '#32965D', fontSize: 14 }} />;
    case 'live':
      return <Schedule style={{ color: '#1B51A4', fontSize: 14 }} />;
    case 'upcoming':
      return <CalendarToday style={{ color: '#292929', fontSize: 14 }} />;
    case 'error':
      return <ErrorOutline style={{ color: '#C13030', fontSize: 14 }} />;
    case 'publishing':
      return <RocketLaunch style={{ color: '#CB9709', fontSize: 14 }} />;
    case 'paused':
      return <Pause style={{ color: '#CB9709', fontSize: 14 }} />;
    default:
      return null;
  }
};

export const CampaignStatusBadge = ({ status } : { status: string }) => (
  <StatusBadge status={status}>
    <StatusIcon status={status} />
    <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
  </StatusBadge>
);

export const formatCurrencyGoogle = (n: number | undefined) => {
  if (!n) return '$0';
  const formattedNumber = n
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');

  return `$${formattedNumber}`;
};