import { Grid } from '@mui/material'
import { getGoogleBusinessProfile } from 'api'
import { LoadingSkeleton } from 'components'
import RightDrawer from 'components/RightDrawer/RightDrawer'
import useSafeAsync from 'hooks/useSafeAsync'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { format24HourTime, formatDateObjectToString } from 'utils'
import { cleanPhoneNumber, formatPhoneNumber } from 'utils/phone'

import * as Styled from './styles'

interface GoogleBusinessProfileDetailsDrawerProps {
  open: boolean
  marketingChannelId: string
  partnerId: string
  onClose: () => void
}

type DateObject = {
  year: number;
  month: number;
  day: number;
}

type TimeObject = {
  hours: number;
  minutes: number;
}

type RegularHourPeriod = {
  openDay: string;
  openTime: TimeObject;
  closeDay: string;
  closeTime: TimeObject;
  closed?: boolean; // Added locally
}

type BusinessHours = SpecialHourPeriod & RegularHourPeriod;
type SpecialHourPeriod = {
  /** The calendar date this special hour period starts on. */
  startDate?: DateObject;
  /** The calendar date this special hour period ends on. */
  endDate?: DateObject;
  /** Whether the location is closed for the special hour period. */
  closed?: boolean | null;
}


type BusinessHourProps = {
  period: BusinessHours;
}

const isClosedAllDay = (period: BusinessHours) => {
  return period.closed 
    && period.startDate
    && period.endDate
    && period.startDate?.year === period.endDate?.year
    && period.startDate?.month === period.endDate?.month
    && period.startDate?.day === period.endDate?.day
}

const DAYS_OF_WEEK = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] as string[];

const processBusinessHours = (periods: RegularHourPeriod[]) => {
  const existingDays = new Set(periods.map(period => period.openDay));
  
  // add missing days as closed
  const missingDays = DAYS_OF_WEEK.filter(day => !existingDays.has(day))
    .map(day => ({ openDay: day, closed: true }));
  
  // combine and sort based on the DAYS_OF_WEEK order
  return [...periods, ...missingDays].sort((a, b) => 
    DAYS_OF_WEEK.indexOf(a.openDay) - DAYS_OF_WEEK.indexOf(b.openDay)
  );
}

const BusinessHour: React.FC<BusinessHourProps & { isSpecialHours?: boolean }> = ({ period, isSpecialHours }) => (
  <Grid container item xs={12} data-testid={isSpecialHours ? 'special-hours' : 'regular-hours'}>
    <Grid item xs={5} sm={7}>
      {period.openDay && <Styled.Value data-testid='open-day'>{_.startCase(period.openDay.toLowerCase())}</Styled.Value>}
      {period.startDate && (
        <Styled.Value data-testid='start-date'>{`${formatDateObjectToString(period.startDate)}`}</Styled.Value>
      )}
    </Grid>
    <Grid item xs={7} sm={5}>
      <Styled.Time data-testid='time'>
        {isClosedAllDay(period) || period.closed 
          ? 'Closed' 
          : `${format24HourTime(period.openTime)} - ${format24HourTime(period.closeTime)}`}
      </Styled.Time>
    </Grid>
  </Grid>
)

const GoogleBusinessProfileDetailsDrawer: React.FC<GoogleBusinessProfileDetailsDrawerProps> = ({
  open,
  marketingChannelId,
  partnerId,
  onClose,
}) => {
  const [profileLoading, setProfileLoading] = useState(false)
  const [profile, setProfile] = useState<any>(null)
  const safeAsync = useSafeAsync()

  const fetchProfile = useCallback(async () => {
    if (!marketingChannelId) return

    setProfileLoading(true)

    const profileData = await getGoogleBusinessProfile(partnerId, marketingChannelId)

    setProfile(profileData)
    setProfileLoading(false)
  }, [marketingChannelId])

  useEffect(() => {
    safeAsync(fetchProfile()).catch((e) => {
      console.error(e)
      setProfileLoading(false)
    })
  }, [partnerId, safeAsync, fetchProfile])

  return (
    <RightDrawer
      isOpen={open}
      onClose={onClose}
      title='Google Business Profile Details'
      hideBackdrop
    >
      <Styled.Container>
        <Styled.Label variant='caption'>Location Name</Styled.Label>
        {profileLoading ? (
          <LoadingSkeleton isFlex />
        ) : (
          <Styled.Value>{profile?.title || 'N/A'}</Styled.Value>
        )}

        <Styled.Spacer />

        <Styled.Label variant='caption'>Website URL</Styled.Label>
        <Styled.Link href={profile?.websiteUri} target='_blank'>
          {profileLoading ? <LoadingSkeleton isFlex /> : profile?.websiteUri || 'N/A'}
        </Styled.Link>

        <Styled.Spacer />

        {profile?.menuLink && (
          <>
            <Styled.Label variant='caption'>Menu Link</Styled.Label>
            <Styled.Link href={profile?.menuLink} target='_blank'>
              {profileLoading ? <LoadingSkeleton isFlex /> : profile?.menuLink || 'N/A'}
            </Styled.Link>

            <Styled.Spacer />
          </>
        )}

        <Styled.Label variant='caption'>Phone Number</Styled.Label>
        <Styled.Link href={`tel:${cleanPhoneNumber(profile?.phoneNumbers?.primaryPhone)}`}>
          {profileLoading ? (
            <LoadingSkeleton isFlex />
          ) : (
            formatPhoneNumber(profile?.phoneNumbers?.primaryPhone) || 'N/A'
          )}
        </Styled.Link>

        <Styled.Spacer />

        <Styled.Label variant='caption'>Hours</Styled.Label>
        {profile?.regularHours?.periods?.length > 0 || profileLoading ? (
          <Grid container spacing={profileLoading ? 0 : 1}>
            {profileLoading ? (
              <LoadingSkeleton isFlex />
            ) : (
              processBusinessHours(profile?.regularHours?.periods).map((period: any, index: number) => (
                <BusinessHour key={index} period={period} />
              ))
            )}
          </Grid>
        ) : (
          <Styled.Value>{'N/A'}</Styled.Value>
        )}

        <Styled.Spacer />

        <Styled.Label variant='caption'>Special Hours</Styled.Label>
        {profile?.specialHours?.specialHourPeriods?.length > 0 || profileLoading ? (
          <Grid container spacing={profileLoading ? 0 : 1}>
            {profileLoading ? (
              <LoadingSkeleton isFlex />
            ) : (
              profile?.specialHours?.specialHourPeriods.map((period: any, index: number) => (
                <BusinessHour key={index} period={period} isSpecialHours={true} />
              ))
            )}
          </Grid>
        ) : (
          <Styled.Value>{'N/A'}</Styled.Value>
        )}
      </Styled.Container>
    </RightDrawer>
  )
}

export default GoogleBusinessProfileDetailsDrawer
