import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { LoadingSkeleton } from 'components'
import { orderBy, startCase } from 'lodash'
import moment from 'moment'
import * as Styled from './styles'
import { useGetInvoices, useGetLocations } from 'hooks'
import SingleLocationDropdown from 'components/SingleLocationDropdown/SingleLocationDropdown'
import { Location } from 'types'


const Billing = () => {
  const { defaultLocation, setDefaultLocation } = useGetLocations()
  const locationId = defaultLocation !== null ? String(defaultLocation?.id) : ''

  // Re-fetch invoices when selectedLocation changes
  const [{ invoices, isLoading }] = useGetInvoices(locationId)
  const sortedInvoices = orderBy(invoices, (item) => moment(item?.attributes?.due_date, 'MM/DD/YYYY'), 'desc')

  const renderSkeletonRows = () => {
    return Array.from({ length: 5 }).map((_, index) => (
      <tr key={index}>
        <Styled.InvoiceTableCell colSpan={4}>
          <LoadingSkeleton />
        </Styled.InvoiceTableCell>
      </tr>
    ))
  }

  const renderNoData = () => {
    return (
      <tr>
        <Styled.InvoiceTableCellNoData colSpan={4}>
          No data
        </Styled.InvoiceTableCellNoData>
      </tr>
    )
  }

  // Handle location change from the dropdown
  const handleLocationChange = (location: Location) => {
    setDefaultLocation({ 
      id: location.id, 
      name: location.name,
      organization_id: location.organization_id,
      organization_name: location.organization_name,
      timezone: location.timezone
    })
  }

  return (
    <Styled.BillingContainer>
      <SingleLocationDropdown
        onLocationChange={handleLocationChange}
      />
      <Styled.InvoiceContainer>
        <Styled.InvoiceTitle>Invoices</Styled.InvoiceTitle>
        <Styled.InvoiceTable>
          <thead>
            <tr>
              <Styled.InvoiceTableHeaderCell>Amount</Styled.InvoiceTableHeaderCell>
              <Styled.InvoiceTableHeaderCell>Due Date</Styled.InvoiceTableHeaderCell>
              <Styled.InvoiceTableHeaderCell>Status</Styled.InvoiceTableHeaderCell>
              <Styled.InvoiceTableHeaderCell></Styled.InvoiceTableHeaderCell>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              renderSkeletonRows()
            ) : invoices?.length === 0 ? (
              renderNoData()
            ) : (
              sortedInvoices?.map((invoice, index) => (
                <tr key={index}>
                  <Styled.InvoiceTableCell>{`$${invoice.attributes.amount}`}</Styled.InvoiceTableCell>
                  <Styled.InvoiceTableCell>{invoice.attributes.due_date}</Styled.InvoiceTableCell>
                  <Styled.InvoiceTableCell>
                    {invoice.attributes.status === 'Paid' ? (
                      <Styled.PaidStatus>{startCase(invoice.attributes.status)}</Styled.PaidStatus>
                    ) : (
                      <Styled.CurrentStatus>{startCase(invoice.attributes.status)}</Styled.CurrentStatus>
                    )}
                  </Styled.InvoiceTableCell>
                  <Styled.InvoiceTableCell>
                    <Styled.DownloadIcon onClick={() => window.open(invoice.attributes.pdf_url.replace(/\s/g, ''))}>
                      <DownloadIcon />
                    </Styled.DownloadIcon>
                  </Styled.InvoiceTableCell>
                </tr>
              ))
            )}
          </tbody>
        </Styled.InvoiceTable>
      </Styled.InvoiceContainer>
    </Styled.BillingContainer>
  )
}

export default Billing
