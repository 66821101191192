import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { fontFamily } from 'theme'
import { Button } from 'components'
import { toast } from 'react-toastify'
import Header from './Header/Header'
import PromoCodesDrawer from './PromoCodesDrawer'
import PromoCodesModal from './Modals/PromoCodesModal'
import { PaginationLinks } from 'api'
import { PromoCode, Meta } from 'api/promoCodes/types'
import { formatUpdatePromoCodeStatusPayload, getPromoCodes, updatePromoCodeStatus } from 'api/promoCodes/promoCodes'
import { useGetLocations } from 'hooks'
import { getPromoCodesNewURL } from 'utils'
import * as Styled from './styles'
import { PromoCodeStatus } from 'api/promoCodes/enums'

export const PromoCodes = () => {
  const navigate = useNavigate()
  const { defaultLocation } = useGetLocations()
  const organizationId = defaultLocation !== null ? String(defaultLocation?.organization_id) : ''

  return (
    <>
      <Header title='Promo Codes' />
      <Styled.Container>
        <Styled.PromoCodesContainer>
          <Styled.RowPair style={{ marginBottom: 20 }}>
            <Styled.PromoCodesTitle>Coupons</Styled.PromoCodesTitle>
            <Button
              size='small'
              narrow
              onClick={() => navigate(getPromoCodesNewURL())}
            >
              Create
            </Button>
          </Styled.RowPair>
          <TableComponent organizationId={organizationId} />
        </Styled.PromoCodesContainer>
      </Styled.Container>
    </>
  )
}

export const TableComponent = ({ organizationId }: { organizationId: string }) => {
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [page, setPage] = useState(0)
  const [data, setData] = useState<PromoCode[] | []>([])
  const [link, setLink] = useState<PaginationLinks>({
    first: null,
    prev: null,
    self: null,
    next: null,
    last: null,
  })
  const [meta, setMeta] = useState<Meta>({
    limit: `${rowsPerPage}`,
    page: 1,
    page_total: 1,
    total: 0,
  })
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedPromoCode, setSelectedPromoCode] = useState<PromoCode | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  
  const fetchPromoCodes = useCallback(async (link?: string) => {
    try {
      if (!organizationId) return
      const response = await getPromoCodes({ organizationId, link, limit: rowsPerPage })
      const promoCodesData = response?.data.map((promoCodeResource) => promoCodeResource.attributes)
      setData(promoCodesData)
      if (response?.links) {
        setLink(response.links)
      }
      if (response?.meta) {
        setMeta(response.meta as Meta)
      }
    } catch (error) {
      console.error('Failed to fetch promo codes:', error)
    }
  }, [organizationId, link, rowsPerPage])

  useEffect(() => {
    if (!organizationId) return
    fetchPromoCodes()
  }, [organizationId, rowsPerPage])

  const handleChangePage = (event: any, newPage: number) => {
    if (newPage > page && link.next) {
      fetchPromoCodes(link.next)
    } else if (newPage < page && link.prev) {
      fetchPromoCodes(link.prev)
    } else {
      fetchPromoCodes(link.self || undefined)
    }
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: any) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  const handleRowClick = (_event: React.MouseEvent<HTMLTableRowElement>, promoCode: PromoCode) => {
    if ((_event.target as HTMLElement).closest('[data-testid="more-button"]')) {
      return
    }
    setSelectedPromoCode(promoCode)
    setIsDrawerOpen(true)
  }

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false)
    setSelectedPromoCode(null)
  }

  const handleModalOpen = (promoCode: PromoCode) => {
    setSelectedPromoCode(promoCode)
    setIsModalOpen(true)
  }
  
  const handleModalClose = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation()
    setIsModalOpen(false)
    setSelectedPromoCode(null)
  }

  const handleCancelCoupon = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (selectedPromoCode) {
      try {
        if (!organizationId) {
          toast.error('Organization ID is required.')
          return
        }
        if (!selectedPromoCode.id) {
          toast.error('Coupon ID is required.')
          return
        }

        const updatePromoCodeStatusPayload = formatUpdatePromoCodeStatusPayload({
          rules: {},
          active: false
        })
        await updatePromoCodeStatus(organizationId, selectedPromoCode.id, updatePromoCodeStatusPayload)
        toast.success('Coupon has been successfully canceled.', { autoClose: 5000 })
        setIsModalOpen(false)
        setSelectedPromoCode(null)
        fetchPromoCodes()
      } catch (error) {
        toast.error('Failed to cancel coupon status.')
        console.error('Error updating promo code status:', error)
      }
    }
  }

  const handleMoreButtonClick = (event: React.MouseEvent, promoCode: PromoCode) => {
    event.stopPropagation()
    console.log(`More button clicked for promo code: ${promoCode.code}`)
  }

  return (
    <Styled.Container>
      <TableContainer>
        <Table>
          <TableHead>
            <Styled.StyledTableRow>
              <Styled.MobileVisibleTableHeader>Coupon Code</Styled.MobileVisibleTableHeader>
              <Styled.CustomTableCell>Created</Styled.CustomTableCell>
              <Styled.CustomTableCell>Start date</Styled.CustomTableCell>
              <Styled.CustomTableCell>End date</Styled.CustomTableCell>
              <Styled.MobileVisibleTableHeader>Redeemed</Styled.MobileVisibleTableHeader>
              <Styled.CustomTableCell>Status</Styled.CustomTableCell>
              <Styled.CustomTableCell>Actions</Styled.CustomTableCell>
            </Styled.StyledTableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row) => {
                return (
                  <Styled.StyledTableRow
                    key={row.id}
                    onClick={(event) => handleRowClick(event, row)}
                  >
                    <Styled.MobileVisibleTableCell>{row.code}</Styled.MobileVisibleTableCell>
                    <Styled.CustomTableCell>{moment(row.created).format('MM/DD/YYYY')}</Styled.CustomTableCell>
                    <Styled.CustomTableCell>{moment(row.starts_at).format('MM/DD/YYYY')}</Styled.CustomTableCell>
                    <Styled.CustomTableCell>{row.expires_at ? moment(row.expires_at).format('MM/DD/YYYY') : '-'}</Styled.CustomTableCell>
                    <Styled.CustomTableCell>{row.redeemed}</Styled.CustomTableCell>
                    <Styled.CustomTableCell>
                      <Styled.StyledChip label={row.aasm_state} status={row.aasm_state} size='small' />
                    </Styled.CustomTableCell>
                    <Styled.CenterTableCell>
                    {row.aasm_state !== PromoCodeStatus.DISABLED && (
                      <Styled.CenteredIconButton
                        onClick={(event) => handleMoreButtonClick(event, row)} 
                        data-testid='more-button'
                      >
                        <MoreHorizIcon sx={{ fontSize: 13 }} />
                      </Styled.CenteredIconButton>
                    )}  
                    </Styled.CenterTableCell>
                  </Styled.StyledTableRow>
                )
              })
            ) : (
              <Styled.StyledTableRow>
                <Styled.CustomTableCell colSpan={7} align='center'>
                  No data available
                </Styled.CustomTableCell>
              </Styled.StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Styled.PaginationContainer>
        <TablePagination
          component='div'
          count={meta.total}
          page={page}
          onPageChange={handleChangePage}
          slotProps={{
            actions: {
              previousButton: {
                disabled: !link?.prev,
              },
              nextButton: {
                disabled: !link?.next,
              },
            },
          }}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Rows per page'
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          sx={{
            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-caption, & .MuiTablePagination-input, & .MuiTablePagination-select': {
              fontFamily: fontFamily.inter,
              fontSize: 13,
            },
          }}
        />
      </Styled.PaginationContainer>
      <PromoCodesDrawer
        isOpen={isDrawerOpen}
        onClose={handleCloseDrawer}
        promoCode={selectedPromoCode}
        onModalOpen={handleModalOpen}
      />
      {selectedPromoCode && (
        <PromoCodesModal
          isOpen={isModalOpen}
          title={`Cancel Coupon ${selectedPromoCode.code}?`}
          description={`This action can't be undone, make sure you want to cancel this coupon?`}
          submitText={'Cancel Coupon'}
          onClose={handleModalClose}
          onCancelCoupon={handleCancelCoupon}
          promoCode={selectedPromoCode}
        />
      )}
    </Styled.Container>
  )
}

export default PromoCodes
