import moment from 'moment'
import React from 'react'

import {
  MessageContainer,
  InitialsCircle,
  MessageBox,
  MessageText,
  TimestampBox,
  MessageWrapper,
  MessageArrow,
} from './styles'
import { ConversationStatus } from 'api/ovation/models'
interface ConversationChatMessageProps {
  name: string;
  message: string;
  createdAt: string;
  isMe: boolean;
  type: ConversationStatus;
}

const ConversationChatMessage: React.FC<ConversationChatMessageProps> = ({
  name = 'Unknown User',
  message,
  createdAt,
  type,
  isMe,
}) => {
  const initials = function() {
    if (type === ConversationStatus.LeftSurvey) {
      return name
    }
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  }

  const formattedTime = moment(createdAt).format('h:mm A')

  return (
    <MessageContainer isMe={isMe}>
      <InitialsCircle noborder={type === ConversationStatus.LeftSurvey}>
        {initials()}
      </InitialsCircle>
      <MessageWrapper isMe={isMe}>
        <MessageArrow isMe={isMe} />
        <MessageBox>
          <MessageText isMe={isMe}>{message}</MessageText>
        </MessageBox>
        <TimestampBox isMe={isMe}>{formattedTime}</TimestampBox>
      </MessageWrapper>
    </MessageContainer>
  )
}

export default ConversationChatMessage
