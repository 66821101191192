import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Button } from 'components'
import Header from './Header/Header'
import { AppliesTo, Conditions, PromoCodeStatus } from 'api/promoCodes/enums'
import { createPromoCode, formatPromoCodePayload } from 'api/promoCodes/promoCodes'
import { PromoCode, couponValues } from 'api/promoCodes/types'
import { useGetLocations, useIsMobile } from 'hooks'
import { getPromoCodesURL } from 'utils'
import * as Styled from './styles'


const validationSchema = Yup.object({
  code: Yup.string()
    .required('Coupon code is required')
    .min(3, 'Coupon code must be at least 6 characters'),
  discount_type: Yup.string().required('Coupon type is required'),
  discount_amount: Yup.number()
    .required('Discount amount is required')
    .positive('Discount amount must be a positive number')
    .min(1, 'Discount amount must be at least 1'),
  starts_at: Yup.date()
    .required('Start date is required')
    .when('expires_at', {
      is: (expires_at: any) => expires_at !== null,
      then: Yup.date().max(Yup.ref('expires_at'), 'Start date cannot be later than end date'),
      otherwise: Yup.date()
    }),
  expires_at: Yup.date()
    .when('includeEndDate', {
      is: true,
      then: Yup.date()
        .required('End date is required')
        .min(Yup.ref('starts_at'), 'End date cannot be before start date'),
      otherwise: Yup.date().nullable(),
    })
})


const PromoCodesForm = () => {
  const navigate = useNavigate()
  const { isMobile } = useIsMobile()
  const { defaultLocation } = useGetLocations()
  const organizationId = defaultLocation !== null ? String(defaultLocation?.organization_id) : ''

  type PromoCodeWithIncludeEndDate = PromoCode & { includeEndDate: boolean }

  const handleSubmit = async (values: PromoCodeWithIncludeEndDate) => {
    const { includeEndDate, ...promoCodePayload } = values
    try {
      const payload = formatPromoCodePayload(promoCodePayload)
      await createPromoCode(organizationId, payload)
      toast.success('Promo code successfully created.', { autoClose: 5000 })
      navigate(getPromoCodesURL())
    } catch (error: any) {
      // Check if the error has a specific response structure
      if (error?.response?.data?.errors) {
        const errorMessage = error.response.data.errors[0]?.message || 'Unknown error occurred'
        toast.error(errorMessage, { autoClose: 5000 })
      } else {
        toast.error(`Unknown error: ${String(error)}`, { autoClose: 5000 })
      }
    }
  }

  const appliesToOptions = Object.values(AppliesTo)
  // const conditionsOptions = Object.values(Conditions)

  return (
    <>
      <Header title='Create Coupon' />
      <Styled.Container>
        <Formik
          initialValues={{
            code: '',
            // description: '',
            discount_type: couponValues.FIXED_DISCOUNT.value,
            discount_amount: null,
            applies_to: AppliesTo.ENTIRE_SALE,
            conditions: Conditions.MINIMUM_PURCHASE_AMOUNT,
            conditionsValue: '',
            starts_at: new Date(),
            expires_at: null,
            aasm_state: PromoCodeStatus.ACTIVE,
            includeEndDate: true,
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values, setFieldValue, isValid, touched, errors }) => (
            <Form>
              <Styled.FormContainer>
                <Styled.Row style={{ marginBottom: 20 }}>
                  <Styled.PromoCodesTitle>Coupon Configuration</Styled.PromoCodesTitle>
                </Styled.Row>
                <Styled.Row>
                  <Styled.FormLabel>Coupon Name</Styled.FormLabel>
                </Styled.Row>
                <Styled.Row>
                  <Field
                    name='code'
                    type='text'
                    width={isMobile ? '100%' : '170px'}
                    required
                    as={Styled.Input}
                  />
                  {touched.code && errors.code && (
                    <Styled.ErrorText>{errors.code}</Styled.ErrorText>
                  )}
                </Styled.Row>
                {/* <Styled.Row>
                  <Styled.FormLabel>Description</Styled.FormLabel>
                </Styled.Row>
                <Styled.Row>
                  <Field
                    name='description'
                    type='text'
                    width={isMobile ? '100%' : '170px'}
                    as={Styled.Input}
                  />
                </Styled.Row> */}
                <Styled.Row>
                  <Styled.FormLabel>Coupon Type</Styled.FormLabel>
                </Styled.Row>
                <Styled.Row>
                  <Styled.InlineRadioWrapper>
                    {Object.keys(couponValues).map((key) => {
                      const couponType = key as keyof typeof couponValues
                      return (
                        <Styled.InlineFormControlLabel
                          key={couponType}
                          value={couponValues[couponType].value}
                          control={
                            <Styled.CustomRadio
                              checked={values.discount_type === couponValues[couponType].value}
                              onChange={(e) => setFieldValue('discount_type', e.target.value)}
                            />
                          }
                          label={couponValues[couponType].label}
                          labelPlacement='end'
                        />
                      )
                    })}
                  </Styled.InlineRadioWrapper>
                </Styled.Row>
                <Styled.Row>
                  <Field
                    name='discount_amount'
                    type='number'
                    width={isMobile ? '100%' : '170px'}
                    required
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target
                      setFieldValue('discount_amount', value ? Number(value) : null)
                    }}
                    as={Styled.Input}
                  />
                  {touched.discount_amount && errors.discount_amount && (
                    <Styled.ErrorText>{errors.discount_amount}</Styled.ErrorText>
                  )}
                </Styled.Row>
                <Styled.Row>
                  <Styled.FormLabel>Applies To</Styled.FormLabel>
                </Styled.Row>
                <Styled.Row>
                  <Styled.InlineRadioWrapper>
                    {appliesToOptions.map((type) => (
                      <Styled.InlineFormControlLabel
                        key={type}
                        value={type}
                        control={
                          <Styled.CustomRadio
                            checked={values.applies_to === type}
                            onChange={(e) => setFieldValue('applies_to', e.target.value)}
                          />
                        }
                        label={type}
                        labelPlacement='end'
                      />
                    ))}
                  </Styled.InlineRadioWrapper>
                </Styled.Row>
                {/* <Styled.Row>
                  <Styled.FormLabel>Conditions</Styled.FormLabel>
                </Styled.Row>
                <Styled.Row>
                  <Styled.ColumnCheckboxWrapper>
                    {conditionsOptions.map((condition) => (
                      <Styled.InlineFormControlLabel
                        key={condition}
                        value={condition}
                        control={
                          <Styled.CustomCheckbox
                            checked={values.conditions === condition}
                            onChange={(e) =>
                              setFieldValue('conditions', e.target.checked ? condition : '')
                            }
                          />
                        }
                        label={condition}
                        labelPlacement='end'
                      />
                    ))}
                  </Styled.ColumnCheckboxWrapper>
                </Styled.Row>
                <Styled.Row>
                  <Field
                    name='conditions_value'
                    type='text'
                    placeholder='Minimum purchase amount'
                    width={isMobile ? '100%' : '170px'}
                    as={Styled.Input}
                  />
                </Styled.Row>
                <Styled.Row>
                  <Styled.Subtext>
                    This condition must be applied to the entire sale. If you've applied it to a
                    specific category or item, please adjust the selection accordingly.
                  </Styled.Subtext>
                </Styled.Row> */}
                <Styled.Row
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Styled.ActiveDatesText>Active Dates</Styled.ActiveDatesText>
                  <Styled.ColumnCheckboxWrapper>
                    <Styled.InlineFormControlLabel
                      control={
                        <Styled.CustomCheckbox
                          checked={values.includeEndDate}
                          onChange={() => {
                            const newValue = !values.includeEndDate
                            setFieldValue('includeEndDate', newValue)
                            if (!newValue) {
                              setFieldValue('expires_at', null)
                            }
                          }}
                        />
                      }
                      label={'Set end date'}
                      labelPlacement='end'
                    />
                  </Styled.ColumnCheckboxWrapper>
                </Styled.Row>
                <Styled.Row style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Styled.FormLabel style={{ flex: 1, textAlign: 'left' }}>
                    Start Date
                  </Styled.FormLabel>
                  {values.includeEndDate && (
                    <Styled.FormLabel style={{ flex: 1, textAlign: 'left' }}>
                      End Date
                    </Styled.FormLabel>
                  )}
                </Styled.Row>
                <Styled.Row
                  style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 8 }}
                >
                  <Styled.DatePickerWrapper data-testid='date-picker-wrapper'>
                    <Styled.DateWrapper>
                      <Styled.DateInput
                        selected={values.starts_at ? values.starts_at : new Date()}
                        onChange={(date: Date) => setFieldValue('starts_at', date)}
                        dateFormat='dd-MM-YYYY'
                        placeholderText='Select a date'
                        popperPlacement='right-start'
                        showIcon
                        required
                        minDate={new Date()}
                      />
                    </Styled.DateWrapper>
                  </Styled.DatePickerWrapper>
                  {values.includeEndDate && (
                    <Styled.DatePickerWrapper data-testid='date-picker-wrapper'>
                      <Styled.DateWrapper>
                        <Styled.DateInput
                          selected={values.expires_at ? values.expires_at : new Date()}
                          onChange={(date: Date) => setFieldValue('expires_at', date)}
                          dateFormat='dd-MM-YYYY'
                          placeholderText='Select a date'
                          popperPlacement='right-start'
                          showIcon
                          required
                          minDate={new Date()}
                        />
                      </Styled.DateWrapper>
                    </Styled.DatePickerWrapper>
                  )}
                </Styled.Row>
              </Styled.FormContainer>
              <Styled.Row full style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 8 }}>
                <Button onClick={() => navigate(getPromoCodesURL())} variant='textonly' narrow>
                  Discard
                </Button>
                <Button type='submit' narrow disabled={!isValid}>
                  Create Coupon
                </Button>
              </Styled.Row>
            </Form>
          )}
        </Formik>
      </Styled.Container>
    </>
  )
}

export default PromoCodesForm
