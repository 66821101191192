import styled from 'styled-components'
import { breakpoints, colors, elevation, fontWeight } from 'theme'

export const Container = styled.div<{ hasOpenBanner?: boolean, sidebarWidth?: string }>(
  {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    backgroundColor: colors.white,
  },
  ({ hasOpenBanner }) =>
    hasOpenBanner && {
      paddingTop: '3rem',
    },
    ({ sidebarWidth }) =>
      sidebarWidth && {
        width: `cal(100% - ${ sidebarWidth })`,
        paddingLeft: `${ sidebarWidth }`,
        transition: 'padding-left 0.3s ease',
      },
)

export const Aside = styled.aside({
  display: 'flex',
  position: 'fixed',
  width: 0,
  left: 0,
  zIndex: elevation.backdrop,
  [`@media ${breakpoints.md}`]: {
    width: 336,
  },
})

export const Main = styled.main({
  padding: '88px 24px 2rem 24px',
  justifyContent: 'center',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: colors.white,
  [`@media ${breakpoints.xxs}`]: {
    gridTemplateRows: '3rem 1fr',
    padding: '0px 16px 2rem 16px',
  },
  [`@media ${breakpoints.xs}`]: {
    gridTemplateRows: '3rem 1fr',
    padding: '0px 16px 2rem 16px',
  },
  [`@media ${breakpoints.sm}`]: {
    gridTemplateRows: '3rem 1fr',
    padding: '0px 24px 2rem 24px',
  },
  [`@media ${breakpoints.md}`]: {
    gridTemplateRows: '3rem 1fr',
    padding: '88px 24px 2rem 24px',
  },
  [`@media ${breakpoints.lg}`]: {
    gridTemplateRows: '3rem 1fr',
    padding: '88px 24px 2rem 24px',
  },
  [`@media ${breakpoints.xl}`]: {
    gridTemplateRows: '3rem 1fr',
    padding: '0px 32px 2rem 32px',
  },
})

export const LoadingContent = styled.div({
  color: colors.primary.dark,
  fontWeight: fontWeight.bold,
  fontSize: '3rem',
  lineHeight: '19px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
})
