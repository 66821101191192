import { Tab, Tabs, TextField, Box } from '@mui/material'
import styled from 'styled-components'
import { breakpoints } from 'theme';

export const SearchBar = styled(TextField)`
  margin-top: 12px !important;
  background-color: #ffffff;
  border-color: #d2ddec;
  height: 40px;
  display: none !important;
  & .MuiOutlinedInput-root {
    fieldset {
      border-color: #d2ddec;
    }
  }

  & [type='text']:focus {
    box-shadow: none;
    border-color: #d2ddec;
  }
`

export const StyledTabs = styled(Tabs)`
  margin-top: 16px;
  background-color: #edf2f9;
  border-radius: 8px;
  min-height: 30px !important;
  & .MuiTabs-indicator {
    display: none;
  }
`

export const StyledTab = styled(Tab)`
  &.MuiButtonBase-root {
    text-transform: capitalize;
    color: #000000;
    min-height: 30px;
    height: 30px;
  }

  &.Mui-selected {
    background-color: #ffffff;
    color: #000000 !important;
  }

  &:not(.Mui-selected) {
    border-right: 1px solid #e0e0e0;
  }

  &:last-of-type {
    border-right: none;
    border-radius: 0 8px 8px 0;
  }

  &:first-of-type {
    border-radius: 8px 0 0 8px;
  }

  &:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
  }
`

export const StyledScrollContainer = styled.div`
  margin-top: 12px !important;
  overflow-y: auto;
  @media ${breakpoints.xxs} {
    height: calc(100vh - 260px);
  }

  @media ${breakpoints.lg} {
    height: calc(100vh - 150px);
  }
`

export const OuterContainer = styled.div`
  padding-right: 16px;
`
export const LoadingBox = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const StyledError = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 16px;
  color: red;
`;