import { LoyaltyRedeemable } from 'api/loyaltyRedeemables/models'
import Toast from 'components/Toast/Toast'
import { useEffect, useState } from 'react'

import {
  createLoyaltyRedeemable,
  deleteLoyaltyRedeemable,
  getLoyaltyRedeemables,
  updateLoyaltyRedeemable,
} from '../api/loyaltyRedeemables'

const useLoyaltyRedeemables = (organizationId: string) => {
  const [loyaltyRedeemables, setLoyaltyRedeemables] = useState<LoyaltyRedeemable[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const fetchLoyaltyRedeemables = async () => {
    try {
      setLoading(true)
      const data = await getLoyaltyRedeemables(organizationId)
      setLoyaltyRedeemables(data.data)
    } catch (err: any) {
      setError('Failed to fetch Rewards')
      Toast.error({ message: `Failed to fetch Rewards. Details: ${err?.response?.data?.errors || err}` }, { autoClose: 3000 })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (organizationId) {
      fetchLoyaltyRedeemables()
    }
  }, [organizationId])

  const createReward = async (payload: any) => {
    try {
      await createLoyaltyRedeemable(organizationId, {
        name: payload.name,
        discount_type: payload.discountType,
        cost: payload.pointValue,
        discount: payload.discount,
      })
      Toast.success({ message: 'Reward created successfully!' }, { autoClose: 3000 })
      await fetchLoyaltyRedeemables()
    } catch (err: any) {
      setError('Failed to create the reward')
      Toast.error({ message: `Failed to create the reward. Details: ${err?.response?.data?.errors || err}` }, { autoClose: 3000 })
    }
  }

  const updateReward = async (id: string, payload: any) => {
    try {
      await updateLoyaltyRedeemable(organizationId, id, {
        name: payload.name,
        discount_type: payload.discountType,
        cost: payload.pointValue,
        discount: payload.discount,
      })
      Toast.success({ message: 'Reward updated successfully!' }, { autoClose: 3000 })
      await fetchLoyaltyRedeemables()
    } catch (err: any) {
      setError('Failed to update the reward')
      Toast.error({ message: `Failed to update the reward. Details: ${err?.response?.data?.errors || err}` }, { autoClose: 3000 })
    }
  }

  const deleteReward = async (id: string) => {
    try {
      await deleteLoyaltyRedeemable(organizationId, id)
      Toast.success({ message: 'Reward deleted successfully!' }, { autoClose: 3000 })
      await fetchLoyaltyRedeemables()
    } catch (err: any) {
      setError('Failed to delete the reward')
      Toast.error({ message: `Failed to delete the reward. Details: ${err?.response?.data?.errors || err}` }, { autoClose: 3000 })
    }
  }

  return {
    loyaltyRedeemables,
    loading,
    error,
    createReward,
    updateReward,
    deleteReward,
  }
}

export default useLoyaltyRedeemables
