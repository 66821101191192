import styled from 'styled-components'
import { breakpoints, colors, inset0, shadow } from 'theme'

type ModalProps = { isVisible: boolean; width?: string; height?: string; borderRadius?: string }

export const Overlay = styled.div<ModalProps>(
  {
    position: 'fixed',
    ...inset0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 50,
    opacity: 0,
    transitionProperty: 'opacity',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '150ms',
    backgroundColor: `${colors.gray.darkest}c0`,
    visibility: 'hidden',
  },
  ({ isVisible }) =>
    isVisible && {
      visibility: 'visible',
      opacity: 1,
    },
)

export const ModalContainer = styled.div<ModalProps>(
  {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 50,
    top: '50%',
    left: '50%',
    maxWidth: 960,
    // padding: '0.75rem',
    // height: '-webkit-fill-available',
    // minHeight: '55%',
    borderRadius: '8px',
    gap: '40px',
    transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 150ms',
    transform: 'translate(-50%, -50%)',
    boxShadow: shadow.modal,
    backgroundColor: colors.white,
    opacity: 0,
    visibility: 'hidden',
    [`@media ${breakpoints.md}`]: {
      // padding: '1rem',
    },
    [`@media ${breakpoints.xxl}`]: {
      width: '60%',
    },
    [`@media (max-width: 600px)`]: {
      width: '95%',
    },
  },
  ({ isVisible }) =>
    isVisible && {
      visibility: 'visible',
      opacity: 1,
    },
  ({ width }) =>
    width && {
      width: width || '90%',
      maxWidth: width,
      [`@media ${breakpoints.xxl}`]: {
        width,
      },
    },
  ({ height }) =>
    height && {
      height,
    },
  ({ borderRadius, width }) =>
    borderRadius && {
      borderRadius: width !== '90%' ? 0 : borderRadius,
    },
)
