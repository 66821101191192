import styled from 'styled-components'

export const Container = styled.div({
  zIndex: 20,
})

export const Button = styled.button({
  padding: '1rem',
  ':focus': {
    outline: '2px solid transparent',
    outlineOffset: 2,
  },
})
