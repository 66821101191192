import { useContext } from 'react'
import { LocationContext } from '../context/LocationContext'
import { LocationContextType } from 'types/LocationContextTypes'

const useGetLocations = (): LocationContextType => {
  const context = useContext(LocationContext)
  if (!context) {
    throw new Error('useLocationContext must be used within a LocationProvider')
  }
  return context
}

export default useGetLocations