import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import styled from 'styled-components'

export const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 8px;
  max-width: 1280px;
  width: 100%;
  text-align: left;
`

export const MainText = styled.span`
  font-size: 25px;
  font-weight: 600;
  color: #333;
  line-height: 140%;
  font-family: Inter;
`

export const SubText = styled.span`
  font-size: 13px;
  color: #666;
  padding-top: 5px;
`

export const LocationIcon = styled(PlaceOutlinedIcon)`
  margin-right: 8px;
  color: #1b51a4;
  width: 16px !important;
  height: 16px !important;
  vertical-align: text-bottom;
`
