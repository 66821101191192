import * as Styled from './styles'

const SkeletonLoader = () => (
  <Styled.Container>
    <Styled.Animated>
      <Styled.SideRight>
        <Styled.SlateBar />
        <Styled.GridLine>
          <Styled.SlateBar colspan={2} />
          <Styled.SlateBar colspan={1} />
          <Styled.SlateBar colspan={3} />
        </Styled.GridLine>
      </Styled.SideRight>
    </Styled.Animated>
  </Styled.Container>
)

const ShortcutLoader = () => (
  <Styled.Container>
    <Styled.Animated>
      <Styled.SideRight>
        <Styled.ShortcutSlateBar />
      </Styled.SideRight>
    </Styled.Animated>
  </Styled.Container>
)

const PlatformLoader = () => (
  <Styled.PlatformContainer>
    <Styled.Animated>
      <Styled.SideRight>
        <Styled.PlatformGridLine>
          <Styled.PlatformSlateBar colspan={1} />
        </Styled.PlatformGridLine>
      </Styled.SideRight>
    </Styled.Animated>
  </Styled.PlatformContainer>
)

const SentenceLoader = () => (
  <Styled.Container>
    <Styled.Animated>
      <Styled.SideRight>
        <Styled.SlateBar />
      </Styled.SideRight>
    </Styled.Animated>
  </Styled.Container>
)

const TitleLoader = () => (
  <Styled.TitleContainer data-testid="skeleton-loader">
    <Styled.Animated>
      <Styled.SideRight>
        <Styled.PlatformGridLine>
          <Styled.SlateBar colspan={1} />
        </Styled.PlatformGridLine>
      </Styled.SideRight>
    </Styled.Animated>
  </Styled.TitleContainer>
)

export { SkeletonLoader, ShortcutLoader, PlatformLoader, SentenceLoader, TitleLoader }
