import { startCase } from 'lodash'
import moment from 'moment'
import { LoadingSkeleton } from 'components'
import {
  TableRow,
  TableRowLoading,
  TableRowNoData,
  TableRowName,
  TableCell,
  TableCellStatus,
  StatusBadge,
} from "./styles";
import { Check, Schedule, Block } from '@mui/icons-material';
import { EmailMarketingCampaign } from 'api/models';

// Status mappings
export const STATUS_MAPPINGS = {
  'draft': ['draft'],
  'pending_approval': ['pending_internal_approval', 'pending_approval'],
  'approved': ['internally_approved', 'approved', 'scheduled'],
  'sent': ['sending', 'sent'],
  'rejected': ['rejected', 'error']
};

export const Skeleton = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <TableRowLoading key={index}>
          <TableCell>
            <LoadingSkeleton />
          </TableCell>
        </TableRowLoading>
      ))}
    </>
  )
}

export const NoData = () => {
  return (
    <TableRowNoData>
      <TableCell centered fullWidth>
        No data
      </TableCell>
    </TableRowNoData>
  )
}

/**
 * Email Marketing Campaigns table Row
 */
export const CampaignItem = (props: { 
  item: EmailMarketingCampaign;
  onAction: (item: EmailMarketingCampaign) => void;
}) => {
  const { item, onAction } = props;
 
  return (
    <TableRow>
      <TableRowName onClick={() => onAction(item)}>{item?.name}</TableRowName>
      <TableCell>{item.subject}</TableCell>
      <TableCell>{item?.scheduled_at ? moment(item?.scheduled_at).format('MM/DD/YY h:mm A') : '-'}</TableCell>
      <TableCellStatus>
        <CampaignStatusBadge status={item.status} />
      </TableCellStatus>
    </TableRow>
  )
}

export const StatusIcon = ({ status } : { status: string }) => {
  let statusCategory;
  
  for (const [key, values] of Object.entries(STATUS_MAPPINGS)) {
    if (values.includes(status)) {
      statusCategory = key;
      break;
    }
  }
  
  switch (statusCategory) {
    case 'draft':
      return <Schedule style={{ color: '#1B51A4', fontSize: 14 }} />;
    case 'pending_approval':
      return <Schedule style={{ color: '#1B51A4', fontSize: 14 }} />;
    case 'approved':
      return <Check style={{ color: '#32965D', fontSize: 14 }} />;
    case 'sent':
      return <Check style={{ color: '#32965D', fontSize: 14 }} />;
    case 'rejected':
      return <Block style={{ color: '#C13030', fontSize: 14 }} />;
    default:
      return <Schedule style={{ color: '#1B51A4', fontSize: 14 }} />;
  }
};

export const CampaignStatusBadge = ({ status } : { status: string }) => (
  <StatusBadge status={status}>
    <StatusIcon status={status} />
    <span>{startCase(status)}</span>
  </StatusBadge>
);