import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import React, { useEffect, useState } from 'react'

import {
  BaseButton,
  StyledDialog,
  StyledIconButton,
  StyledSpan,
  StyledTextField,
  StyledTypography,
} from './styles'

interface UpsertRewardModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (
    rewardData: { name: string; discountType: string; pointValue: string; discount: string },
    isUpdate: boolean,
  ) => void
  initialData?: { name: string; discountType: string; pointValue: string; discount: string }
}

const UpsertRewardModal: React.FC<UpsertRewardModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialData,
}) => {
  const [rewardName, setRewardName] = useState(initialData?.name || '')
  const [discountType, setDiscountType] = useState(initialData?.discountType || 'fixed')
  const [pointValue, setPointValue] = useState(initialData?.pointValue || '')
  const [discount, setDiscount] = useState(initialData?.discount || '0')

  useEffect(() => {
    if (initialData) {
      setRewardName(initialData.name)
      setDiscountType(initialData.discountType)
      setPointValue(initialData.pointValue)
      setDiscount(initialData.discount)
    }
  }, [initialData])

  useEffect(() => {
    setRewardName(initialData?.name || '')
    setDiscountType(initialData?.discountType || 'fixed')
    setPointValue(initialData?.pointValue || '')
    setDiscount(initialData?.discount || '0')
  }, [isOpen])

  const handleSubmit = () => {
    onSubmit({ name: rewardName, discountType, pointValue, discount }, !!initialData)
    onClose()
  }

  const isValidDiscount = (value: string) => {
    // Check if the value is a valid number and greater than zero
    const numberValue = parseFloat(value)
    return !isNaN(numberValue) && numberValue > 0
  }

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
          <span>{initialData ? 'Update Reward' : 'Create Reward'}</span>
          <StyledIconButton edge='end' onClick={onClose} aria-label='close'>
            <CloseIcon />
          </StyledIconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <FormControl fullWidth required variant='outlined'>
            <StyledTypography>
              Reward Name <StyledSpan>(required)</StyledSpan>
            </StyledTypography>
            <StyledTextField
              value={rewardName}
              onChange={(e) => setRewardName(e.target.value)}
              inputProps={{ 'data-testid': 'reward-name-input' }}
            />
          </FormControl>
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box mb={2}>
            <FormControl fullWidth required>
              <StyledTypography>
                Discount <StyledSpan>(required)</StyledSpan>
              </StyledTypography>
              <StyledTextField
                value={discount}
                onChange={(e) => {
                  const value = e.target.value
                  // Allow numbers and dot for float values
                  if (/^[0-9]*\.?[0-9]*$/.test(value)) {
                    setDiscount(value)
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      {discountType === 'fixed' ? '$' : discountType === 'percentage' ? '%' : ''}
                    </InputAdornment>
                  ),
                  sx: {
                    marginRight: '16px',
                  },
                }}
                inputProps={{
                  'data-testid': 'discount-input'
                }}
              />
            </FormControl>
          </Box>
          <FormControl>
            <RadioGroup
              aria-labelledby='discount-type-label'
              value={discountType}
              onChange={(e) => setDiscountType(e.target.value)}
              name='discountType'
              row
            >
              <FormControlLabel value='fixed' control={<Radio />} label='Dollar ($)' />
              <FormControlLabel value='percentage' control={<Radio />} label='Percentage (%)' />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth required>
            <StyledTypography>
              Point Value Required <StyledSpan>(required)</StyledSpan>
            </StyledTypography>
            <StyledTextField
              inputProps={{ 'data-testid': 'point-value-input' }}
              value={pointValue}
              onChange={(e) => {
                const value = e.target.value
                // Allow only integer values
                if (/^\d*$/.test(value)) {
                  setPointValue(value)
                }
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '24px' }}>
        <BaseButton noBorder onClick={onClose}>
          Discard
        </BaseButton>
        <BaseButton
          disabled={!rewardName || !isValidDiscount(discount) || !pointValue}
          variant='solid'
          onClick={handleSubmit}
        >
          {initialData ? 'Update' : 'Create'}
        </BaseButton>
      </DialogActions>
    </StyledDialog>
  )
}

export default UpsertRewardModal
