import styled from 'styled-components'

export const PageContainer = styled.div({
  maxWidth: '1280px',
  width: '100%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  ['@media (max-width: 610px)']: {
    width: '100%',
    padding: "20px 0",
  },
})


export const Title = styled.h1({
  fontSize: 20,
  fontWeight: 600,
  marginBottom: 20,
  color: '#292929',
})

export const ToDoContainer = styled.div({
  width: '100%',
  margin: 'auto',
  padding: '20px 15px',
  background: '#FEFEFE',
  borderRadius: '8px',
})

export const CampaignsContainer = styled.div({
  width: '100%',
  margin: 'auto',
  padding: '20px 15px',
  borderRadius: '8px',
  background: '#FEFEFE',
})

export const ToDoTitle = styled.h2({
  fontSize: 16,
  fontWeight: 600,
  marginLeft: 6,
  marginBottom: 10,
  color: '#292929',
  [`@media (max-width: 440px)`]: {
    marginLeft: 2,
    fontSize: 14,
  },
})


export const LocationsContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;
`

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
`;

export const Tab = styled.button<{ active?: boolean }>`
  margin-right: 16px;
  padding: 6px 8px;
  background: ${({ active }) => (active ? 'rgba(27, 81, 164, 0.10)' : 'transparent')};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #1B51A4;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  text-align: center;
  font-family: Inter;
  &:hover {
    background: #f0f0f0;
  }
`;

export const LocationDropdownWrapper = styled.div`
  align-self: flex-start;
  width: 100%;
  maxWidth: 1000px;
`
