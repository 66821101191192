import { useGetLocations } from 'hooks'
import React from 'react'

import LoyaltyRewards from './LoyaltyRewards/LoyaltyRewards'
import {
  BoldText,
  Container,
  DescriptionText,
  RuleBox,
  RuleTitle,
  RuleValue,
  RulesCardBox,
  RulesGrid,
  SectionTitle,
  TitleCardBox,
  TitleTypography,
} from './styles'

const Loyalty: React.FC = () => {
  const { defaultLocation } = useGetLocations()
  const organizationName = defaultLocation?.organization_name || ''
  const organizationId = defaultLocation?.organization_id || ''

  return (
    <Container>
      <TitleCardBox>
        <TitleTypography>
          {organizationName ? `Loyalty Program - ${organizationName}` : 'Loyalty Program'}
        </TitleTypography>
        <DescriptionText>
          <BoldText>Chowly Loyalty</BoldText> allows you to easily manage and set up loyalty rewards
          for your diners.
        </DescriptionText>
      </TitleCardBox>

      <RulesCardBox>
        <SectionTitle>Loyalty Rules</SectionTitle>
        <RulesGrid>
          <RuleBox>
            <RuleTitle>Earning Type Rule</RuleTitle>
            <RuleValue>Dollar-per-point</RuleValue>
          </RuleBox>
          <RuleBox>
            <RuleTitle>Point Value</RuleTitle>
            <RuleValue>1 point for every $1 spent</RuleValue>
          </RuleBox>
        </RulesGrid>
      </RulesCardBox>

      <LoyaltyRewards organizationId={organizationId} />
    </Container>
  )
}

export default Loyalty
