import styled from 'styled-components';
import { Box } from '@mui/material';
import { breakpoints } from 'theme';
import { Heading } from '../styles';
export const CustomerInfoContainer = styled(Box)`
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
`;

export const CustomerInfoList = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const InfoLabel = styled('div')`
  // font-family: "Cerebri Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #95AAC9;
`;

export const InfoValue = styled('div')`
  // font-family: "Cerebri Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #12263F;
`;

export const CustomerInfoRow = styled(Box)`  display: flex;
  justify-content: space-between;
  padding: 8px 0;
`;

export const CenteredBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const StyledHeading = styled(Heading)`
  padding-left: 16px;
  display: flex;
  align-items: center;
`

export const MobileBackBox = styled(Box)`
  display: none;
  margin-right: 8px;
  
  @media ${breakpoints.xxs} {
    display: block;
  }
  
  @media ${breakpoints.lg} {
    display: none;
  }
`
