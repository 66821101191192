import {
  FormattedMenuSectionInfo,
  FormattedMenuSectionInfoWithParent,
  ItemModifier,
  MenuMappingItemDetails,
  MenuMappingSubsection,
  TypeName,
} from 'api'
import ArrowBackIcon from 'assets/icons/chevron-left.svg'
import ArrowForwardIcon from 'assets/icons/chevron-right-small.svg'
import CloseIcon from 'assets/icons/outline-icon-button-close.svg'
import EllipseIcon from 'assets/icons/outline-icon-button-ellipse.svg'
import IMAGE_PLACEHOLDER from 'assets/item_placeholder_img.svg'
import { ActionBar, Button } from 'components'
import Modal from 'features/modal/Modal'
import { useGetLocationPartners, useIsMobile } from 'hooks'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import theme from 'theme'
import { v4 as uuidv4 } from 'uuid'

import { ModifierValuesMap, getNestedCustomizationOptionAttributes } from '../MenuSectionEditor'
import ModifierSubsection from '../ModifierSubsection'
import ModifierSubsectionEditor from '../ModifierSubsectionEditor'
import * as Styled from '../styles'
import createNewMenuItem from '../util/createNewMenuItem'
import updateExistingMenuItem from '../util/updateExistingMenuItem'
import EditableModifierGroupForm from './EditableModifierGroupForm'
import { getNestedGroupModifierValues } from './NestedGroupAndMods'

type Props = {
  item?: FormattedMenuSectionInfo
  isOpen: boolean
  onClose: () => void
  isEditable?: boolean
  itemCount?: number
  totalItemsCount?: number
  setSelectedItemInModal?: (item: FormattedMenuSectionInfo) => void
  allMenuItems?: FormattedMenuSectionInfo[]
  setItemViewModalOpen?: (open: boolean) => void
  refreshMenuSection?: () => void
  subsectionId?: MenuMappingSubsection['id']
  checkedModifierIds?: ItemModifier['id'][]
  onModifierClick?: (modifierId: ItemModifier['id']) => void
  setSuspendUntilItem?: Dispatch<
    SetStateAction<
      | {
          id: MenuMappingItemDetails['id'] | ItemModifier['id']
          type: TypeName.Item | TypeName.CustomizationOption
        }
      | undefined
    >
  >
  checkSuspendUntilStatus?: (
    type: TypeName.Item | TypeName.CustomizationOption,
    id: MenuMappingItemDetails['id'] | ItemModifier['id'],
  ) => string
  expandAllModifiers?: boolean
}

export const NESTED_GROUP_PREFIX = 'parent_option'

export const getGroupModifierValues = (groupId: string, modifierValues: ModifierValuesMap) => {
  const groupMods: ModifierValuesMap = {}

  Object.entries(modifierValues).forEach(([modId, values]) => {
    if (values.parentGroupId === groupId) {
      groupMods[modId] = values
    }
  })

  return groupMods
}

export const createDefaultModInfo = ({
  group,
  isNested,
}: {
  group: FormattedMenuSectionInfo
  isNested: boolean
}) => {
  const groupId = group.id
  const tempModId = `temp-${uuidv4()}`

  const newModValues = {
    [tempModId]: {
      title: '',
      price: 0,
      parentGroupId: groupId,
      isNested,
    },
  }

  return newModValues
}

export const createDefaultGroupInfo = ({ parentModId }: { parentModId?: string }) => {
  let tempModGroupId = `temp-${uuidv4()}`

  if (parentModId) {
    tempModGroupId = `${NESTED_GROUP_PREFIX}:${parentModId}:${tempModGroupId}`
  }

  const newModGroupValues = {
    type: TypeName.MenuMappingCustomization,
    id: tempModGroupId,
    attributes: {
      title: '',
      min_permitted: 0,
      max_permitted: 0,
    },
    children: {
      customization_options: [],
    },
    parentModId,
  }

  return newModGroupValues
}

const validateGroupAndModCounts = (
  group: FormattedMenuSectionInfo,
  groupModifiers: ModifierValuesMap,
) => {
  // make sure we included at least the number of modifiers that are listed for minimum selection
  const { min_permitted: minCount } = group.attributes
  let validModCount = true

  if (minCount && minCount > Object.entries(groupModifiers).length) {
    validModCount = false
  }

  // disallow group creation with no mods. May change in the future.
  if (Object.keys(groupModifiers).length < 1) {
    validModCount = false
  }

  if (!validModCount) {
    toast.error(
      'The number of modifiers you created does not match the requirements, or you did not include at least one modifier. Please fix before proceeding.',
      { autoClose: 5000 },
    )
    return false
  }
  return true
}

const validatedAndFormattedNestedGroups = (
  nestedGroups?: FormattedMenuSectionInfo[],
  nestedMods?: ModifierValuesMap,
) => {
  const formattedNestedGroups: FormattedMenuSectionInfo[] = []

  nestedGroups?.forEach((nestedGroup) => {
    const nestedModsForGroup = getNestedGroupModifierValues(nestedGroup.id, nestedMods || {})
    const isValidGroup = validateGroupAndModCounts(nestedGroup, nestedModsForGroup)

    if (isValidGroup) {
      formattedNestedGroups.push({
        id: nestedGroup.id,
        type: TypeName.MenuMappingCustomization,
        attributes: {
          ...nestedGroup.attributes,
          min_permitted: nestedGroup.attributes.min_permitted ?? 0,
          max_permitted: nestedGroup.attributes.max_permitted ?? 0,
        },
        children: {
          customization_options: Object.entries(nestedModsForGroup).map(([id, values]) => {
            return {
              id,
              type: TypeName.MenuMappingCustomizationOption,
              attributes: {
                title: values.title,
                price: values.price,
              },
              children: {},
            }
          }),
        },
      })
    }
  })

  return formattedNestedGroups
}

// Combine Parent Group, Modifiers, Nested Groups and Nested Modifiers into one object
// Luckily we only go down 2 levels otherwise we'd need a recursive function. Could still refactor in the future.
const formatNestedMenuTree = ({
  group,
  modifiers, // these are already group specific
  nestedGroups,
  nestedModifiers,
}: {
  group: FormattedMenuSectionInfo
  modifiers: ModifierValuesMap
  nestedGroups?: FormattedMenuSectionInfoWithParent[]
  nestedModifiers?: ModifierValuesMap
}): { nestedTree: FormattedMenuSectionInfo; errors: string[] } => {
  const errors: string[] = []

  const combinedGroupAndModifierDetails = {
    ...group,
    attributes: {
      ...group.attributes,
      min_permitted: group.attributes.min_permitted ?? 0,
      max_permitted: group.attributes.max_permitted ?? 0,
    },
    children: {
      customization_options: Object.entries(modifiers).map(([modId, values]) => {
        const nestedGroupsForMod = nestedGroups?.filter((group) => group.parentModId === modId)
        const formattedNestedGroups = validatedAndFormattedNestedGroups(
          nestedGroupsForMod,
          nestedModifiers,
        )

        if (formattedNestedGroups.length !== nestedGroupsForMod?.length) {
          errors.push('Some of the nested groups did not meet the modifier quantity requirements.')
        }

        return {
          id: modId,
          type: TypeName.MenuMappingCustomizationOption,
          attributes: {
            title: values.title,
            price: values.price,
          },
          children: formattedNestedGroups.length
            ? {
                customizations: formattedNestedGroups,
              }
            : {},
        }
      }),
    },
  }

  return { nestedTree: combinedGroupAndModifierDetails, errors }
}

const MenuItemModal = ({
  item,
  isOpen,
  onClose,
  isEditable,
  itemCount,
  totalItemsCount,
  setSelectedItemInModal,
  allMenuItems,
  setItemViewModalOpen,
  refreshMenuSection,
  subsectionId,
  checkedModifierIds,
  onModifierClick,
  setSuspendUntilItem,
  checkSuspendUntilStatus,
  expandAllModifiers,
}: Props) => {
  const { menuMappingMenuId } = useGetLocationPartners()

  const { isMobile } = useIsMobile()
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [price, setPrice] = useState<number>()
  const [addModifierGroupOpen, setAddModifierGroupOpen] = useState<boolean>(false)
  const [allItemGroups, setAllItemGroups] = useState<FormattedMenuSectionInfo[]>([])
  const [tempCreatedGroups, setTempCreatedGroups] = useState<FormattedMenuSectionInfo[]>([])
  // Only stage one group and one set of modifiers for that group at one time.
  const [newModifierGroupDetails, setNewModifierGroupDetails] = useState<FormattedMenuSectionInfo>()
  // staged modifier prices. To get diff between original and new.
  const [originalModifierValues, setOriginalModifierValues] = useState<ModifierValuesMap>({})
  const [modifierValues, setModifierValues] = useState<ModifierValuesMap>({})
  const [isEditGroup, setEditGroup] = useState<string>()

  const leftButtonRef = useRef<HTMLButtonElement>(null)
  const rightButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (item) {
      setTitle(item.attributes.title || '')
      setDescription(item.attributes.description || '')
      setPrice(item.attributes.price)

      if (item.children.customizations) {
        setAllItemGroups(item.children.customizations)
      }

      // prefill modifier prices and save to get diff later
      let originalModValues: ModifierValuesMap = {}

      item.children.customizations?.forEach((modGroup) => {
        // get all nested modifier prices and store them on the same object
        const nestedCustomizationPrices: ModifierValuesMap = getNestedCustomizationOptionAttributes(
          { customization: modGroup, isNested: false },
        )

        originalModValues = { ...originalModValues, ...nestedCustomizationPrices }
      })

      setOriginalModifierValues(originalModValues)
      setModifierValues(originalModValues)
    }
  }, [item])

  // modifier values are an editable object of all attributes in modifiers/nested mods that can be changed.
  // If we create or delete a group, we need to update the modifiers object.
  const updateModifiersForItemGroups = () => {
    // refresh modifier values in case we added a new group
    let modValues: ModifierValuesMap = {}

    allItemGroups.forEach((modGroup) => {
      // get all nested modifier values and store them on the same object
      const nestedCustomizationValues: ModifierValuesMap = getNestedCustomizationOptionAttributes({
        customization: modGroup,
        isNested: false,
      })

      modValues = { ...modValues, ...nestedCustomizationValues }
    })

    setModifierValues(modValues)
  }

  useEffect(() => {
    if (allItemGroups) {
      updateModifiersForItemGroups()
    }
  }, [allItemGroups])

  const onSelectNewItem = (newItemIdx: number) => {
    if (!allMenuItems) {
      return
    }
    const item = allMenuItems[newItemIdx]

    if (item && setSelectedItemInModal) {
      setSelectedItemInModal(item)
    }
  }

  // Allows for hotkeys on left/right buttons
  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement> | KeyboardEvent) => {
    if (itemCount === undefined || totalItemsCount === undefined) {
      return
    }

    if (e.key === 'ArrowRight' && itemCount < totalItemsCount) {
      e.preventDefault()
      if (rightButtonRef.current) {
        rightButtonRef.current.focus()
      }
      // since we're looking at the index, we don't add a 1, it's already the right value.
      const newItemIdx = itemCount
      onSelectNewItem(newItemIdx)
    }

    if (e.key === 'ArrowLeft' && itemCount > 1) {
      e.preventDefault()
      if (leftButtonRef.current) {
        leftButtonRef.current.focus()
      }

      // previous item and -1 for index.
      const newItemIdx = itemCount - 1 - 1
      onSelectNewItem(newItemIdx)
    }
  }

  // since we create one modifier at a time for a group, we transfer values from state to this new object which will make it easier to format the request.
  const handleStageModifierGroup = ({
    nestedGroups,
    nestedModifiers,
    isNew,
  }: {
    nestedGroups: FormattedMenuSectionInfo[]
    nestedModifiers: ModifierValuesMap
    isNew?: boolean
  }) => {
    if (newModifierGroupDetails) {
      const groupModifiers: ModifierValuesMap = getGroupModifierValues(
        newModifierGroupDetails.id,
        modifierValues,
      )

      const isValidGroup = validateGroupAndModCounts(newModifierGroupDetails, groupModifiers)

      if (!isValidGroup) {
        return
      }

      // Combine Parent Group, Modifiers, Nested Groups and Nested Modifiers into one object
      const { nestedTree: combinedGroupAndModifierDetails, errors } = formatNestedMenuTree({
        group: newModifierGroupDetails,
        modifiers: groupModifiers,
        nestedGroups,
        nestedModifiers,
      })

      if (errors.length) {
        toast.error(`Please fix the following errors to add a new group: ${errors.toString()}`)
        return
      }

      if (isNew) {
        const allTempGroups = [...tempCreatedGroups, combinedGroupAndModifierDetails]
        setTempCreatedGroups(allTempGroups) // store only temps
        setAllItemGroups([...allItemGroups, combinedGroupAndModifierDetails]) // shows in the main mods table
      } else {
        // if group is not new, we are just replacing its values.
        const updatedItemGroups = allItemGroups.map((group) => {
          if (group.id === newModifierGroupDetails.id) {
            return combinedGroupAndModifierDetails
          }
          return group
        })

        setAllItemGroups(updatedItemGroups)
      }

      // now that we appended the values to all groups, clear the group creation box and close it
      setNewModifierGroupDetails(undefined)
      setAddModifierGroupOpen(false)
      setEditGroup(undefined)
    }
  }

  // creates a default temp group object
  const createNewTempModifierGroup = () => {
    const newModGroupValues = createDefaultGroupInfo({})

    setNewModifierGroupDetails(newModGroupValues)
  }

  const createNewGroupModifier = (group: FormattedMenuSectionInfo) => {
    const newModValues = createDefaultModInfo({ group, isNested: false })

    const updatedModValues = {
      ...modifierValues,
      ...newModValues,
    }

    setModifierValues(updatedModValues)
  }

  // these are already in a group that was added to the main object, but not saved to db yet. Remove the specific modifier from our new staged group.
  // removing it from this tree will trigger a side effect to remove it from the main modifiers state object
  const removeModifierFromStagedGroup = (
    modId: string,
    customization: FormattedMenuSectionInfo,
  ) => {
    const modifiedGroups = [...allItemGroups].map((group) => {
      if (group.id === customization.id) {
        const groupMods = group?.children.customization_options

        if (groupMods) {
          const minMods = group.attributes.min_permitted

          if (groupMods.length - 1 < 1) {
            toast.error(`A group requires at least at least 1 modifier.`, { autoClose: 5000 })
            return group
          }

          if (minMods !== undefined && minMods > groupMods.length - 1) {
            toast.error(
              'The modifier group minimum count is set. Change or remove the minimum count to delete this modifier.',
            )
            return group
          }

          const modToRemoveIdx = groupMods.findIndex((mod) => mod.id === modId)

          if (modToRemoveIdx !== -1) {
            groupMods.splice(modToRemoveIdx, 1)
          }
        }
      } else {
        // the main group doesn't match, check if it's modifiers have groups that do since the group could be nested
        if (group.children.customization_options?.length) {
          group.children.customization_options.map((mod) => {
            if (mod.children.customizations?.length) {
              return mod.children.customizations.map((nestedGroup) => {
                if (nestedGroup.id === customization.id) {
                  const groupMods = nestedGroup?.children.customization_options

                  if (groupMods) {
                    const minMods = group.attributes.min_permitted

                    if (groupMods.length - 1 < 1) {
                      toast.error(`A group requires at least at least 1 modifier.`, {
                        autoClose: 5000,
                      })
                      return nestedGroup
                    }

                    if (minMods !== undefined && minMods > groupMods.length - 1) {
                      toast.error(
                        'The modifier group minimum count is set. Change or remove the minimum count to delete this modifier.',
                      )
                      return nestedGroup
                    }

                    const modToRemoveIdx = groupMods.findIndex((mod) => mod.id === modId)

                    if (modToRemoveIdx !== -1) {
                      groupMods.splice(modToRemoveIdx, 1)
                    }
                  }
                }
                return nestedGroup
              })
            }
            return mod
          })
        }
      }

      return group
    })

    setAllItemGroups(modifiedGroups)
  }

  const removeNewGroupModifier = ({
    group,
    modId,
  }: {
    group: FormattedMenuSectionInfo
    modId: string
  }) => {
    const minCount = group?.attributes.min_permitted
    const groupMods: ModifierValuesMap = getGroupModifierValues(group.id, modifierValues)

    if (
      minCount === undefined ||
      (minCount !== undefined && minCount <= Object.keys(groupMods).length - 1)
    ) {
      const updatedMods = { ...modifierValues }
      delete updatedMods[modId]

      setModifierValues(updatedMods)
    }

    if (minCount !== undefined && minCount > Object.keys(groupMods).length - 1) {
      toast.error(
        'The modifier group minimum count is set. Change or remove the minimum count to delete this modifier.',
        { autoClose: 5000 },
      )
    }
  }

  const getModifierValuesDifference = ({
    newMods,
    originalMods,
  }: {
    newMods: ModifierValuesMap
    originalMods: ModifierValuesMap
  }) => {
    // extract the ones that have changed
    const modifiersToAdd: ModifierValuesMap = {}
    const modifiersToDelete: ModifierValuesMap = {}
    const modifiersToUpdate: ModifierValuesMap = {}

    Object.entries(newMods).forEach(([id, values]) => {
      if (id.startsWith('temp') && !originalMods[id]) {
        modifiersToAdd[id] = values
      }

      if (originalMods[id]) {
        if (
          originalMods[id].price !== newMods[id].price ||
          originalMods[id].title !== newMods[id].title
        ) {
          modifiersToUpdate[id] = values
        }
      }
    })

    Object.entries(originalMods).forEach(([id, values]) => {
      if (!newMods[id]) {
        modifiersToDelete[id] = values
      }
    })

    return { modifiersToAdd, modifiersToDelete, modifiersToUpdate }
  }

  const getCustomizationValuesDifference = ({
    updatedGroups,
    originalGroups,
  }: {
    updatedGroups?: FormattedMenuSectionInfo[]
    originalGroups?: FormattedMenuSectionInfo[]
  }) => {
    const groupsToAdd: FormattedMenuSectionInfo[] = []
    const groupsToDelete: FormattedMenuSectionInfo[] = []
    const groupsToUpdate: FormattedMenuSectionInfo[] = []

    updatedGroups?.forEach((newGroup) => {
      const matchingOriginalGroup = originalGroups?.find((group) => group.id === newGroup.id)

      if (!matchingOriginalGroup) {
        groupsToAdd.push(newGroup)
      } else {
        // if only attributes have changed, we are updating
        if (
          matchingOriginalGroup.attributes.title !== newGroup.attributes.title ||
          matchingOriginalGroup.attributes.min_permitted !== newGroup.attributes.min_permitted ||
          matchingOriginalGroup.attributes.max_permitted !== newGroup.attributes.max_permitted
        ) {
          groupsToUpdate.push(newGroup)
        }
      }

      // check nested groups for updates
      newGroup.children.customization_options?.forEach((mod) => {
        const matchingOriginalMod = matchingOriginalGroup?.children.customization_options?.find(
          (originalMod) => originalMod.id === mod.id,
        )

        mod.children.customizations?.forEach((newNestedGroup) => {
          const matchingOriginalNestedGroup = matchingOriginalMod?.children.customizations?.find(
            (oldNestedGroup) => oldNestedGroup.id === newNestedGroup.id,
          )
          if (matchingOriginalNestedGroup) {
            // check if we need to update the groups fields
            if (
              newNestedGroup.attributes.title !== matchingOriginalNestedGroup.attributes.title ||
              newNestedGroup.attributes.min_permitted !==
                matchingOriginalNestedGroup.attributes.min_permitted ||
              newNestedGroup.attributes.max_permitted !==
                matchingOriginalNestedGroup.attributes.max_permitted
            ) {
              groupsToUpdate.push(newNestedGroup)
            }
          } else {
            // no matching group, need to create a new one.
            groupsToAdd.push(newNestedGroup)
          }
        })
      })
    })

    // groups that exist only in original need to be deleted.
    originalGroups?.forEach((oldGroup) => {
      const matchingNewGroup = updatedGroups?.find((group) => group.id === oldGroup.id)

      if (matchingNewGroup) {
        // check if we need to delete nested groups
        oldGroup.children.customization_options?.forEach((oldMod) => {
          const matchingNewMod = matchingNewGroup.children.customization_options?.find(
            (newMod) => newMod.id === oldMod.id,
          )

          if (matchingNewMod) {
            oldMod.children.customizations?.forEach((oldNestedGroup) => {
              const matchingNewModGroup = matchingNewMod.children.customizations?.find(
                (newNestedGroup) => newNestedGroup.id === oldNestedGroup.id,
              )

              // the nested group doesn't exist anymore in the new object, delete it
              if (!matchingNewModGroup) {
                groupsToDelete.push(oldNestedGroup)
              }
            })
          }
        })
      } else {
        groupsToDelete.push(oldGroup)
      }
    })

    return { groupsToAdd, groupsToDelete, groupsToUpdate }
  }

  // Modifiers that were being added to a new group, but then then group creation was canceled. We need to remove them from the modifierValues obj.
  const removeStagedModifierValues = (tempGroupId: string) => {
    const modsInTempGroup = getGroupModifierValues(tempGroupId, modifierValues)
    const modIds = Object.keys(modsInTempGroup)

    const updatedMods = { ...modifierValues }
    modIds.forEach((id) => {
      delete updatedMods[id]
    })
    setModifierValues(updatedMods)
  }

  /**
   *  We are either:
   * 1. Saving a brand new item with it's groups and modifiers
   * 2. Editing an existing item
   * */
  const handleSaveItem = async () => {
    // ensure item title exists, otherwise the item won't show up in the menu.
    if (!title) {
      toast.error('Please add a product title before saving your product.', { autoClose: 5000 })
      return
    }

    // gets diff of top level and nested modifiers
    const { modifiersToAdd, modifiersToDelete, modifiersToUpdate } = getModifierValuesDifference({
      newMods: modifierValues,
      originalMods: originalModifierValues,
    })

    // compares diff of top level and nested groups
    const { groupsToAdd, groupsToDelete, groupsToUpdate } = getCustomizationValuesDifference({
      updatedGroups: allItemGroups,
      originalGroups: item?.children.customizations || [],
    })

    // Editing existing item
    if (item && menuMappingMenuId) {
      // price is stored in a join table so we need to update it via subsection_items
      let priceAttribute = {}
      if (price !== item.attributes.price) {
        priceAttribute = { subsection_items: { subsection_id: subsectionId, item_price: price } }
      }

      const updateRes = await updateExistingMenuItem({
        itemId: item.id,
        menuId: menuMappingMenuId,
        attributes: { title, description, ...priceAttribute },
        modifiersToAdd,
        modifiersToDelete,
        modifiersToUpdate,
        modifierGroupsToAdd: groupsToAdd,
        modifierGroupsToUpdate: groupsToUpdate,
        modifierGroupsToDelete: groupsToDelete,
      })
      if (updateRes) {
        toast.success('Your product was updated.', { autoClose: 5000 })
        if (refreshMenuSection) {
          refreshMenuSection()
        }
        if (setItemViewModalOpen) {
          setItemViewModalOpen(false)
        }
      } else {
        toast.error('Failed to update product.', { autoClose: 5000 })
      }
    } else {
      // create new item
      if (menuMappingMenuId && subsectionId) {
        const createRes = await createNewMenuItem({
          menuId: menuMappingMenuId,
          attributes: { title, description },
          modifiersToAdd,
          modifierGroupsToAdd: groupsToAdd,
          subsectionId,
        })

        if (createRes) {
          toast.success('Your product was created.', { autoClose: 5000 })
          if (refreshMenuSection) {
            refreshMenuSection()
          }
          if (setItemViewModalOpen) {
            setItemViewModalOpen(false)
          }
        } else {
          toast.error('Failed to create product.', { autoClose: 5000 })
        }
      }
    }
  }

  // listens to the keydown event.
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  return (
    <Modal width='100%' height='100%' isOpen={isOpen} onClose={onClose}>
      <ActionBar position='top' padding='0 16px'>
        <Styled.ButtonContainer>
          <Styled.IconButton margin='auto 0 auto auto' type='button' onClick={onClose}>
            <img src={CloseIcon} alt='close' />
          </Styled.IconButton>
        </Styled.ButtonContainer>
      </ActionBar>
      <div
        style={{
          overflow: 'scroll',
          width: '100%',
          marginTop: '64px',
          marginBottom: isEditable ? '64px' : 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Styled.ItemViewContainer isMobile={isMobile}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 5px' }}>
            {item?.attributes.title && <Styled.Subtitle>{item.attributes.title}</Styled.Subtitle>}
            {item && itemCount && totalItemsCount ? (
              <div style={{ display: 'flex' }}>
                <Styled.IconButton
                  type='button'
                  ref={leftButtonRef}
                  disabled={itemCount <= 1}
                  onClick={() => {
                    if (itemCount > 1) {
                      // previous item and -1 for index.
                      const newItemIdx = itemCount - 1 - 1
                      onSelectNewItem(newItemIdx)
                    }
                  }}
                  onKeyDown={onKeyDown}
                >
                  <img src={ArrowBackIcon} alt='arrow back' width={24} height={24} />
                </Styled.IconButton>
                <Styled.ItemViewCount>{`${itemCount}/${totalItemsCount}`}</Styled.ItemViewCount>
                <Styled.IconButton
                  type='button'
                  ref={rightButtonRef}
                  disabled={itemCount >= totalItemsCount}
                  onClick={() => {
                    if (itemCount < totalItemsCount) {
                      // need to pass in index so don't need to add a 1.
                      onSelectNewItem(itemCount)
                    }
                  }}
                  onKeyDown={onKeyDown}
                >
                  <img src={ArrowForwardIcon} alt='arrow next' width={24} height={24} />
                </Styled.IconButton>
              </div>
            ) : null}
          </div>
          <div
            style={{
              background: theme.colors.gray[450],
              height: 'auto',
              width: '100%',
              padding: '24px',
            }}
          >
            <div style={{ display: 'flex', gap: 24 }}>
              <Styled.ItemViewImageContainer>
                <Styled.ItemViewProductTitle>Product Image</Styled.ItemViewProductTitle>
                <Styled.ItemViewImageBox>
                  <div style={{ height: '100%' }}>
                    <img
                      src={item?.attributes.image_url || IMAGE_PLACEHOLDER}
                      alt='product'
                      style={{ borderRadius: 9.6, height: '48px', width: 'auto', margin: 'auto' }}
                    />
                  </div>
                  <div style={{ position: 'absolute', bottom: '12px', right: '12px' }}>
                    <Styled.IconButton>
                      <img src={EllipseIcon} alt='upload' />
                    </Styled.IconButton>
                  </div>
                </Styled.ItemViewImageBox>
                <Styled.ItemViewProductDescription>
                  Logo image that is shown on the top of every page. Recommended minimum resolution:
                  500px x 500px. Accepted file formats: .png, .jpg, .webP.{' '}
                </Styled.ItemViewProductDescription>
              </Styled.ItemViewImageContainer>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                  flexBasis: isMobile ? '50%' : undefined,
                }}
              >
                <Styled.ItemViewProductTitle>Product Title</Styled.ItemViewProductTitle>
                <Styled.Input
                  height='47px'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  disabled={!isEditable}
                />
                <Styled.ItemViewProductTitle>Product Description</Styled.ItemViewProductTitle>
                <Styled.TextArea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={5}
                  disabled={!isEditable}
                />
                <Styled.ItemViewProductTitle>Product Price</Styled.ItemViewProductTitle>
                <Styled.Input
                  height='47px'
                  type='number'
                  min='0'
                  step='any'
                  value={price}
                  onChange={(e) => setPrice(parseFloat(e.target.value))}
                  disabled={!isEditable}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              background: theme.colors.gray[450],
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: 24, padding: '24px' }}>
              <Styled.ItemViewProductTitle>Modifiers</Styled.ItemViewProductTitle>
              <Styled.MenuTable padding='0'>
                {allItemGroups?.map((customization) => {
                  if (isEditable) {
                    if (isEditGroup === customization.id && newModifierGroupDetails) {
                      // Editing an existing group
                      return (
                        <EditableModifierGroupForm
                          key={customization.id}
                          modifierGroupDetails={newModifierGroupDetails}
                          setModifierGroupDetails={setNewModifierGroupDetails}
                          handleStageModifierGroup={handleStageModifierGroup}
                          modifierValues={modifierValues}
                          setModifierValues={setModifierValues}
                          removeGroupModifier={removeNewGroupModifier}
                          createGroupModifier={createNewGroupModifier}
                          handleCancelEditGroup={() => {
                            setEditGroup(undefined)
                            setNewModifierGroupDetails(undefined)
                          }}
                          isNew={false}
                        />
                      )
                    } else {
                      return (
                        <ModifierSubsectionEditor
                          key={customization.id}
                          customization={customization}
                          groupModifiers={customization.children.customization_options}
                          modifierValues={modifierValues}
                          isItemModal
                          onChangeModifierValues={({ modId, price, title }) =>
                            setModifierValues({
                              ...modifierValues,
                              [modId]: {
                                ...modifierValues[modId],
                                price: price,
                                title: title || '',
                              },
                            })
                          }
                          onDeleteModifier={(modId, parentGroup) =>
                            removeModifierFromStagedGroup(modId, parentGroup)
                          }
                          setEditGroup={(groupId) => {
                            // prefill temporary group details
                            setNewModifierGroupDetails(customization)
                            setEditGroup(groupId)
                          }}
                        />
                      )
                    }
                  } else {
                    return (
                      <ModifierSubsection
                        key={customization.id}
                        subsection={customization}
                        groupModifiers={customization.children.customization_options}
                        checkedModifierIds={checkedModifierIds}
                        onModifierClick={onModifierClick}
                        setSuspendUntilItem={setSuspendUntilItem}
                        checkSuspendUntilStatus={checkSuspendUntilStatus}
                        expandAllModifiers={expandAllModifiers}
                      />
                    )
                  }
                })}
              </Styled.MenuTable>
              {/* Creating a new group. If we are editing an existing group, we don't want to show this option. */}
              {isEditable && !isEditGroup && (
                <div>
                  {addModifierGroupOpen && newModifierGroupDetails ? (
                    <EditableModifierGroupForm
                      modifierGroupDetails={newModifierGroupDetails}
                      setModifierGroupDetails={setNewModifierGroupDetails}
                      handleStageModifierGroup={handleStageModifierGroup}
                      modifierValues={modifierValues}
                      setModifierValues={setModifierValues}
                      removeGroupModifier={removeNewGroupModifier}
                      createGroupModifier={createNewGroupModifier}
                      handleCancelEditGroup={() => {
                        setNewModifierGroupDetails(undefined)
                        removeStagedModifierValues(newModifierGroupDetails.id)
                        setAddModifierGroupOpen(false)
                      }}
                      isNew={true}
                    />
                  ) : (
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                      <Button
                        size='small'
                        narrow
                        onClick={() => {
                          createNewTempModifierGroup()
                          setAddModifierGroupOpen(true)
                        }}
                      >
                        Add Modifier Group
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Only show this if there are changes to the item */}
        </Styled.ItemViewContainer>
      </div>
      {isEditable && (
        <ActionBar position='bottom'>
          <Styled.ButtonContainer>
            <Button variant='textonly' narrow onClick={onClose}>
              Cancel
            </Button>
            <Button variant='solid' narrow onClick={handleSaveItem}>
              Save
            </Button>
          </Styled.ButtonContainer>
        </ActionBar>
      )}
    </Modal>
  )
}

export default MenuItemModal
