import React, { useState, useEffect, useRef, ChangeEvent } from 'react'
import { debounce } from 'lodash'
import { Spinner } from 'components'
import { v4 as uuidv4 } from 'uuid'
import { showError } from 'utils'
import * as Styled from './styles'
import { Location, LocationSummary } from 'types'
import { useGetLocations } from 'hooks'

type SingleLocationDropdownProps = {
  onLocationChange: (location: Location) => void,
}

const SingleLocationDropdown: React.FC<SingleLocationDropdownProps> = ({
  onLocationChange,
}) => {
  const { locations, isLoading, hasMore, defaultLocation, isAdminUser, searchLocations } = useGetLocations()
  const [filteredLocations, setFilteredLocations] = useState<LocationSummary[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [showDropDown, setShowDropDown] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState<Location>({
    id: defaultLocation?.id.toString() || '',
    name: defaultLocation?.name || '',
    organization_id: defaultLocation?.organization_id,
    organization_name: defaultLocation?.organization_name,
    timezone: defaultLocation?.timezone,
  })  
  const [page, setPage] = useState(1)
  const selectWrapperRef = useRef<HTMLDivElement | null>(null)
  const dropdownResultsRef = useRef<HTMLDivElement | null>(null)
  const searchRef = useRef<HTMLInputElement | null>(null)
  const activeRequestId = useRef<string | null>(null)
  const hasFetched = useRef<boolean>(false)
  const filterBoxRef = useRef<HTMLInputElement | null>(null)
  
  // Debounced filter for location search
  const debounceFetch = debounce(async (currentPage: number, term: string, requestId: string) => {
    // Ensure this is the latest request
    if (requestId !== activeRequestId.current) return

    try {
      const newLocations = await searchLocations(currentPage, term)
      setFilteredLocations((prev) =>
        currentPage === 1 ? newLocations : [...prev, ...newLocations],
      )
    } catch (error) {
      if (requestId === activeRequestId.current) showError(error)
    } finally {
      setPage(currentPage)
    }
  }, 300)

  const debounceWithRequestId = (nextPage: number, term: string) => {
    const requestId = uuidv4()
    activeRequestId.current = requestId
    debounceFetch(nextPage, term, requestId)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value
    setFilteredLocations([])
    setSearchTerm(term)
    setPage(1)
    debounceWithRequestId(1, term)
  }

  const resetSearch = () => {
    setSearchTerm('')
    setPage(1)
    isAdminUser ? debounceWithRequestId(1, '') : setFilteredLocations(locations)
    setShowDropDown(true)
  }

  const handleLocationSelect = (location: LocationSummary) => {
    const { id, name, organization_id, organization_name, timezone } = location
    onLocationChange({ id: id.toString(), name, organization_id, organization_name, timezone })
    setSelectedLocation({ id: id.toString(), name, organization_id, organization_name, timezone })
    setSearchTerm(location.name)
    setShowDropDown(false)
  }

  const handleScroll = () => {
    if (
      searchRef.current &&
      searchRef.current.scrollTop + searchRef.current.clientHeight >=
        searchRef.current.scrollHeight - 20 &&
      !isLoading && hasMore
    ) {
      const nextPage = page + 1
      debounceWithRequestId(nextPage, searchTerm)
    }
  }

  useEffect(() => {
    const container = searchRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [hasMore, isLoading, page, searchTerm, showDropDown])

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectWrapperRef.current &&
        !selectWrapperRef.current.contains(event.target as Node) &&
        dropdownResultsRef.current &&
        !dropdownResultsRef.current.contains(event.target as Node)
      ) {
        setShowDropDown(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  // Set default location if available
  useEffect(() => {
    if (defaultLocation) {
      setSelectedLocation(defaultLocation)
    }
  }, [defaultLocation])

  useEffect(() => {
    if (selectedLocation.id !== '') {  // Ensure id is not null or undefined
      const selectedLocationFromList = locations.find((location: LocationSummary) => location.id.toString() === selectedLocation.id)
      if (selectedLocationFromList) {
        const { name, organization_id, organization_name, timezone } = selectedLocationFromList
        setSelectedLocation(prev => ({
          ...prev,
          name,
          organization_id,
          organization_name,
          timezone,
        }))
      }
    }
  }, [locations, selectedLocation.id])
  

  // Ensure the effect runs only once when data is available
  useEffect(() => {
    if (locations && locations.length > 0 && !hasFetched.current) {
      hasFetched.current = true
      setFilteredLocations(locations)
    }
  }, [locations])

  // focus the input when dropdown is shown
  useEffect(() => {
    if (showDropDown && filterBoxRef.current) {
      filterBoxRef.current.focus()
      if (searchTerm) {
        filterBoxRef.current.select()
      }
    }
  }, [showDropDown])

  return (
    <Styled.Container>
      <Styled.DropdownWrapper>
        <Styled.SelectWrapper 
          ref={selectWrapperRef}
          onClick={() => setShowDropDown(prev => !prev)}
          isActive={showDropDown}
        >
          <Styled.SelectBox>
            <Styled.TextWrapper>
              <Styled.Input
                value={`Location `}
                readOnly={true}
              />
              <Styled.InputBoldText>{selectedLocation.name}</Styled.InputBoldText>
            </Styled.TextWrapper>
            <Styled.DownArrow $isExpanded={showDropDown} />
          </Styled.SelectBox>
        </Styled.SelectWrapper>

        {showDropDown && (
          <Styled.DropdownResults ref={dropdownResultsRef}>
            <Styled.FilterContainer>
              <Styled.FilterBox 
                ref={filterBoxRef}
                type="text" 
                value={searchTerm} 
                onChange={handleInputChange} 
                placeholder="Filter locations"
              />
              {!!searchTerm.length && (
                <Styled.ClearButton onClick={resetSearch} />
              )}
            </Styled.FilterContainer>
            <Styled.ResultsWrapper ref={searchRef} data-testid='search-results'>
              {filteredLocations.map((location: LocationSummary) => (
                <Styled.Result
                  key={location.id}
                  isActive={location.id.toString() === selectedLocation.id}
                  onClick={() => handleLocationSelect(location)}
                >
                  {location.name}
                </Styled.Result>
              ))}
              {isLoading && <Styled.SpinnerWrapper><Spinner /></Styled.SpinnerWrapper>}
              {filteredLocations.length === 0 && !isLoading && searchTerm.length > 0 && (
                <Styled.NoResults>No locations found.</Styled.NoResults>
              )}
            </Styled.ResultsWrapper>
          </Styled.DropdownResults>
        )}
      </Styled.DropdownWrapper>
    </Styled.Container>
  )
}

export default SingleLocationDropdown