import { sigmaPages } from 'config'
import { useEmbedUrl } from 'hooks'

import { ReportContainer, ReportFrame } from './styles'

type Props = {
  partnerId?: string
}

const TopCustomersListReport = ({ partnerId }: Props) => {
  const url = useEmbedUrl(sigmaPages.topCustomersListReport, partnerId)

  return (
    <ReportContainer>
      <ReportFrame src={url} title='Top Customers List' />
    </ReportContainer>
  )
}

export default TopCustomersListReport
