import { TitleLoader } from 'components'
import { useGetLocations } from 'hooks'
import React from 'react'
import { useLocation } from 'react-router-dom'
import * as url from 'utils/urls'

import { BreadcrumbContainer, LocationIcon, MainText, SubText } from './styles'

const routeNames: { [key: string]: { name: string; showLocationBreadcrumb: boolean } } = {
  /** Nested routes go first */
  [url.getAnalyticsLocationsURL()]: { name: 'Locations', showLocationBreadcrumb: false },
  [url.getAnalyticsWowURL()]: { name: 'Week Over Week', showLocationBreadcrumb: false },
  [url.getAnalyticsSalesURL()]: { name: 'Sales', showLocationBreadcrumb: false },
  [url.getAnalyticsOrdersURL()]: { name: 'Orders', showLocationBreadcrumb: false },
  [url.getAnalyticsDeliveryBuyDownURL()]: {
    name: 'Delivery Buy Down',
    showLocationBreadcrumb: false,
  },
  [url.getAnalyticsProductMixURL()]: { name: 'Product Mix', showLocationBreadcrumb: false },
  [url.getAnalyticsSmartPricingURL()]: { name: 'Smart Pricing', showLocationBreadcrumb: false },
  [url.getAnalyticsReconciliationURL()]: {
    name: 'Reconciliation Report',
    showLocationBreadcrumb: false,
  },
  [url.getAnalyticsDisputesURL()]: { name: 'Disputes Report', showLocationBreadcrumb: false },
  [url.getAnalyticsDMReportingURL()]: {
    name: 'Paid Ads Spend Overview',
    showLocationBreadcrumb: false,
  },
  [url.getMenuManagementNewURL('')]: { name: 'Create New Menu', showLocationBreadcrumb: false },

  /** Simple routes go here */
  [url.getAnalyticsURL()]: { name: 'Analytics', showLocationBreadcrumb: false },
  [url.getMenuManagementURL('')]: { name: 'Menu Management', showLocationBreadcrumb: true },
  [url.getMenuManagementPlatformURL('')]: { name: 'Manage Platform', showLocationBreadcrumb: true },
  [url.getAddLocationURL()]: { name: 'Add New Location', showLocationBreadcrumb: true },
  [url.getBillingURL()]: { name: 'Billing', showLocationBreadcrumb: true },
  [url.getOrderManagementURL()]: { name: 'Order Management', showLocationBreadcrumb: false },
  [url.getDigitalMarketingURL()]: { name: 'Digital Marketing', showLocationBreadcrumb: false },
  [url.getHealthURL()]: { name: 'Home', showLocationBreadcrumb: true },
  [url.getCustomerSurveysURL()]: { name: 'Customer Surveys', showLocationBreadcrumb: true },
  [url.getPromoCodesURL()]: { name: 'Promo Codes', showLocationBreadcrumb: false },
  [url.getPromoCodesNewURL()]: { name: 'Create Coupon', showLocationBreadcrumb: false },
  // Add additional routes as needed
}

const BreadcrumbHeader: React.FC = () => {
  const { pathname } = useLocation()
  const { defaultLocation } = useGetLocations()

  // Derive module name from route
  const currentPath = Object.keys(routeNames).find(
    (key) => (key !== '/' && pathname.includes(key)) || key === pathname,
  )

  const moduleName = currentPath ? routeNames[currentPath].name : 'Unknown Module'

  // Skip title name for subitems
  const skipNames = ['promo-codes', 'loyalty-setup']
  const showName = !skipNames.some(skipName => pathname.includes(skipName))

  // Show location only on pages that have the SingleLocationDropdown component
  const showLocation = currentPath
    ? routeNames[currentPath]?.showLocationBreadcrumb || false
    : false

  return (
    <BreadcrumbContainer>
      {showName && (
        <MainText data-testid='module-name'>{moduleName}</MainText>
      )}
      {showLocation && (
        <SubText data-testid='location-text'>
          <LocationIcon />
          {defaultLocation ? (
            <>
              {defaultLocation?.organization_name} / {defaultLocation?.name}
            </>
          ) : (
            <TitleLoader />
          )}
        </SubText>
      )}
    </BreadcrumbContainer>
  )
}

export default BreadcrumbHeader
