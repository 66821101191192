import { Box, BoxProps, Typography } from '@mui/material'
import styled from 'styled-components'

export const ConversationName = styled(Typography)`
  font-weight: 500 !important;
  font-size: 15px !important;
  color: #12263f;
`

export const MessageText = styled(Typography)`
  color: #12263f;
  font-size: 13px !important;
  font-weight: 400 !important;
  padding: 8px 8px 8px 0px;
`

export const IconContainer = styled.div`
  font-size: 24px !important;
  padding-top: 8px !important;
`

export const TimestampBox = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: flex-end;
  gap: 4px;
  margin-top: auto;
  color: ${({ color }) => color};
`
interface ConversationWrapperProps extends BoxProps {
  $isSelected: boolean
}

export const ConversationWrapper = styled(Box)<ConversationWrapperProps>`
  height: 126px;
  min-height: 126px;
  padding: 16px 16px 24px;
  border-radius: 8px;
  background-color: ${({ $isSelected }) => ($isSelected ? '#EDF2F9' : 'transparent')};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: #edf2f9;
  }
`

export const MessageBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const HeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const TimePassedContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`
