import {
  FormattedMenuSectionInfo,
  MenuMappingCustomizationOptionResource,
  MenuMappingCustomizationResource,
  MenuMappingItem,
  MenuMappingItemResource,
  MenuMappingNestedCustomizationResource,
} from 'api'
import {
  createMenuMappingCustomizationOption,
  removeMenuMappingCustomizationOptionRelationship,
  updateMenuMappingCustomizationOption,
} from 'api/menuMapping/customizationOptions'
import {
  createMenuMappingCustomization,
  deleteMenuMappingCustomization,
  updateMenuMappingCustomization,
} from 'api/menuMapping/customizations'
import { updateMenuMappingItem } from 'api/menuMapping/items'
import { createMenuMappingNestedCustomization } from 'api/menuMapping/nestedCustomizations'
import { AxiosError } from 'axios'

import { NESTED_GROUP_PREFIX } from '../MenuItemViewModal/index'
import { ModifierValuesMap } from '../MenuSectionEditor'

type UpdateItemProps = {
  itemId: string
  menuId: string
  attributes: MenuMappingItem
  modifierGroupsToAdd: FormattedMenuSectionInfo[]
  modifierGroupsToDelete: FormattedMenuSectionInfo[]
  modifierGroupsToUpdate: FormattedMenuSectionInfo[]
  modifiersToAdd: ModifierValuesMap
  modifiersToDelete: ModifierValuesMap
  modifiersToUpdate: ModifierValuesMap
}

export type ResponseErrors = {
  createErrors?: [{ title?: string; error: any }]
  updateErrors?: [{ title?: string; error: any }]
  deleteErrors?: [{ title?: string; error: any }]
}

const updateExistingMenuItem = async ({
  itemId,
  menuId,
  attributes,
  modifierGroupsToAdd,
  modifierGroupsToDelete,
  modifierGroupsToUpdate,
  modifiersToAdd,
  modifiersToDelete,
  modifiersToUpdate,
}: UpdateItemProps) => {
  // we need to store a reference for a temp Id to a real Id that we create for these objects, because their children depend on it
  const createdGroupIdsMap: { [tempId: string]: string } = {}
  const createdModifiersIdsMap: { [tempId: string]: string } = {} // need for nested groups and mods
  const createdNestedGroupsIdsMap: { [tempId: string]: string } = {} // need for nested mods

  let updatedItemResponse: MenuMappingItemResource | undefined
  let addModifierGroupsResponse: (MenuMappingCustomizationResource | undefined)[] | undefined
  let updateModifierGroupsResponse: (MenuMappingCustomizationResource | undefined)[] | undefined
  let deleteModifierGroupsResponse: ({ id: string; result: string } | undefined)[] | undefined
  let addModifiersResponse: (MenuMappingCustomizationOptionResource | undefined)[] | undefined
  let deleteModifiersResponse: ({ id: string; result: string } | undefined)[] | undefined
  let udpateModifiersResponse: (MenuMappingCustomizationOptionResource | undefined)[] | undefined
  let addNestedModifierGroupsResponse:
    | (MenuMappingNestedCustomizationResource | undefined)[]
    | undefined

  let addNestedModifiersResponse: (MenuMappingCustomizationOptionResource | undefined)[] | undefined
  const modifierGroupErrors: ResponseErrors = {}
  const modifierErrors: ResponseErrors = {}
  const itemErrors: ResponseErrors = {}

  const groupsToAdd: FormattedMenuSectionInfo[] = []
  const nestedGroupsToAdd: FormattedMenuSectionInfo[] = []
  const modsToAdd: ModifierValuesMap = {}
  const nestedModsToAdd: ModifierValuesMap = {}

  // split up groups and nested groups
  modifierGroupsToAdd.forEach((group) => {
    if (group.id.includes(NESTED_GROUP_PREFIX)) {
      nestedGroupsToAdd.push(group)
    } else {
      groupsToAdd.push(group)
    }
  })

  // split up mods and nested mods
  Object.entries(modifiersToAdd).forEach(([id, values]) => {
    if (values.isNested) {
      nestedModsToAdd[id] = values
    } else {
      modsToAdd[id] = values
    }
  })

  // update item attributes: title, description, price
  try {
    updatedItemResponse = await updateMenuMappingItem({
      itemId,
      attributes,
    })
  } catch (err: any) {
    itemErrors.updateErrors = [
      { title: attributes.title || itemId, error: (err as AxiosError)?.message },
    ]
  }

  // create new modifier groups
  if (groupsToAdd?.length) {
    addModifierGroupsResponse = await Promise.all(
      groupsToAdd.map(async (group) => {
        const { attributes } = group

        try {
          const newGroupResult = await createMenuMappingCustomization({
            attributes,
            items: [itemId],
            menuId,
          })

          if (newGroupResult) {
            // @ts-ignore - this is correct.
            createdGroupIdsMap[group.id] = newGroupResult.data.id

            return newGroupResult
          }
        } catch (err: any) {
          if (modifierGroupErrors.createErrors) {
            modifierGroupErrors.createErrors.push({
              title: attributes.title || group.id,
              error: err,
            })
          } else {
            modifierGroupErrors.createErrors = [
              {
                title: attributes.title || group.id,
                error: err,
              },
            ]
          }
        }
      }),
    )
  }

  // create new modifiers
  if (Object.keys(modsToAdd).length) {
    // new modifiers can belong to an existing group or a newly created group
    addModifiersResponse = await Promise.all(
      Object.entries(modsToAdd).map(async ([id, values]) => {
        const groupId = values.parentGroupId
        let customizationId = groupId

        // we are adding the mod to a newly created group. Use the new group Id
        if (groupId.startsWith('temp-')) {
          const newGroupId = createdGroupIdsMap[groupId]
          customizationId = newGroupId
        }

        if (customizationId) {
          const attributes = {
            title: values.title,
            customization_customization_options: {
              customization_id: customizationId,
              customization_option_price: values.price || 0,
            },
          }

          try {
            const modResult = await createMenuMappingCustomizationOption({
              attributes,
              customizations: [customizationId],
              menuId,
            })

            if (modResult) {
              // store real ids for children objects
              // @ts-ignore - this is correct.
              createdModifiersIdsMap[id] = modResult.data.id

              return modResult
            }
          } catch (err) {
            if (modifierErrors.createErrors) {
              modifierErrors.createErrors.push({ title: values.title, error: err })
            } else {
              modifierErrors.createErrors = [{ title: values.title, error: err }]
            }
          }
        }
      }),
    )
  }

  // create new nested groups
  // nested group id: `${NESTED_GROUP_PREFIX}:${parentModId}:${tempModGroupId}`
  if (nestedGroupsToAdd?.length) {
    addNestedModifierGroupsResponse = await Promise.all(
      nestedGroupsToAdd.map(async (group, idx) => {
        const { attributes } = group
        const groupIdArr = group.id.split(':')
        let parentModId = groupIdArr[1]

        // if the mod was also just created, map it to the new real id
        if (parentModId.startsWith('temp')) {
          parentModId = createdModifiersIdsMap[parentModId]
        }

        // Create new customization, then nested customization relationship
        try {
          const newGroupResult = await createMenuMappingCustomization({
            attributes,
            menuId,
          })

          if (newGroupResult && parentModId) {
            // store for reference
            // @ts-ignore - this is correct.
            createdNestedGroupsIdsMap[group.id] = newGroupResult.data.id

            const nestedGroupResult = await createMenuMappingNestedCustomization({
              attributes: { sort_position: idx + 1 },
              parentOptionId: parentModId,
              // @ts-ignore
              customizationId: newGroupResult.data.id,
            })

            return nestedGroupResult
          }
        } catch (err: any) {
          if (modifierGroupErrors.createErrors) {
            modifierGroupErrors.createErrors.push({
              title: attributes.title || group.id,
              error: err,
            })
          } else {
            modifierGroupErrors.createErrors = [
              {
                title: attributes.title || group.id,
                error: err,
              },
            ]
          }
        }
      }),
    )
  }

  // create new nested mods
  if (Object.keys(nestedModsToAdd).length) {
    // new modifiers can belong to an existing group or a newly created group
    addNestedModifiersResponse = await Promise.all(
      Object.values(nestedModsToAdd).map(async (values) => {
        const groupId = values.parentGroupId
        let customizationId = groupId

        // we are adding the mod to a newly created group. Use the new group Id
        if (groupId.startsWith(NESTED_GROUP_PREFIX)) {
          // only temps use this prefix
          customizationId = createdNestedGroupsIdsMap[groupId]
        }

        if (customizationId) {
          const attributes = {
            title: values.title,
            customization_customization_options: {
              customization_id: customizationId,
              customization_option_price: values.price || 0,
            },
          }

          try {
            const modResult = await createMenuMappingCustomizationOption({
              attributes,
              customizations: [customizationId],
              menuId,
            })

            return modResult
          } catch (err) {
            if (modifierErrors.createErrors) {
              modifierErrors.createErrors.push({ title: values.title, error: err })
            } else {
              modifierErrors.createErrors = [{ title: values.title, error: err }]
            }
          }
        }
      }),
    )
  }

  // update group attributes (min/max, title)
  if (modifierGroupsToUpdate?.length) {
    updateModifierGroupsResponse = await Promise.all(
      modifierGroupsToUpdate.map(async (group) => {
        try {
          const updateRes = await updateMenuMappingCustomization({
            id: group.id,
            attributes: group.attributes,
          })

          if (updateRes) {
            return updateRes
          }
        } catch (err) {
          if (modifierGroupErrors.updateErrors) {
            modifierGroupErrors.updateErrors.push({
              title: group.attributes.title,
              error: (err as AxiosError)?.message,
            })
          } else {
            modifierGroupErrors.updateErrors = [
              { title: group.attributes.title, error: (err as AxiosError)?.message },
            ]
          }
        }
      }),
    )
  }

  if (Object.keys(modifiersToDelete).length) {
    deleteModifiersResponse = await Promise.all(
      Object.entries(modifiersToDelete).map(async ([id, values]) => {
        const modId = id
        const groupId = values.parentGroupId

        try {
          const deleteRes = await removeMenuMappingCustomizationOptionRelationship({
            modifierId: modId,
            customizationId: groupId,
          })

          if (deleteRes) {
            return { id: modId, result: 'success' }
          }
        } catch (err) {
          if (modifierErrors.deleteErrors) {
            modifierErrors.deleteErrors.push({ title: values.title, error: err })
          } else {
            modifierErrors.deleteErrors = [{ title: values.title, error: err }]
          }
        }
      }),
    )
  }

  // update modifiers
  if (Object.keys(modifiersToUpdate).length) {
    udpateModifiersResponse = await Promise.all(
      Object.entries(modifiersToUpdate).map(async ([id, values]) => {
        const modId = id
        const customizationId = values.parentGroupId

        const attributes = {
          title: values.title,
          customization_customization_options: {
            customization_id: customizationId,
            customization_option_price: values.price || 0,
          },
        }

        try {
          const updateRes = await updateMenuMappingCustomizationOption({
            id: modId,
            attributes,
          })

          return updateRes
        } catch (err) {
          if (modifierErrors.updateErrors) {
            modifierErrors.updateErrors.push({ title: values.title, error: err })
          } else {
            modifierErrors.updateErrors = [{ title: values.title, error: err }]
          }
        }
      }),
    )
  }

  // delete modifier groups
  if (modifierGroupsToDelete?.length) {
    deleteModifierGroupsResponse = await Promise.all(
      modifierGroupsToDelete.map(async (group) => {
        try {
          const deleteRes = await deleteMenuMappingCustomization({
            id: group.id,
          })

          if (deleteRes) {
            return { id: group.id, result: 'success' }
          }
        } catch (err) {
          if (modifierGroupErrors.deleteErrors) {
            modifierGroupErrors.deleteErrors.push({
              title: group.attributes.title,
              error: (err as AxiosError)?.message,
            })
          } else {
            modifierGroupErrors.deleteErrors = [
              { title: group.attributes.title, error: (err as AxiosError)?.message },
            ]
          }
        }
      }),
    )
  }

  return {
    updatedItemResponse,
    addModifierGroupsResponse,
    addNestedModifierGroupsResponse,
    updateModifierGroupsResponse,
    deleteModifierGroupsResponse,
    addModifiersResponse,
    addNestedModifiersResponse,
    deleteModifiersResponse,
    udpateModifiersResponse,
    modifierGroupErrors,
    modifierErrors,
    itemErrors,
  }
}

export default updateExistingMenuItem
