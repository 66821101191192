import {
  JoinedMenuMappingOverrideSection,
  MenuMappingOverrideSectionInput,
  OnboardingLocation,
  OrderPlatformResource,
  TypeName,
  deleteCheck,
  getAllOnboardingLocations,
  getMainOnboardingLocation,
  getMenuMappingMenu,
  getMenuMappingOverrideSection,
  getMenuMappingOverrideSections,
  getMenuMappingSchedule,
  getMenuMappingSection,
  getMenuMappingSections,
  getMyAccount,
  getParentPlatform,
  getPartner,
  getPartners,
  getPosProvidesStockUpdates,
} from 'api'
import findLast from 'lodash/findLast'
import { atom, selector, selectorFamily } from 'recoil'
import { getSavedItem, removeSavedItem, saveItem } from 'utils'

import { PlatformName, PlatformSummary } from '../types'

export const onboardingLocationsQuery = selector<OnboardingLocation[]>({
  key: 'onboardingLocationsQuery',
  get: async () => {
    const results = await getAllOnboardingLocations()
    return results.data.map(({ attributes }) => attributes)
  },
})

export const onboardingState = atom<OnboardingLocation | null>({
  key: 'onboardingState',
  default: undefined, // undefined is important here. Which indicates that this atom is not initialized with the api data yet
  effects: [
    ({ setSelf, trigger, onSet }) => {
      const fetchData = async () => {
        setSelf(await getMainOnboardingLocation())
      }
      if (trigger === 'get') {
        fetchData()
      }
      onSet((_newValue, _oldValue, isReset) => {
        if (isReset) {
          fetchData()
        }
      })
    },
  ],
})

export const incompleteLocationState = atom<OnboardingLocation | null>({
  key: 'incompleteLocationState',
  default: null,
  effects: [
    ({ setSelf, trigger, onSet, getPromise }) => {
      const fetch = async () => {
        const allLocations = await getPromise(onboardingLocationsQuery)
        const incompleteLocation = allLocations.find(({ completed }) => !completed) ?? null
        setSelf(incompleteLocation)
      }
      if (trigger === 'get') {
        fetch()
      }
      onSet((_newValue, _oldValue, isReset) => {
        if (isReset) {
          fetch()
        }
      })
    },
  ],
})

export const userIdState = selector<string | null>({
  key: 'userIdState',
  get: async () => {
    const user = await getMyAccount()
    return user.data.id
  },
})

export const mayDeletePlatform = selectorFamily({
  key: 'mayDeletePlatform',
  get: (platformId: number | string | null | undefined) => async () => {
    if (!platformId) {
      return null
    }
    const mayDelete = await deleteCheck(Number(platformId))
    return mayDelete
  },
})

export const currentMenuMappingOverrideSection = atom<JoinedMenuMappingOverrideSection | null>({
  key: 'currentOverrideSection',
  default: null,
  effects: [
    ({ setSelf, onSet }) => {
      const key = 'currentOverrideSection'
      const savedOverrideSection = getSavedItem<JoinedMenuMappingOverrideSection | null>(
        sessionStorage,
        key,
      )
      if (savedOverrideSection) {
        setSelf(savedOverrideSection)
      }

      onSet((newValue, _, isReset) => {
        isReset ? removeSavedItem(sessionStorage, key) : saveItem(sessionStorage, key, newValue)
      })
    },
  ],
})

export const currentMenuMappingOverrideSectionInput = atom<MenuMappingOverrideSectionInput | null>({
  key: 'currentOverrideSectionInput',
  default: null,
  effects: [
    ({ setSelf, onSet }) => {
      const key = 'currentOverrideSectionInput'
      const savedOverrideSection = getSavedItem<MenuMappingOverrideSectionInput | null>(
        sessionStorage,
        key,
      )
      if (savedOverrideSection) {
        setSelf(savedOverrideSection)
      }

      onSet((newValue, _, isReset) => {
        isReset ? removeSavedItem(sessionStorage, key) : saveItem(sessionStorage, key, newValue)
      })
    },
  ],
})

export const partnersMetadataQuery = selector({
  key: 'partnersMetadata',
  get: async () => {
    try {
      const partnersMetadataResponse = await getPartners({
        meta_only: true,
        partner_status: 'not_churned',
      })
      return partnersMetadataResponse
    } catch (error) {
      console.warn(error)
      return null
    }
  },
})

export const partnerQuery = selectorFamily({
  key: 'partnerData',
  get: (partnerId: number | string | null | undefined) => async () => {
    if (!partnerId) {
      return null
    }
    return await getPartner(partnerId, { include: 'order_platforms' })
  },
})

export const modalState = atom<{
  isOpen: boolean
  modalName: string | null
}>({
  key: 'modalState',
  default: {
    isOpen: false,
    modalName: 'OnboardingModal',
  },
})

export const platformDrawerState = atom<{
  isOpen: boolean
}>({
  key: 'PlatformDrawerStatus',
  default: { isOpen: false },
})

export const uberEatsActivationLinkState = atom<string>({
  key: 'uberEatsActivationLinkState',
  default: '',
})

export const doordashActivationLinkState = atom<string>({
  key: 'doordashActivationLinkState',
  default: '',
})

export const onboardingStepState = atom<string>({
  key: 'onboardingStepState',
  default: 'welcome',
})

export const isEditingMenuState = atom<boolean>({
  key: 'isEditingMenuState',
  default: false,
})

export const activePartnerIdState = atom<string | null>({
  key: 'activePartnerIdState',
  default: null,
})

export const activePlatformIdState = atom<string | null>({
  key: 'activePlatformIdState',
  default: null,
})

export const activeMenuSectionIdState = atom<string | null>({
  key: 'activeSectionIdState',
  default: null,
})

export const upchargeState = atom<number | string>({
  key: 'upChargeState',
  default: 0,
})

type onboardingStatusWebHook = {
  type: string
  time: number
}

type uberPublishSuccessfulWebHook = {
  publish_successful: boolean
}

export const onboardingStatusWebHookCall = atom<onboardingStatusWebHook | null>({
  key: 'onboardingStatusWebHookCall',
  default: null,
})

export const uberPublishSuccessfulWebHookCall = atom<uberPublishSuccessfulWebHook | null>({
  key: 'uberPublishSuccessfulWebHookCall',
  default: null,
})

export const orderPlatformConnectedStatus = atom<boolean | null>({
  key: 'orderPlatformConnectedStatus',
  default: false,
})

export const menuPlatformsState = atom<PlatformSummary[] | undefined | null>({
  key: 'menuPlatformsState',
  default: null,
})

export const parentOrderPlatformQuery = selectorFamily({
  key: 'parentOrderPlatformQuery',
  get: (partnerId: number | string | null | undefined) => async () => {
    if (partnerId) {
      const parentOrderPlatform = await getParentPlatform(partnerId)
      return parentOrderPlatform
    }
  },
})

// @deprecated. Instead use menuMappingMenuFullQuery
export const menuMappingMenuQuery = selector({
  key: 'menuMappingMenuQuery',
  get: async ({ get }) => {
    const activePartnerId = get(activePartnerIdState)
    if (!activePartnerId) {
      return null
    }
    const platform = get(parentOrderPlatformQuery(activePartnerId))
    const menu_mapping_menu_id = platform?.attributes.menu_mapping_menu_id
    if (!menu_mapping_menu_id) {
      return null
    }
    const { data } = await getMenuMappingMenu(menu_mapping_menu_id)
    return data
  },
})

export const menuMappingSectionsQuery = selectorFamily({
  key: 'menuMappingSectionsQuery',
  get: (menuId: string | null | undefined) => () => {
    if (menuId) {
      return getMenuMappingSections(menuId)
    }
    return []
  },
})

export const menuMappingSectionQuery = selectorFamily({
  key: 'menuMappingSectionQuery',
  get: (menuSectionId: string | null | undefined) => () => {
    if (menuSectionId) {
      return getMenuMappingSection(menuSectionId)
    }
  },
})

// @deprecated
export const menuMappingOverrideSectionQuery = selectorFamily({
  key: 'menuMappingOverrideSectionQuery',
  get: (sectionId: string | null | undefined) => async () => {
    if (!sectionId) {
      return
    }
    return getMenuMappingOverrideSection(sectionId, {
      include: 'schedule,subsections',
    })
  },
})

// @deprecated
export const menuMappingOverrideSectionsQuery = selector({
  key: 'menuMappingOverrideSectionsQuery',
  get: async ({ get }) => {
    const activePartnerId = get(activePartnerIdState)
    if (!activePartnerId) {
      return []
    }
    const { id } = get(parentOrderPlatformQuery(activePartnerId)) ?? {}
    if (!id) {
      return []
    }
    return getMenuMappingOverrideSections(id, {
      include: 'schedule,subsections,override_section_collections',
    })
  },
})

export const menuMappingScheduleQuery = selectorFamily({
  key: 'menuMappingScheduleQuery',
  get: (scheduleId: string | undefined) => () => {
    if (!scheduleId) {
      return null
    }
    return getMenuMappingSchedule(scheduleId, {
      include: 'service_availabilities,service_availabilities.time_periods',
    })
  },
})

export const posProvidesStockUpdatesQuery = selectorFamily({
  key: 'posProvidesStockUpdatesQuery',
  get: (partnerId: number | undefined) => async () => {
    if (!partnerId) {
      return null
    }
    return getPosProvidesStockUpdates(partnerId)
  },
})

export const marketplaceQuery = selector({
  key: 'marketplaceQuery',
  get: async ({ get }) => {
    const onboarding = get(onboardingState)
    const partnerId = onboarding?.partner_id
    if (!partnerId) {
      return null
    }
    const response = await getPartner(partnerId, { include: 'order_platforms' })

    if (response) {
      const { included } = response

      if (!included) {
        return null
      }

      const marketplace =
        findLast<OrderPlatformResource>(
          included as OrderPlatformResource[],
          ({ type, attributes }) =>
            type == TypeName.OrderPlatform && attributes.name !== PlatformName.CHOWLY,
        )?.attributes ?? null
      return marketplace
    }
  },
})
