import React from 'react'
import moment from 'moment'
import { Drawer, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { PromoCode } from 'api/promoCodes/types'
import * as Styled from './styles'
interface PromoCodesDrawerProps {
  isOpen: boolean
  onClose: () => void
  promoCode: PromoCode | null
  onModalOpen: (promoCode: PromoCode) => void
}

const PromoCodesDrawer: React.FC<PromoCodesDrawerProps> = ({
  isOpen,
  onClose,
  promoCode,
  onModalOpen,
}) => {
  if (!promoCode) return null

  const {
    code,
    discount_type,
    applies_to,
    conditions,
    starts_at,
    expires_at,
    aasm_state,
  } = promoCode

  const handleCancelCoupon = () => {
    onClose()

    setTimeout(() => {
      onModalOpen(promoCode)
    }, 300)
  }

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      sx={{ '& .MuiDrawer-paper': { maxWidth: '410px', width: '100%', padding: 0 } }}
    >
      <Styled.DrawerHeader>
        <h2>Coupon Details</h2>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Styled.DrawerHeader>
      <Styled.DrawerContent>
        <Styled.Row full>
          <Styled.Label>Coupon Name</Styled.Label>
          <Styled.Value horizontal>
            <Styled.CouponName>{code}</Styled.CouponName>
            <Styled.StyledChip
              label={aasm_state}
              status={aasm_state}
              size='small'
            />
          </Styled.Value>
        </Styled.Row>
        <Styled.Row full>
          <Styled.Label>Coupon Type</Styled.Label>
          <Styled.Value horizontal>{discount_type}</Styled.Value>
        </Styled.Row>
        <Styled.Row full>
          <Styled.Label>Applies To</Styled.Label>
          <Styled.Value horizontal>{applies_to}</Styled.Value>
        </Styled.Row>
        <Styled.Row full>
          <Styled.Label>Conditions</Styled.Label>
          <Styled.Value horizontal>{conditions}</Styled.Value>
        </Styled.Row>
        <Styled.Row>
          <Styled.Label>Active Dates</Styled.Label>
          <Styled.Value horizontal>
            <Styled.RowPair>
              <Styled.Value>Start Date: {moment(starts_at).format('MM/DD/YYYY')}</Styled.Value>
              <Styled.Value>End Date: {moment(expires_at).format('MM/DD/YYYY')}</Styled.Value>
            </Styled.RowPair>
          </Styled.Value>
        </Styled.Row>
        <Styled.ButtonContainer>
          <Styled.StyledCancelButton
            variant='contained'
            onClick={handleCancelCoupon}
          >
            Cancel Coupon
          </Styled.StyledCancelButton>
        </Styled.ButtonContainer>  
      </Styled.DrawerContent>
    </Drawer>
  )
}

export default PromoCodesDrawer
