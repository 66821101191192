import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'

import client from '../chowly'
import { OrganizationResponse, OrganizationsResponse } from '../models'

export type OrganizationQueryParams = {
  include: string
}

export const getOrganization = async (
  id: number | string,
  params?: Partial<OrganizationQueryParams>,
): Promise<OrganizationResponse> => {
  const url = generatePath(chowlyApi.organization, { id })

  const { data } = await client.get<OrganizationResponse>(url, { params })
  return data
}

export const getAllOrganizations = async (page = 1, query = '', params = {}) => {
  const combinedParams = {
    ...params,
    ...(page > 1 && { page }),
    ...(query.length > 0 && { q: query }),
  }
  const { data } = await client.get<OrganizationsResponse>(chowlyApi.organizations, {
    params: combinedParams,
  })
  return data
}
