import React, { useCallback, useEffect, useState } from 'react'
import { getToDoTitle } from '../components';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import GreenButton from 'components/modern/GreenButton';
import RedButton from 'components/modern/RedButton';
import { 
  CustomTextArea, 
  FeedbackContent, 
  DialogActions, 
  BaseModal, 
  FeedbackTitle,
  ModalTitle,
  ButtonLoadingIcon, 
} from './styles';
import { Toast } from 'components';
import IconButton from '@mui/material/IconButton';
import FeedbackQuestion from './FeedbackQuestion';
import { UserFeedbackSentiment } from 'types/digitalMarketing/googleAd';
import PrimaryButton from 'components/modern/PrimaryButton';
import { DialogProps } from '@mui/material';
import { useDigitalMarketingApi } from 'hooks';
import { TodoItemWithProperties } from 'types/digitalMarketing/common';
import { EmailMarketingCampaignInput } from 'types/digitalMarketing';
import { withTodoAsMetaAd, withTodoAsGoogleAd, withTodoAsTargetableInsight, withTodoAsEmailMarketingAd } from 'utils/dmUtils';
import { 
  buildGoogleAdFeedback, 
  updateInsightWithFeedback, 
  updateSuggestedCampaignWithFeedback 
} from '../utils';
import { userIdState } from 'state/atoms';
import { useRecoilValue } from 'recoil';
import { updateEmailMarketingCampaign } from 'api/digitalMarketing';

interface FeedbackModalProps {
  todoItem: TodoItemWithProperties | null;
  mode: 'approve' | 'reject';
  onClose: () => void;
  onSubmitted: (todoItem: TodoItemWithProperties, approved: boolean) => void;
  partnerId: string;
  organizationId: string;
}

const defaultQuestions = [
  "Did you like the content (text, design, images, etc) of the ad?",
  "Did we accomplish the objectives you requested?",
  "Do we have permission to schedule this ad?",
]

/** A Base Modal that ignores backdrop clicks */
const InsensitiveBackDropModal = (props: DialogProps) => {
  const {onClose, ...rest} = props;

  const handleClose = (event: any, reason: "backdropClick" | "escapeKeyDown") => {
    if(reason === 'backdropClick') return;
    onClose && onClose(event, reason);
  }

  return (
    <BaseModal
      onClose={handleClose}
      {...rest}
    />
  )
}

const FeedbackModal = (props: FeedbackModalProps) => {
  const { todoItem, mode, onSubmitted, onClose, partnerId, organizationId } = props;
  const [feedbackAnswer, setFeedbackAnswer] = useState<Record<string, UserFeedbackSentiment>>({});
  const [additionalFeedback, setAdditionalFeedback] = useState<string>('');
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [questions, setQuestions] = useState<string[]>(defaultQuestions);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [digitalMarketingApi, { isLoading: isPostingFeedback }] = useDigitalMarketingApi(partnerId);
  const [fetchQuestionnaireApi, {  
    isLoading: isLoadingQuestionnaire, 
    error, 
    data: questionnaireData, 
    resetState: resetQuestionnaireData 
  }] = useDigitalMarketingApi(partnerId);
  
  const rccUserId = useRecoilValue(userIdState)
  const showModal = !!todoItem;
  const isEmailMarketingCampaign = withTodoAsEmailMarketingAd(todoItem);

  const resetForm = useCallback(() => {
    setConfirmationModal(false);
    setFeedbackAnswer({});
    setAdditionalFeedback('');
    resetQuestionnaireData();
    setSubmitting(false);
  }, [resetQuestionnaireData]);

  const closeAndReset = () => {
    resetForm();
    onClose();
  }

  /** submit the feedback for the todo item to the right backend */
  const handleSubmit = async (isApproved: boolean) => {
    if(!todoItem) return;

    // Submit feedback based on its type
    const asInsight = withTodoAsTargetableInsight(todoItem);
    const asGoogleAd = withTodoAsGoogleAd(todoItem);
    const asMetaCampaign = withTodoAsMetaAd(todoItem);
    const asEmailMarketingCampaign = withTodoAsEmailMarketingAd(todoItem);

    const notifyError = (errorMsg: string) => {
      Toast.error({ message: `We couldn't process your feedback, please try again. Error: ${errorMsg}` });
      return;
    }

    if(asInsight){
      const updatedInsight = updateInsightWithFeedback(
        asInsight,
        feedbackAnswer,
        isApproved,
        rccUserId,
        additionalFeedback
      );
      
      // NOTE: In a this call the BE will take care of storing the feedback and scheduling 
      // the ad update based on the feedback status.
      const { error } = await digitalMarketingApi('updateInsight', asInsight.id, updatedInsight)
      if(error){
        return notifyError(error);
      }
    }

    if(asGoogleAd){
      const feedback = buildGoogleAdFeedback(feedbackAnswer, additionalFeedback);
      const { error } = await digitalMarketingApi('submitGoogleAdFeedback', asGoogleAd.id, feedback, isApproved)
      if(error) return notifyError(error);

      // Publish the ad
      if(isApproved){
        const { error: publishError } = await digitalMarketingApi('publishGoogleAd', asGoogleAd.id);
        if(publishError){
          return notifyError(publishError);
        }
      }
    }

    if(asMetaCampaign){
      // Suggested campaigns are too large for algolia so only an optimized version is stored.
      // here we need to fetch the full suggested campaign which is required by the update endpoint.
      const { data: suggestedCampaign, error } = await digitalMarketingApi('getSuggestedCampaignById', asMetaCampaign.id);
      if(!error && suggestedCampaign){
        const updatedSC = updateSuggestedCampaignWithFeedback(suggestedCampaign, feedbackAnswer, isApproved, rccUserId, additionalFeedback);
        
        // Post feedback
        let response = await digitalMarketingApi('updateSuggestedCampaign', asMetaCampaign.id, updatedSC);
        if(response.error) return notifyError(response.error);

        if(isApproved){
          // Create a campaign draft
          response = await digitalMarketingApi('createMetaCampaignDraftFromSc', updatedSC );

          if(response.error || !response.data) return notifyError(response.error || 'Something went wrong');

          // Publish draft
          const metaDraft = response.data;
          const { error } = await digitalMarketingApi('publishMetaCampaign', metaDraft.campaigns[0].id);
          if(error){
            return notifyError(error);
          }
        }
      }
    }

    if(asEmailMarketingCampaign && organizationId){
      try {
        const emailMarketingBody: EmailMarketingCampaignInput = {
          status: isApproved ? 'approved' : 'rejected',
          feedback: additionalFeedback,
        };
        await updateEmailMarketingCampaign(organizationId, todoItem.id, emailMarketingBody)
      } catch (error: any) {
        if (error instanceof Error) {
          return notifyError(error.message);
        } else {
          return notifyError('An unexpected error occurred.');
        }
      }
    }
    
    onSubmitted(todoItem, isApproved);
    setSubmitting(false);
  }

  const handleDismiss = () => {
    setConfirmationModal(true);
  }

  const handleCloseConfirmation = () => {
    setConfirmationModal(false);
  }

  const title = getToDoTitle(todoItem);
  

  const handleFeedbackChange = (question: string, sentiment: UserFeedbackSentiment) => {
    setFeedbackAnswer({
      ...feedbackAnswer,
      [question]: sentiment
    });
  }

  // Clear form after the modal is closed
  useEffect(() => {
    if(!showModal){
      resetForm();
    }
  }, [showModal, resetForm]);

  const hasQuestionnaireId = (item: any): item is { questionnaireId: string } => {
    return typeof item?.questionnaireId === 'string';
  }

  useEffect(() => {
    const fetchQuestions = async () => {
      if (!hasQuestionnaireId(todoItem)) return;
  
      // Safe to invoke here since the invoke function guarantees not to resolve if unmounted
      const { data, error } = await fetchQuestionnaireApi('getQuestionnaireById', todoItem.questionnaireId);
      if (data && !error) {
        const questions = data?.questionnaire?.questions?.map((q) => q.question);
        setQuestions(questions);
      } else {
        setQuestions(defaultQuestions);
      }
    };
  
    if (!questionnaireData && !isLoadingQuestionnaire && !error) {
      fetchQuestions();
    }
  }, [fetchQuestionnaireApi, isLoadingQuestionnaire, questionnaireData, error, todoItem]);
 
  const allQuestionsAnswered = questions.every(question => feedbackAnswer[question]);
  const inAsyncOperation = isPostingFeedback || submitting;

  return (
    <InsensitiveBackDropModal
      open={showModal}
      onClose={closeAndReset}
    >
      <InsensitiveBackDropModal 
        open={confirmationModal} 
        onClose={handleCloseConfirmation}
        aria-modal
      >
        <ModalTitle>Are you sure?</ModalTitle>
        <FeedbackContent>
          <span>Cancel {mode === 'approve' ? 'approving' : 'rejecting'} this campaign?</span>
        </FeedbackContent>
        <DialogActions>
          <PrimaryButton variant="text" onClick={closeAndReset}>Yes</PrimaryButton>
          <PrimaryButton variant="text" onClick={handleCloseConfirmation}>No</PrimaryButton>
        </DialogActions>
      </InsensitiveBackDropModal>

      <FeedbackTitle data-testid="feedback-title">
        {mode === 'approve' ? 'Approve' : 'Reject'}: {title}
        <IconButton data-testid="close-button" onClick={handleDismiss}>
          <CloseRoundedIcon />
        </IconButton>
      </FeedbackTitle>
      <FeedbackContent>
        {!isEmailMarketingCampaign && questions.map((question, index) => (
          <FeedbackQuestion
            key={index}
            question={question}
            sentiment={feedbackAnswer[question]}
            onChange={handleFeedbackChange}
            loadingQuestion={isLoadingQuestionnaire}
          />
        ))}
        <CustomTextArea 
          placeholder="Any additional thoughts (optional)?"
          value={additionalFeedback}
          disabled={isPostingFeedback || isLoadingQuestionnaire} 
          onChange={(e) => setAdditionalFeedback(e.target.value)}
        />
      </FeedbackContent>
      <DialogActions>
        {mode === 'approve' && (
          <GreenButton 
            disabled={(!allQuestionsAnswered || inAsyncOperation) && !isEmailMarketingCampaign}
            onClick={() => handleSubmit(true)}
            endIcon={<ButtonLoadingIcon size={16} loading={inAsyncOperation} />}
          >
            Approve
          </GreenButton>
        )}
        {mode === 'reject' && (
          <RedButton 
            disabled={(!allQuestionsAnswered || inAsyncOperation) && !isEmailMarketingCampaign}
            onClick={() => handleSubmit(false)}
            endIcon={<ButtonLoadingIcon size={16} loading={isPostingFeedback}/>}
          >
            Reject
          </RedButton>
        )}
      </DialogActions>
    </InsensitiveBackDropModal>
  )
}

export default FeedbackModal;
