import Disclaimer from 'components/AdPreviews/Disclaimer/Disclaimer'
import { StyledLabel } from 'components/AdPreviews/Disclaimer/styles'
import GoogleFeed, { GoogleFeedAd } from 'components/AdPreviews/GoogleFeed/GoogleFeed'
import _ from 'lodash'
import moment from 'moment'
import { GoogleLocationSettings } from 'types/digitalMarketing'
import { AdvancedLocationTargeting, RadiusLocationUnits } from 'types/digitalMarketing/common'
import { GenericAd } from 'types/digitalMarketing/googleAd'

import { AdTypeSpan, StyledBulletTypography, StyledTypography } from './styles'

interface GoogleAdDetailsProps {
  campaign: GenericAd
  partnerId: string
}

const getAdScheduleText = (campaign: GenericAd): string => {
  const getScheduleText = (startDate: string, endDate: string) =>
    `This ad will start on: ${startDate} and end on: ${endDate}`
  const isEvergreen = !campaign?.endDate
  const startDate = moment(campaign?.startDate).format('MM/DD/YYYY')
  const endDate = moment(campaign?.endDate)?.format('MM/DD/YYYY')
  return getScheduleText(startDate, isEvergreen ? 'No End Date (Evergreen)' : endDate)
}

const getAdBudgetText = (campaign: GenericAd): string => {
  const budgetAmount = campaign?.google?.budget?.amount.toFixed(2)
  return `Google Search Ad(s) will spend a maximum of <strong>$${budgetAmount} everyday</strong>`
}

const getAdKeywordsText = (campaign: GenericAd): string =>
  (campaign?.google?.group?.fields?.keywords || [])
    .map((keyword) => _.startCase(keyword))
    .join(', ') || 'N/A'

const getAdTargeting = (campaign: GenericAd) => {
  const isLocationRadius = campaign?.google?.campaign?.fields?.advancedLocations?.length || false
  if (isLocationRadius) {
    const advancedRadiusLocations: AdvancedLocationTargeting[] = (
      campaign?.google?.campaign?.fields?.advancedLocations || []
    ).filter((location) => location.radius)
    return (
      <>
        <StyledLabel variant='caption'>Radius Location Targeting</StyledLabel>
        {advancedRadiusLocations.map(({ radius }, index) => (
          <StyledBulletTypography variant='body2' key={index}>
            {radius?.address?.streetAddress}
            <AdTypeSpan>
              {': Radius '}
              {radius?.distance}
              {`${radius?.unit === RadiusLocationUnits.Kilometers ? ' km' : ' mi'}.`}
            </AdTypeSpan>
          </StyledBulletTypography>
        ))}
      </>
    )
  } else {
    const locationSettings: GoogleLocationSettings[] =
      campaign?.google?.campaign?.fields?.locationSettings || []
    return (
      <>
        <StyledLabel variant='caption'>Location Targeting</StyledLabel>
        {locationSettings.map(({ canonicalName, targetType }, index) => (
          <StyledBulletTypography variant='body2' key={index}>
            {canonicalName}
            <AdTypeSpan>{` (${targetType})`}</AdTypeSpan>
          </StyledBulletTypography>
        ))}
      </>
    )
  }
}

const GoogleAdDetails: React.FC<GoogleAdDetailsProps> = ({ campaign, partnerId }) => {
  return (
    <>
      <GoogleFeed
        ad={{ ...(campaign?.google?.ad as GoogleFeedAd) }}
        campaign={{ ...campaign?.google?.campaign }}
        marketingChannelId={campaign?.marketingChannelId as string}
        partnerId={partnerId}
      />
      <Disclaimer text='Ad appearance may vary across devices' variant='firstLine' />
      <StyledLabel variant='caption' marginTop='24px' display={'block'}>
        Marketing Platform
      </StyledLabel>
      <StyledTypography variant='body2'>Google Paid Search Ads</StyledTypography>

      <StyledLabel variant='caption'>Budget</StyledLabel>
      <StyledTypography
        variant='body2'
        dangerouslySetInnerHTML={{
          __html: getAdBudgetText(campaign),
        }}
      />

      <StyledLabel variant='caption'>Schedule</StyledLabel>
      <StyledTypography variant='body2'>{getAdScheduleText(campaign)}</StyledTypography>

      <StyledLabel variant='caption'>Keywords</StyledLabel>
      <StyledTypography variant='body2'>{getAdKeywordsText(campaign)}</StyledTypography>

      {/* Location Targeting */}
      {getAdTargeting(campaign)}
    </>
  )
}

export default GoogleAdDetails
