import { Menu, MenuItem as MuiMenuItem, TableCell } from '@mui/material'
import Select from '@mui/material/Select'
import styled from 'styled-components'
import { breakpoints } from 'theme'

export const CardBox = styled.div({
  marginBottom: '24px',
  borderRadius: '8px',
  background: '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(27, 81, 164, 0.04)',
  padding: '24px',
  '&:last-child': {
    marginBottom: 0,
  },
})

export const RewardsHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
})

export const CreateButton = styled.button({
  display: 'flex',
  padding: '8px 22px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  background: '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(27, 81, 164, 0.04)',
  color: '#1B51A4',
  border: '1px solid #1B51A4',
  cursor: 'pointer',
  fontFamily: 'Inter',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '150%',
  '&:hover': {
    background: '#F5F8FF',
  },
})

export const EmptyStateWrapper = styled.div({
  textAlign: 'center',
})

export const EmptyStateText = styled.p({
  color: '#777',
  fontFamily: 'Inter',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
})

// Pagination
export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  gap: 16px;
  font-size: 13px;
  @media ${breakpoints.xs_max} {
    justify-content: center;
  }
`

export const RowsPerPageWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${breakpoints.xs_max} {
    display: none;
  }
`

export const PaginationText = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  margin-right: 0;
`

export const CurrentPageInfo = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  text-align: left;

  @media ${breakpoints.xs_max} {
    display: none;
  }
`

export const PaginationSelect = styled(Select)`
  .MuiInputBase-input {
    font-size: 13px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`

export const StyledMenuItem = styled(MuiMenuItem)`
  font-family: 'Inter';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

export const StyledMenu = styled(Menu)`
  width: 100px;
  border-radius: 8px;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.2);
`
export const StyledTableCell = styled(TableCell)({
  '&.MuiTableCell-root': {
    fontFamily: 'Inter',
    fontSize: '11px',
    fontWeight: 600,
    color: '#777777',
    padding: '10px 16px',
    backgroundColor: 'transparent',
  },
})
