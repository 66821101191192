import { useEffect, useState } from 'react'

import { getSigmaUrl } from '../api'

const useEmbedUrl = (embedPath: string, partnerIds?: string) => {
  const [embedUrl, setEmbedUrl] = useState('')

  useEffect(() => {
    // keep empty partnerIds if we need to implement a select dropdown for partners
    // if no partner ids get passed in, the api will call sigma with all partner ids for a user
    getSigmaUrl(embedPath, partnerIds).then(setEmbedUrl)
  }, [embedPath, partnerIds])

  return embedUrl
}

export default useEmbedUrl
