import InboxIcon from '@mui/icons-material/Inbox'
import { Box, CircularProgress, Typography, Tooltip } from '@mui/material'
import { conversationTimeline, replyToConversation, resolveConversation } from 'api/ovation/conversations'
import {
  Conversation,
  ConversationEvent,
  ConversationMessage,
  ConversationSearchResult,
  ConversationStatus,
} from 'api/ovation/models'
import MENU_OPEN_RIGHT from 'assets/icons/menu-open-right.svg'
import { usePusherMessages, useGetLocations } from 'hooks'
import { first, sortBy, uniqBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import ChatMessage from './ConversationChatMessage'
import {
  CenteredBox,
  ChatContainer,
  DateHeader,
  FloatingDateHeader,
  HeaderContainer,
  HeaderTitle,
  IconButtonStyled,
  InputContainer,
  LoadingBox,
  MobileBackBox,
  SendButton,
  StyledCheckIcon,
  StyledChevronLeftIcon,
  StyledList,
  StyledTextArea,
  TextAreaWrapper,
} from './styles'

const RatingToEmoji = ['🤬','😡','🙁','😐','🙂','🤩']

// TypeScript utility function to map ConversationMessage to ConversationEvent
function mapConversationMessageToEvent(message: ConversationMessage): ConversationEvent {
  const { _id, location, customer, conversation, user, text, type, created_at } =
    message.data.message

  return {
    _id: _id,
    type:
      type === 'outgoing' ? ConversationStatus.OutboundMessage : ConversationStatus.InboundMessage,
    location: location,
    customer: customer,
    conversation: conversation,
    user: user,
    online_review_platform: null,
    created_at: created_at,
    message: {
      _id: _id,
      text: text,
    },
    customer_order: null,
    survey: undefined,
    customer_saved_survey: null,
  }
}

interface ConversationChatProps {
  conversation: Conversation | null
  onBack: () => void
  onCustomerInfoClick: () => void
  onExpand: (expanded: boolean) => void
  isExpanded: boolean
}

const ConversationChat: React.FC<ConversationChatProps> = ({
  conversation,
  onBack,
  onCustomerInfoClick,
  onExpand,
  isExpanded,
}) => {
  const { defaultLocation } = useGetLocations()
  const partnerId = defaultLocation?.id !== null ? String(defaultLocation?.id) : ''
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const listRef = useRef<HTMLUListElement>(null)
  const MESSAGES_PER_PAGE = 25
  const [currentDate, setCurrentDate] = useState<string>('')
  const [isDateVisible, setIsDateVisible] = useState(false)
  const dateTimeoutRef = useRef<NodeJS.Timeout>()
  const [visibleDateHeader, setVisibleDateHeader] = useState<string | null>(null)
  const [chats, setChats] = useState<any[]>([])
  const [initialLoading, setInitialLoading] = useState(true)
  const [startingEventId, setStartingEventId] = useState<string | null>(null)
  const [isSending, setIsSending] = useState(false)

  const sortedChats = [...chats].sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())

  const showDateTemporarily = () => {
    setIsDateVisible(true)

    if (dateTimeoutRef.current) {
      clearTimeout(dateTimeoutRef.current)
    }

    dateTimeoutRef.current = setTimeout(() => {
      setIsDateVisible(false)
    }, 800)
  }

  const handleScroll = () => {
    if (!listRef.current) return

    const { scrollTop } = listRef.current

    const dateHeaders = listRef.current.getElementsByTagName('h6')
    const containerRect = listRef.current.getBoundingClientRect()

    let currentHeader = null
    for (let i = 0; i < dateHeaders.length; i++) {
      const header = dateHeaders[i]
      const rect = header.getBoundingClientRect()
      if (rect.top <= containerRect.top + 10) {
        currentHeader = header
      }
    }

    if (currentHeader) {
      const headerDate = currentHeader.getAttribute('data-date')
      if (headerDate) {
        setCurrentDate(headerDate)
        setVisibleDateHeader(headerDate)
        showDateTemporarily()
      }
    }

    const scrolledToTop = scrollTop < 50
    if (scrolledToTop && !loading && hasMore) {
      fetchChats()
    }
  }

  useEffect(() => {
    return () => {
      if (dateTimeoutRef.current) {
        clearTimeout(dateTimeoutRef.current)
      }
    }
  }, [])

  // Function to adjust scroll position after fetching new chats
  const adjustScrollPosition = (isFirstLoad: boolean, previousScrollHeight: number) => {
    setTimeout(() => {
      setLoading(false)

      requestAnimationFrame(() => {
        const newScrollHeight = listRef.current?.scrollHeight || 0
        if (!isFirstLoad) {
          const heightDifference = newScrollHeight - previousScrollHeight
          if (listRef.current) {
            listRef.current.scrollTop = heightDifference // Maintain scroll position
          }
        } else {
          if (listRef.current) {
            listRef.current.scrollTop = newScrollHeight
          }
        }
      })
    }, 100)
  }

  const fetchChats = async (isFirstLoad?: boolean) => {
    if (loading || !partnerId || !conversation) return

    const previousScrollHeight = listRef.current?.scrollHeight || 0 // Store previous scroll height
    setLoading(true)
    try {
      const params: ConversationSearchResult = {
        partner_id: partnerId,
        next_limit: isFirstLoad ? MESSAGES_PER_PAGE : 0,
        ...(!isFirstLoad ? { previous_limit: MESSAGES_PER_PAGE } : {}),
        types: `${ConversationStatus.OutboundMessage},${ConversationStatus.InboundMessage},${ConversationStatus.LeftSurvey}`,
        ...(startingEventId ? { starting_event_id: startingEventId } : {}),
      }

      const {
        data: { analytic_events },
      } = await conversationTimeline(conversation?._id, params)
      const sortedChats = analytic_events.length > 0 ? sortBy(analytic_events, 'created_at', 'desc') : []
      const lastChatId = first(sortedChats)?._id
      if (lastChatId) {
        setStartingEventId(lastChatId)
      }
      // Ensure uniqueness when setting chats
      setChats((prev) => {
        const combinedChats = [...sortedChats, ...prev]
        return uniqBy(combinedChats, '_id')
      })
      setHasMore(analytic_events.length >= MESSAGES_PER_PAGE)

      // Adjust scroll position after fetching new chats
      if (!isFirstLoad) {
        adjustScrollPosition(false, previousScrollHeight)
      } else {
        adjustScrollPosition(true, previousScrollHeight)
      }
    } catch (error) {
      console.error('Error fetching conversations:', error)
    } finally {
      setLoading(false)
      setInitialLoading(false)
    }
  }

  useEffect(() => {
    if (conversation?._id && partnerId) {
      setStartingEventId(null)
      setChats([])
      setInitialLoading(true)
      setHasMore(true)
      setMessage('')
    }
  }, [conversation?._id, partnerId])

  useEffect(() => {
    if (startingEventId === null && conversation?._id && partnerId) {
      fetchChats(true)
    }
  }, [startingEventId, conversation?._id, partnerId])

  const { newMessage } = usePusherMessages(
    // OVATION_CHANNEL_PREFIX (chowly-api) + partnerId
    `${'private-ovation-'}${partnerId}`,
    partnerId || '',
    ['analytic_event_created'],
  )

  const sendMessage = async () => {
    if (isSending || !message.trim() || !partnerId || !conversation?._id) return

    try {
      setIsSending(true)
      const response = await replyToConversation(conversation._id, message, partnerId)

      const newMessageEvent = mapConversationMessageToEvent(response)
      setChats((prev) => [...prev, newMessageEvent])
      setMessage('')
      adjustScrollPosition(true, 0)
    } catch (error) {
      console.error('Error sending message:', error)
    } finally {
      setIsSending(false)
    }
  }

  const formatMessageDate = (date: string) => {
    const messageDate = moment(date)
    const today = moment().startOf('day')
    const yesterday = moment().subtract(1, 'day').startOf('day')
    const oneWeekAgo = moment().subtract(7, 'days').startOf('day')

    if (messageDate.isSame(today, 'day')) {
      return 'Today'
    } else if (messageDate.isSame(yesterday, 'day')) {
      return 'Yesterday'
    } else if (messageDate.isAfter(oneWeekAgo)) {
      return messageDate.format('dddd')
    }
    return messageDate.format('MMMM D, YYYY')
  }

  const messageName = (message: ConversationEvent) : string => {

    if (message.type === ConversationStatus.LeftSurvey && message.survey?.feedback) {

      return RatingToEmoji.at(Number(message.survey?.feedback)) || 'Unknown'
    }
    return  message.type === ConversationStatus.OutboundMessage ? 'M E' : conversation?.customer?.name
  }

  const groupMessagesByDate = (messages: typeof chats) => {
    const groups: { [key: string]: typeof chats } = {}
    messages.forEach((message) => {
      const dateKey = moment(message?.created_at).startOf('day').toISOString()
      if (!groups[dateKey]) {
        groups[dateKey] = []
      }
      groups[dateKey].push(message)
    })

    return groups
  }

  const messageGroups = groupMessagesByDate(sortedChats)

  useEffect(() => {
    if (newMessage && conversation?._id) {
      const newEvent = mapConversationMessageToEvent({
        data: newMessage,
      } as unknown as ConversationMessage)

      // Only process the new message if it belongs to the current conversation
      if (newEvent?.conversation === conversation?._id) {
        // Wait for initial loading to complete before processing new messages
        if (!initialLoading) {
          setChats((prev) => {
            const messageExists = prev.some(msg => msg.message?._id === newEvent.message?._id)
            if (messageExists) {
              return prev
            }
            return uniqBy([...prev, newEvent], 'message._id')
          })
          adjustScrollPosition(true, 0);
        }
      }
    }
  }, [newMessage, conversation?._id, initialLoading, chats])

  const handleResolveConversation = async () => {
    if (!conversation?._id || !partnerId) return

    try {
      await resolveConversation(conversation._id, partnerId)
      toast.success('Conversation was successfully resolved', { autoClose: 3000 })
      onBack()
    } catch (error) {
      console.error('Error resolving conversation:', error)
      toast.error('Failed to resolve conversation', { autoClose: 3000 })
    }
  }

  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onExpand(!isExpanded);
    if (!isExpanded) {
      onCustomerInfoClick();
    }
  };

  return (
    <ChatContainer>
      <HeaderContainer>
        <MobileBackBox>
          <IconButtonStyled size='small' onClick={onBack}>
            <StyledChevronLeftIcon />
          </IconButtonStyled>
        </MobileBackBox>
        <HeaderTitle>
          {conversation && (conversation?.customer?.name || 'Unknown User')}
        </HeaderTitle>
        <Box sx={{ minHeight: '30px' }}>
          {conversation && (
            <>
              {!conversation.resolved && (
                <IconButtonStyled size='small' onClick={handleResolveConversation}>
                  <Tooltip
                    title="You're safe to mark this as resolved. If they reply, it will re-open."
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#2885FD',
                          maxWidth: '160px',
                          whiteSpace: 'pre-line',
                          textAlign: 'center',
                          '& .MuiTooltip-arrow': {
                            color: '#2885FD'
                          }
                        }
                      }
                    }}
                  >
                    <StyledCheckIcon />
                  </Tooltip>
                </IconButtonStyled>
              )}
              <IconButtonStyled size='small' onClick={handleExpandClick}>
                <img src={MENU_OPEN_RIGHT} alt='menu open right' />
              </IconButtonStyled>
            </>
          )}
        </Box>
      </HeaderContainer>
      <ChatContainer>
        {!conversation?._id ? (
          <CenteredBox>
            <InboxIcon sx={{ fontSize: 40, marginBottom: 1 }} />
            <Typography variant='body1'>No conversation selected</Typography>
          </CenteredBox>
        ) : initialLoading ? (
          <CenteredBox>
            <CircularProgress size={24} />
          </CenteredBox>
        ) : (
          <>
            <FloatingDateHeader visible={isDateVisible} variant='caption'>
              {formatMessageDate(currentDate)}
            </FloatingDateHeader>
            <StyledList ref={listRef as React.RefObject<HTMLUListElement>} onScroll={handleScroll}>
              {loading && (
                <LoadingBox>
                  <CircularProgress size={24} />
                </LoadingBox>
              )}
              {Object.entries(messageGroups)
                .sort(([dateA], [dateB]) => moment(dateA).valueOf() - moment(dateB).valueOf())
                .map(([date, messages]) => (
                  <React.Fragment key={date}>
                    <DateHeader
                      variant='h6'
                      data-date={date}
                      visible={date !== visibleDateHeader || !isDateVisible}
                    >
                      {formatMessageDate(date)}
                    </DateHeader>
                    {messages.map((chat, index) => {
                      const messageComponent = (name: string, message: string) => (
                        <ChatMessage
                          key={`${chat._id}-${index}`}
                          name={name}
                          message={message}
                          createdAt={chat.created_at}
                          type={chat.type}
                          isMe={chat.type === ConversationStatus.OutboundMessage}
                        />
                      );

                      return (
                        <li
                          key={`${chat._id}-${index}`}
                          data-date={date}
                          style={{ marginBottom: index === messages.length - 1 ? '10px' : '0' }}
                        >
                          {chat.type === ConversationStatus.LeftSurvey ? (
                            <>
                              {messageComponent(messageName(chat), `User left a ${chat?.survey?.rating}/5 rating`)}
                              {messageComponent('', chat?.message?.text || chat?.survey?.feedback)}
                            </>
                          ) : (
                            messageComponent(messageName(chat), chat?.message?.text || chat?.survey?.feedback)
                          )}
                        </li>
                      );
                    })}
                  </React.Fragment>
                ))}
            </StyledList>
            <InputContainer>
              <TextAreaWrapper>
                <StyledTextArea
                  aria-label='Reply'
                  placeholder='Reply here...'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault(); // Prevent default behavior (new line)
                      sendMessage();
                    }
                  }}
                />
                <SendButton
                  variant='text'
                  disabled={!message?.trim() || isSending}
                  onClick={sendMessage}
                  disableRipple
                >
                  {isSending ? 'Sending...' : 'Send'}
                </SendButton>
              </TextAreaWrapper>
            </InputContainer>
          </>
        )}
      </ChatContainer>
    </ChatContainer>
  )
}

export default ConversationChat
