import { Field } from 'formik'
import styled from 'styled-components'
import { breakpoints, colors } from 'theme'

export const AlertCircle = styled.img({
  width: 24,
  height: 24,
  color: colors.warning.DEFAULT,
})

export const ModalBox = styled.div({
  maxWidth: 600,
  borderRadius: '8px',
  display: 'flex',
  gap: 12,
  padding: 32,
})

export const TextContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
})

export const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 24,
  gap: 8,
})

export const LineRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  // padding: '16px 0px',
  borderBottom: '1px solid #D7D7D7',
  flexWrap: 'nowrap',
  [`@media ${breakpoints.xs}`]: {
    gap: '16px',
  },
})

export const FormikField = styled(Field)({
  borderRadius: '4px',
  height: '33px',
  textIndent: '16px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16.5px',
  color: '#484848',
})
